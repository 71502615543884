import React, { Component } from "react"

import { Flex, Box, Image, Button, Input } from "rebass"

export default class PasswordReset extends Component {
  state = {
    username: "",
    code: "",
    password: "",
    requested: false,
    isBusy: false
  }
  handleSubmit = async () => {
    const { username, code, password, requested } = this.state
    this.setState({ isBusy: true })
    if (!requested) {
      // await this.props.userStore.forgotPassword(username)
      // this.form.requested = true
    } else {
      // this.props.userStore.forgotPasswordSubmit(username, code, password)
    }
    this.this.setState({ isBusy: false })
  }

  handleChange = key => {
    return {
      value: this.state[key],
      onChange: ({ target: { value } }) => {
        this.setState({ [key]: value })
      }
    }
  }
  render() {
    const { requested } = this.state
    return (
      <Box width={340} mt={5} p={3}>
        {requested ? (
          <form>
            <Input label="Email" {...this.handleChange("username")} />
            <Input placeholder="code" {...this.handleChange("code")} />
            <Input
              type="password"
              placeholder="password"
              {...this.handleChange("password")}
            />
          </form>
        ) : (
          <form>
            <Input placeholder="email" {...this.handleChange("username")} />
          </form>
        )}
        <Box mt={3}>
          <Button
            type="submit"
            onClick={this.handleSubmit}
            children={requested ? "Submit" : "Request Reset"}
          />
        </Box>
      </Box>
    )
  }
}
