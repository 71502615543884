import React from "react";
import _ from "lodash";
import countriesProvinces from "countries-provinces";
import { Flex, Text, Box } from "rebass";
import * as Yup from "yup";
import { Formik, Field, FastField, Form } from "formik";
import {
  Title,
  SubTitle,
  FormControlLabel,
  Checkbox,
  TextField,
  Headline,
  Select,
  MenuItem,
  Button,
  PrimaryButton,
  Link,
  GradientFlex,
  SecondarySwitch,
  ErrorFormHelperText
} from "../ui";
import CompanyForm from "./CompanyForm";

const schema = Yup.object().shape({
  contactName: Yup.string().required("Primary Contact Name is required"),
  streetAddr: Yup.string().required("Street Address is required"),
  city: Yup.string().required("City is required"),
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State/Province is required"),
  zip: Yup.string().required("Post/Zip is required")
});

class SiteContactForm extends React.Component {
  static defaultProps = {
    onError: () => {},
    onSubmit: () => {},
    onDirty: () => {},
    streetAddr: "",
    streetAddr2: "",
    zip: "",
    city: "",
    extension: "",
    contactName: "",
    contactEmail: "",
    contactNumber: "",
    disabled: false,
    company: ""
  };

  state = {
    selectedCountry: null,
    selectedState: null
  };

  submit() {
    this.form.submitForm();
  }

  getValues() {
    return {
      ..._.get(this.form, "initialValues"),
      ..._.get(this.form, "state.values")
    };
  }

  resetForm() {
    this.form.resetForm(this.getValues());
  }

  handleCountryChange = selectedCountry => {
    this.setState({ selectedCountry });
  };

  handleStateChange = selectedState => {
    this.setState({ selectedState });
  };

  validateState = value => {
    const { selectedCountry } = this.state;
    if (
      selectedCountry &&
      value &&
      !_.find(countriesProvinces.fromCountryCode(selectedCountry), {
        name: value
      })
    ) {
      return "State/Province is invalid";
    }
    return null;
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.selectedCountry &&
      this.state.selectedState &&
      prevState.selectedCountry !== this.state.selectedCountry
    ) {
      this.form.setFieldTouched("state");
    }
  }

  render() {
    const {
      contactName,
      streetAddr,
      streetAddr2,
      city,
      country,
      state,
      zip,
      contactEmail,
      contactNumber,
      extension,
      companyName
    } = this.props;
    return (
      <Formik
        validationSchema={schema}
        ref={_ref => (this.form = _ref)}
        onSubmit={this.props.onSubmit}
        initialValues={{
          contactName,
          streetAddr,
          streetAddr2,
          city,
          country,
          state,
          zip,
          contactEmail,
          contactNumber,
          extension,
          companyName
        }}
        render={({
          values,
          isSubmitting,
          dirty,
          touched,
          errors,
          handleSubmit,
          handleChange,
          setFieldTouched,
          validateField
        }) => (
          <Flex flexDirection="column" style={{ width: "100%" }}>
            <Box pt="2px">
              <FastField
                disabled={this.props.disabled}
                name="streetAddr"
                label="Street Address"
                component={TextField}
              />
            </Box>
            <Box pt={20}>
              <FastField
                disabled={this.props.disabled}
                name="streetAddr2"
                label="Street Address Line 2 (Opt)"
                component={TextField}
              />
            </Box>
            <Flex pt={20} justify="space-between">
              <FastField
                disabled={this.props.disabled}
                name="city"
                label="City"
                component={TextField}
                css={{ maxWidth: "170px" }}
              />
              {this.props.disabled ? (
                  <Field
                  disabled={this.props.disabled}
                  name="country"
                  component={TextField}
                  label="Country"
               
                />
              ) : (
                <Field
                disabled={this.props.disabled}
                name="country"
                component={TextField}
                render={props => (
                  <Select
                    {...props}
                    error={errors.country}
                    touched={touched.country}
                    setFieldTouched={setFieldTouched}
                    labelText="Country"
                    htmlFor="country"
                    frmControlStyle={{ minWidth: 150 }}
                    value={values.country}
                    onChange={value => this.handleCountryChange(value)}
                  >
                    {_.map(
                      _.get(countriesProvinces, "countries.en"),
                      (data, i) => (
                        <MenuItem
                          key={i}
                          children={data.name}
                          value={data.short}
                        />
                      )
                    )}
                  </Select>
                )}
              />
              )}
             {
               this.props.disabled ? (
                <Field
                disabled={this.props.disabled}
                name="state"
                validate={this.validateState}
                label="State"
                component={TextField}
              />
               ) : (
                <Field
                disabled={this.props.disabled}
                name="state"
                validate={this.validateState}
                render={props => (
                  <Select
                    {...props}
                    error={errors.state}
                    touched={touched.state}
                    setFieldTouched={setFieldTouched}
                    labelText="State/Province"
                    htmlFor="state"
                    frmControlStyle={{ minWidth: 150 }}
                    value={values.state}
                    onChange={value => this.handleStateChange(value)}
                  >
                    {_.map(
                      countriesProvinces.fromCountryCode(values.country),
                      (data, i) => (
                        <MenuItem
                          key={i}
                          children={data.name}
                          value={data.name}
                        />
                      )
                    )}
                  </Select>
                )}
              />
               )
             }
           
              <FastField
                disabled={this.props.disabled}
                name="zip"
                label="Post/Zip"
                component={TextField}
                css={{ maxWidth: "70px" }}
              />
            </Flex>
            <Flex justify="space-between" pt={30}>
              <FastField
                disabled={this.props.disabled}
                name="contactName"
                label="Primary Contact Name"
                component={TextField}
                css={{ minWidth: "155px" }}
              />
              <FastField
                disabled={this.props.disabled}
                name="contactEmail"
                label="Primary Contact Email (Opt)"
                component={TextField}
                css={{ minWidth: "185px" }}
              />
              <FastField
                disabled={this.props.disabled}
                name="contactNumber"
                label="Primary Phone (Opt)"
                component={TextField}
                css={{ minWidth: "130px" }}
              />
              <FastField
                disabled={this.props.disabled}
                name="extension"
                label="Ext (Opt)"
                component={TextField}
                css={{ maxWidth: "70px" }}
              />
            </Flex>
            {dirty && this.props.onError(!_.isEmpty(_.keys(errors)))}
            {this.props.onDirty(dirty)}
          </Flex>
        )}
      />
    );
  }
}

export default SiteContactForm;
