import copy from "copy-to-clipboard"

async function copyToClipboard(text) {
  // Use the Async Clipboard API when available
  if (navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(text)
      return Promise.resolve(true)
    } catch (err) {}
  }

  return copy(text)
}

export default copyToClipboard
