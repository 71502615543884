//Derived from https://github.com/Vashnak/react-toasts

import { observer, inject } from "mobx-react";
import _ from "lodash";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropType from "prop-types";
import styled, { keyframes } from "styled-components";

const FadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 10px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(-26%, 10px, 0);
  }
`;

const Toasts = styled.div`
  height: 20%;
  width: 100%;
  position: fixed;
  overflow: hidden;
  z-index: 9999;
  max-height: calc(100vh - 10px);
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 300px;
  right: 0;
`;

const Toast = styled.div`
  font-family: "Sunflower";
  background-color: #f15400;
  box-shadow: 0px 4px 10px -3px rgba(0, 0, 0, 0.34);
  font-size: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 20px 50px;
  white-space: pre-line;
  min-height: 50px;
  margin-bottom: 15px;
  border-radius: 3px;
  animation-name: ${FadeInUp};
  animation-duration: 1s;
  animation-fill-mode: both;
`;

const BackgroundColor = {
  success: {
    color: "#FFF",
    backgroundColor: "#F15400"
  },
  info: {
    backgroundColor: "rgba(236, 240, 241, 1)"
  },
  warning: {
    backgroundColor: "rgba(241, 196, 15, 1)"
  },
  error: {
    color: "#FFF",
    backgroundColor: "rgba(231, 76, 60, 1)"
  }
};

const LightBackgroundColor = {
  success: {
    color: "#468847",
    backgroundColor: "#dff0d8",
    borderColor: "#d6e9c6"
  },
  info: {
    color: "#3a87ad",
    backgroundColor: "#d9edf7",
    borderColor: "#bce8f1"
  },
  warning: {
    color: "#c09853",
    backgroundColor: "#fcf8e3",
    borderColor: "#fbeed5"
  },
  error: {
    color: "#b94a48",
    backgroundColor: "#f2dede",
    borderColor: "#eed3d7"
  }
};

class ToastItem extends Component {
  state = {
    toasts: []
  };

  componentWillMount() {
    this.setToasts(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setToasts(nextProps);
  }

  setToasts(props) {
    const { data, closeToast } = props;
    if (data) {
      let toast = {
        status: "success",
        id: Math.random(),
        ...(_.isString(data) ? { message: data } : data)
      };
      this.state.toasts = [toast, ...this.state.toasts];
      setTimeout(() => {
        this.setState({
          toasts: _.filter(this.state.toasts, t => t.id !== toast.id)
        });
        closeToast();
      }, data.timer || 3000);
    }
  }

  render() {
    return (
      <Toasts>
        {this.state.toasts.map(toast => {
          return (
            <Toast
              key={toast.id}
              className={"toast toast-" + toast.status + " " + toast.classNames}
              style={BackgroundColor[toast.status]}
            >
              {toast.message}
            </Toast>
          );
        })}
      </Toasts>
    );
  }
}

@inject("uxStore")
@observer
class ToastContainer extends Component {
  static POSITION = {
    TOP_LEFT: "top_left",
    TOP_RIGHT: "top_right",
    BOTTOM_LEFT: "bottom_left",
    BOTTOM_RIGHT: "bottom_right",
    TOP_CENTER: "top_center",
    BOTTOM_CENTER: "bottom_center"
  };

  constructor(props) {
    super(props);
  }

  renderContainer() {
    const data = _.get(this.props, "uxStore.toast");
    return data ? <ToastItem data={data} closeToast={this.closeToast} /> : null;
  }

  closeToast = () => {
    this.props.uxStore.closeToast();
  };

  render() {
    return ReactDOM.createPortal(this.renderContainer(), document.body);
  }
}

ToastContainer.propTypes = {
  position: PropType.string
};

export default ToastContainer;
