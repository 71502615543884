import React from "react"
import { Heading, Box, Flex, Text } from "rebass"
import { observer, inject } from "mobx-react"
import Check from "@material-ui/icons/Check"
import Close from "@material-ui/icons/Close"
import { NavLink } from "react-router-dom"

import {
  HeadlineText,
  Title,
  WANButton,
  Checkbox,
  FlatPaper,
  Link,
  VerticalMenu,
  Button,
  PrimaryButton
} from "../ui"
import { Table, TablePagination } from "../ui/Table"
import { track } from "../../analytics"
import { copyToClipboard } from "../../utils"
const rows = [
  {
    id: "gatewayName",
    numeric: false,
    disablePadding: true,
    label: "Gateway Name",
    style: { textAlign: "left" }
  },
  {
    id: "gatewayIp",
    numeric: false,
    disablePadding: true,
    label: "Gateway IP",
    style: { textAlign: "left" }
  },
  {
    id: "portNumber",
    numeric: true,
    disablePadding: true,
    label: "Port Number",
    style: { textAlign: "left" }
  },
  {
    id: "noOfLinks",
    numeric: true,
    disablePadding: true,
    label: "Number of Links",
    style: { textAlign: "left" }
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: true,
    label: "Actions",
    style: { textAlign: "left" }
  }
]

@inject("userStore", "wanStore", "uxStore", "internetGatewayStore", "siteStore")
@observer
export default class GatewayList extends React.Component {
  state = {
    selected: [],
    gateways: [],
    page: 0,
    rowsPerPage: 15
  }

  async componentDidMount() {
    track("WAN List")
    const { userId } = this.props.userStore.currentUser
    this.props.internetGatewayStore.allUserInternetGateways(userId)
  }

  handleCopyInstallScripts = () => async closeMenu => {
    try {
      const {
        allUserUbondServers,
        getCoreInstallUrlServer
      } = this.props.internetGatewayStore
      const { userId } = this.props.userStore.currentUser
      const serverId = await allUserUbondServers(userId)

      // Assumption ONLY 1 Gateway / User
      const url = await getCoreInstallUrlServer(serverId[0].id)
      copyToClipboard(url)
      this.props.uxStore.openToast({
        message: `Copied !`
      })
      closeMenu()
    } catch (err) {
      console.log(err)
      this.props.uxStore.openToast({
        status: "error",
        message: "Error occurred !"
      })
    }
  }

  handleNewGateway = () => {
    this.props.history.push("/console/create-gateway")
  }

  handleChangePage = (event, page) => {}

  handleChangeRowsPerPage = event => {}

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value })
  }

  getCellProps(id) {
    switch (id) {
      case "gatewayName":
        return {
          component: "th",
          scope: "row",
          padding: "none",
          style: { textAlign: "left" }
        }
      case "noOfLinks":
        return { numeric: true, style: { textAlign: "left" } }
      case "portNumber":
        return {
          component: "th",
          scope: "row",
          padding: "none",
          style: { textAlign: "left" }
        }
      case "gatewayIp":
        return {
          component: "th",
          scope: "row",
          padding: "none",
          style: { textAlign: "left" }
        }
      case "actions":
        return {
          component: "th",
          scope: "row",
          padding: "none",
          style: { textAlign: "left" }
        }
      default:
        return {}
    }
  }

  getSortValue = (n, orderBy) => {
    switch (orderBy) {
      case "gatewayName":
        return _.get(n, "name", "")
      case "gatewayIp":
        return _.get(n, "ip", "")
      case "portNumber":
        return _.get(n, "port", "")
      default:
        return _.get(n, orderBy)
    }
  }

  getCellChildren(row, n) {
    switch (row.id) {
      case "gatewayName":
        return (
          <Link
            color="#373737"
            css={{ textDecoration: "none" }}
            is={NavLink}
            to={`/console/create-gateway`}>
            {`${_.get(n, "name", "")}`}
          </Link>
        )
      case "gatewayIp":
        return (
          <Link
            color="#373737"
            css={{ textDecoration: "none" }}
            is={NavLink}
            to={`/console/create-gateway`}>
            {`${_.get(n, "ip", "")}`}
          </Link>
        )
      case "portNumber":
        return (
          <Link
            color="#373737"
            css={{ textDecoration: "none" }}
            is={NavLink}
            to={"/console/create-gateway"}>
            {`${_.get(n, "port", "")}`}
          </Link>
        )
      case "noOfLinks":
        // Total Number Of Site For User - MVP - Only 1 Gateway / User
        return this.props.siteStore.usedPorts.length
      case "actions":
        return (
          <VerticalMenu
            options={[
              {
                text: "Copy Install Scripts",
                onClick: this.handleCopyInstallScripts(),
                manual: true
              }
            ]}
          />
        )
      default:
        return n[row.id]
    }
  }

  handleSelect = selected => {
    this.setState({ selected })
  }

  render() {
    const {
      internetGatewayStore: { gateways }
    } = this.props
    const { rowsPerPage, page, selected } = this.state
    return (
      <Flex align="flex-start" flexDirection="column">
        <Flex
          flex="0 0 70px"
          css={{
            width: "100%",
            background: "linear-gradient(212deg, #a2a2a9 0%, #2b2b2b 100%)"
          }}
          pt={10}
          pb={10}>
          <Flex flex={1} align="center">
            <HeadlineText children={"Gateways"} px={3} />
            <PrimaryButton
              children="Create Gateway"
              onClick={this.handleNewGateway}
              ml={"25px"}
            />
          </Flex>
          <Flex flex="1" width="100%" justify="flex-end">
            <TablePagination
              disableRipple
              component="div"
              count={_.size(gateways)}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[]}
              page={page}
              backIconButtonProps={{
                "aria-label": "Previous Page"
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page"
              }}
              labelDisplayedRows={({ from, to, count }) => `${to} of ${count}`}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Flex>
        </Flex>
        <FlatPaper style={{ width: "100%" }}>
          <Table
            data={gateways}
            ref={_ref => (this.wanList = _ref)}
            orderBy="siteName"
            page={page}
            rows={rows}
            sortByValue={this.getSortValue}
            rowsPerPage={rowsPerPage}
            onSelect={this.handleSelect}
            cellRenderer={(row, n) => {
              return {
                cellProps: this.getCellProps(row.id),
                children: this.getCellChildren(row, n)
              }
            }}
          />
        </FlatPaper>
        {!_.isEmpty(selected) && (
          <Button
            type="button"
            bg={"greys.3"}
            fontSize={13}
            fontWeight={300}
            p={"15px 25px"}
            ml={20}
            mt={20}
            onClick={this.handleDeleteAllWans}>
            Delete All Selected
          </Button>
        )}
      </Flex>
    )
  }
}
