import { observable, decorate, action } from "mobx"
import { API, graphqlOperation } from "aws-amplify"

import { GET_USER, GET_MISC_USER_CONFIG } from "../queries"

class UserStore {
  @observable
  currentUser
  config

  setUser = user => {
    this.currentUser = user
    if (process.env.REACT_APP_ENV !== "production") {
      console.log("user is: ", user)
    }
  }

  loadUser = async () => {
    const { userId: id } = this.currentUser
    const r = await API.graphql(graphqlOperation(GET_USER, { id }))
    // do something with r..
    return Promise.resolve()
  }

  /**
   * Get Miscelaneous Config
   *
   * NOTE:
   * this is intended as a temporary solution for querying on setup
   * and config that we just haven't really found a proper place for yet
   *
   * @param {String!} ownerEmail - email of the user
   * @return {Promise} Promise containing an object with various config
   */
  getMiscConfig = async () => {
    const { email } = this.currentUser
    const {
      data: {
        getMiscConfig: { config }
      }
    } = await API.graphql(
      graphqlOperation(GET_MISC_USER_CONFIG, { ownerEmail: email })
    )
    this.config = JSON.parse(config)
    return Promise.resolve(config)
  }

  forgetUser = () => {
    this.currentUser = undefined
  }
}

decorate(UserStore, {
  loadUser: action,
  forgetUser: action,
  setUser: action,
  config: observable
})

export default new UserStore()
