const matchMedia = (query, func) => {
  if (window.matchMedia(query).matches) {
    func();
  }

  window.addEventListener("resize", () => {
  	if (window.matchMedia(query).matches) {
    	func();
  	}
  })
}

export default matchMedia