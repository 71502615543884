export const netMaskData = [
  32,
  30,
  29,
  28,
  27,
  26,
  25,
  24,
  23,
  22,
  21,
  20,
  19,
  18,
  17,
  16,
  15,
  14,
  13,
  12,
  11,
  10,
  9,
  8
]

export const interfacePortData = [
  "eth0",
  "eth1",
  "eth2",
  "eth3",
  "eth4",
  "eth5",
  "eth6",
  "eth7",
  "eth8"
]

export const linkTypeData = [
  "Consumer Cable",
  "Consumer xDSL",
  "Consumer Wireless (LTE/4G/3G)",
  "Consumer Fiber",
  "DIA Fiber (Business)",
  "DIA Fixed Wireless (Business)",
  "DIA EOC (Business)",
  "DIA T1/T3 (Business)",
  "MPLS",
  "Other"
]

export const industryTypeData = [
  "Animal Services",
  "Art / Media / Entertainment",
  "Computers / Technology",
  "Construction",
  "Consumer Goods",
  "Education",
  "Environment",
  "Farming / Agriculture / Fisheries",
  "Fashion / Maker",
  "Financial Services",
  "Government / Organization",
  "Healthcare",
  "Hospitality / Food & Beverage",
  "Leisure, Travel & Tourism",
  "Liquor / Tobacco",
  "Manufacturing",
  "Marketing / Advertising",
  "Mining",
  "NGOs / Charities / Religious Institutions",
  "Other",
  "Professional Services",
  "Real Estate",
  "Recreation",
  "Retail",
  "Sciences",
  "Services",
  "Telecommunications",
  "Transportation",
  "Utilities"
]
