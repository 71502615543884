import React from "react"
import { Heading, Box, Flex } from "rebass"
import { observer, inject } from "mobx-react"
import { withStyles } from "@material-ui/core"
import { Title, SubTitle, FormControlLabel, Checkbox, TextField, Select, MenuItem,
  Tree, PrimaryButton, Button, Link, GradientFlex, PrimarySwitch, IOSSwitch, Input, RadioGroup,
  Radio, Headline } from "../ui"
import WANMeshConfig from "./WANMeshConfig"
import WANHubConfig from "./WANHubConfig"

export default class WANSiteConfig extends React.Component {
  handleNewSite = () => {
    this.props.history.push("/console/company-profile")
  }

  getValues() {
    if (this.props.wanType === "MESH") {
      return this.wanMeshConfig.wrappedInstance.getValues()
    }
    else {
      return this.wanHubConfig.wrappedInstance.getValues()
    }
  }

  render() {
    return (
      <Flex flexDirection="column">
        <Flex>
          <PrimaryButton children="Create Site" onClick={this.handleNewSite}/>
        </Flex>
        { this.props.wanType === "MESH" && <WANMeshConfig {...this.props} ref={(_ref) => (this.wanMeshConfig = _ref)}/> }
        { this.props.wanType === "HUB_SPOKE" && <WANHubConfig {...this.props} ref={(_ref) => (this.wanHubConfig = _ref)}/> }
      </Flex>
    )
  }
}
