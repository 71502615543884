import React from "react"
import ReactDOM from "react-dom"
import Amplify from "aws-amplify"
import { Provider } from "mobx-react"
import App from "./components/App"
import userStore from "./stores/UserStore"
import siteStore from "./stores/SiteStore"
import companyStore from "./stores/CompanyStore"
import wanStore from "./stores/WanStore"
import internetGatewayStore from "./stores/InternetGatewayStore"
import uxStore from "./stores/UxStore"
import mixpanel from "mixpanel-browser"

mixpanel.init("ecd71d6cb1ffa240220d79ae6c7317a5")

const stores = {
  userStore,
  siteStore,
  companyStore,
  uxStore,
  wanStore,
  internetGatewayStore
}

console.log(process.env.REACT_APP_ENV)

import { AMPLIFY_CONFIG } from "./config"

Amplify.configure(AMPLIFY_CONFIG)

window._____APP_STATE_____ = stores

ReactDOM.render(
  <Provider {...stores}>
    <App />
  </Provider>,
  document.getElementById("root")
)
