import { observable, action, decorate } from "mobx"

class UxStore {
	dialog = null;
	toast = null;

	openDialog(_dialog) {
		this.dialog = _dialog;
	}

	closeDialog() {
		this.dialog = null;
	}

	openToast(_toast) {
		this.toast = _toast
	}

	closeToast() {
		this.toast = null
	}
}

decorate(UxStore, {
  closeDialog: action,
  openDialog: action,
  dialog: observable,
  toast: observable,
  openToast: action,
  closeToast: action
})

export default new UxStore()