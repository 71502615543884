import React from "react";
import { Box, Flex, Text, Image, Border, Circle, Heading } from "rebass";
import { withRouter } from "react-router-dom";
import { Formik, Field, FastField, Form, FieldArray } from "formik";
import { TRAFFIC_MGMT_PROFILE } from "./constants";
import {
  Title,
  PrimaryButton,
  WANButton,
  DialogTitle,
  DialogSubTitle,
  TextField,
  Checkbox,
  Plus,
  Select,
  MenuItem,
  Link
} from "../../ui";
import ssDialog from "./decorator";
import { withStyles } from "@material-ui/core";
import styled from "styled-components";

const AESCheckbox = withStyles({
  root: {
    width: "30px",
    height: "27px"
  },
  labelRoot: {
    alignItems: "flex-end"
  },
  disabled: {
    color: "#ffffff3b !important"
  },
  labelDisabled: {
    color: "#ffffff4d !important"
  }
})(Checkbox);

const TrafficSelect = withStyles({
  select: {
    display: "flex"
  }
})(Select);

const trafficTypeData = [
  {
    value: 1,
    name: "DSCP EP",
    desc: "(VoIP Traffic Priority)"
  },
  {
    value: 2,
    name: "SIN-FYN-ACK",
    desc: "(Web Application Priority)"
  }
];

const DeleteItem = props => (
  <Flex
    onClick={props.onDelete}
    align="center"
    css={{ cursor: "pointer" }}
    onClick={props.onClick}
  >
    <Image src="https://icongr.am/material/trash-can.svg?color=CCCCCC&size=28" />
    <Text color="#FFFFFF" fontSize={12} mt={"6px"}>
      Delete
    </Text>
  </Flex>
);

const renderPriorities = ({
  form: { values },
  push: pushPriority,
  remove: removePriority
}) => (
  <Flex flexDirection="column">
    {_.map(values.trafficPriorities, (priority, index) => (
      <Flex flexDirection="column" key={`trafficPriorities.${index}`} mt={20}>
        <DialogSubTitle
          children={`Priority ${index + 1} Traffic`}
          p={"7px 10px 7px 15px"}
          color="white"
          bg={index === 0 ? "greenTitle" : "greyBg"}
        />
        <FieldArray
          name={`trafficPriorities.${index}`}
          render={({ form: { values }, push, remove }) => (
            <Plus
              imgStyle={{ bottom: "20px", right: "15px" }}
              flexStyle={{
                background: "#8A8888",
                padding: "20px",
                border: "1.4px solid #777",
                borderTop: 0
              }}
              onAdd={() => {
                push({ desc: "", type: "" });
              }}
              showMinus={false}
            >
              {_.map(values.trafficPriorities[index], (p, i) => (
                <React.Fragment key={`priority-${i}`}>
                  <Flex flex={"0 0 80%"}>
                    <Box flex="1" mr={30}>
                      <FastField
                        name={`trafficPriorities.${index}.${i}.desc`}
                        label="Class Description (Opt)"
                        component={TextField}
                        value={p.desc}
                      />
                    </Box>
                    <Box flex="1">
                      <FastField
                        name={`trafficPriorities.${index}.${i}.type`}
                        render={props => (
                          <TrafficSelect
                            {...props}
                            labelText="Traffic Type"
                            frmControlStyle={{
                              minWidth: "inherit",
                              width: "100%"
                            }}
                          >
                            {_.map(trafficTypeData, (data, i) => (
                              <MenuItem key={i} value={data.value}>
                                <Text>{data.name}</Text>
                                <Text fontSize={11} fontWeight="bold" ml="2px">
                                  {data.desc}
                                </Text>
                              </MenuItem>
                            ))}
                          </TrafficSelect>
                        )}
                      />
                    </Box>
                  </Flex>
                  <Flex justify="flex-end" flex={1} mr={"35px"}>
                    <DeleteItem
                      onClick={() => {
                        if (i === 0) {
                          removePriority(index);
                        } else {
                          remove(i);
                        }
                      }}
                    />
                  </Flex>
                </React.Fragment>
              ))}
            </Plus>
          )}
        />
      </Flex>
    ))}
    <Flex justify="flex-end" p={"10px 0 0 0"}>
      <Link
        children="Add Priority  +"
        underline="true"
        fontSize={13}
        onClick={() => {
          pushPriority([{ desc: "", type: "" }]);
        }}
      />
    </Flex>
  </Flex>
);

@withRouter
@ssDialog({ key: TRAFFIC_MGMT_PROFILE, type: "form" })
export default class TrafficMgmtDialog extends React.Component {
  static defaultProps = {
    trafficPriorities: [[{ desc: "", type: "" }]],
    tunnelBypassTraffic: [{ desc: "", type: "" }]
  };

  deleteItem = (arrayHelpers, index) => () => {
    arrayHelpers.remove(index);
  };

  handleCancel = () => {
    this.props.onClose();
  };

  render() {
    const { trafficPriorities, tunnelBypassTraffic } = this.props;

    return (
      <Box p={"0 30px"}>
        <DialogTitle
          children="Traffic Management Profile"
          p={"50px 0 20px 5px"}
          color="white"
          css={{ borderBottom: "1px solid #DDD" }}
        />
        <Formik
          initialValues={{ trafficPriorities, tunnelBypassTraffic }}
          render={({
            values,
            setFieldTouched,
            errors,
            touched,
            isSubmitting
          }) => (
            <Form>
              <Flex align="baseline" p={"20px 0 40px 0"}>
                <FastField
                  name="profileName"
                  label="Profile Name"
                  component={TextField}
                  css={{ flex: 1 }}
                />
                <Box ml={30}>
                  <FastField
                    name="aesEncrytionEnabled"
                    label={
                      <Flex>
                        <Text ml={2}>Enable AES 128 Encryption</Text>
                        <Text ml={1} fontSize={"0.9em"}>
                          (Will reduce internet bandwidth)
                        </Text>
                      </Flex>
                    }
                    component={props => <AESCheckbox disabled {...props} />}
                  />
                </Box>
              </Flex>
              <FieldArray
                name="trafficPriorities"
                component={renderPriorities}
              />
              <Flex flexDirection="column" mt={30}>
                <DialogSubTitle
                  children="Tunnel Bypass Traffic"
                  p={"7px 10px 7px 15px"}
                  color="white"
                  bg="redBg"
                />
                <FieldArray
                  name="tunnelBypassTraffic"
                  render={arrayHelpers => (
                    <Plus
                      imgStyle={{ bottom: "20px", right: "15px" }}
                      flexStyle={{
                        flex: "0 0 80px",
                        background: "#8A8888",
                        padding: "20px 20px 20px 20px",
                        border: "1.4px solid #777",
                        borderTop: 0
                      }}
                      onAdd={() => {
                        arrayHelpers.push({ type: "", desc: "" });
                      }}
                      showMinus={false}
                    >
                      {_.map(values.tunnelBypassTraffic, (priority, index) => (
                        <React.Fragment key={`tunnelBypassTraffic-${index}`}>
                          <Flex flex={"0 0 80%"}>
                            <Box flex="1" mr={30}>
                              <FastField
                                name={`tunnelBypassTraffic.${index}.desc`}
                                label="Class Description (Opt)"
                                component={TextField}
                              />
                            </Box>
                            <Box flex="1">
                              <FastField
                                name={`tunnelBypassTraffic.${index}.type`}
                                render={props => (
                                  <TrafficSelect
                                    {...props}
                                    error={errors.trafficType}
                                    touched={touched.trafficType}
                                    setFieldTouched={setFieldTouched}
                                    labelText="Traffic Type"
                                    htmlFor="trafficType"
                                    frmControlStyle={{
                                      minWidth: "inherit",
                                      width: "100%"
                                    }}
                                  >
                                    {_.map(trafficTypeData, (data, i) => (
                                      <MenuItem key={i} value={data.value}>
                                        <Text>{data.name}</Text>
                                        <Text
                                          fontSize={11}
                                          fontWeight="bold"
                                          ml="2px"
                                        >
                                          {data.desc}
                                        </Text>
                                      </MenuItem>
                                    ))}
                                  </TrafficSelect>
                                )}
                              />
                            </Box>
                          </Flex>
                          <Flex justify="flex-end" flex={1} mr={"35px"}>
                            <DeleteItem
                              onClick={this.deleteItem(arrayHelpers, index)}
                            />
                          </Flex>
                        </React.Fragment>
                      ))}
                    </Plus>
                  )}
                />
              </Flex>
              <Flex p={"20px 0"} mb={40} justify="flex-end" align="center">
                <Link
                  children="Cancel"
                  underline="true"
                  fontSize={13}
                  pr={20}
                  onClick={this.handleCancel}
                />
                <PrimaryButton
                  type="submit"
                  children="Save Settings"
                  disabled={isSubmitting}
                  p={"10px 30px"}
                />
              </Flex>
            </Form>
          )}
        />
      </Box>
    );
  }
}
