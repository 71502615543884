import React, { Component } from "react"
import { Flex, Box, Banner } from "rebass"
import SignIn from "./SignIn"
import PasswordReset from "./PasswordReset"

export default class Authenticator extends Component {
  state = {
    showSignIn: this.props.location.search !== "?signUp"
  }

  toggleMode = () => {
    this.setState({ showSignIn: !this.state.showSignIn })
  }

  getImageURL = () => {
    const images = [
      "https://i.imgur.com/WMx8pdU.png",
      "https://i.imgur.com/qe4nglu.png"
    ]
    let randNumber = Math.floor(Math.random() * images.length + 1)
    let imgURL = `${images[randNumber - 1]}`
    return imgURL
  }

  render() {
    const { showSignIn } = this.state

    return (
      <Box>
        <Flex alignItems="center">
          <Banner
            width={7 / 15}
            minHeight="100vh"
            bg="grey"
            backgroundImage={this.getImageURL()}
          />
          <Box mx="auto" width={1 / 2}>
            {showSignIn ? (
              <SignIn toggleMode={this.toggleMode} />
            ) : (
              <PasswordReset />
            )}
          </Box>
        </Flex>
      </Box>
    )
  }
}
