import gql from "graphql-tag"
import { print } from "graphql/language/printer"

export const GET_USER = print(gql`
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
    }
  }
`)

export const GET_MISC_USER_CONFIG = print(gql`
  query GetMiscConfig($ownerEmail: String!) {
    getMiscConfig(ownerEmail: $ownerEmail) {
      config
    }
  }
`)

export const GET_USER_AND_SITES = print(gql`
  query GetUser($id: ID!, $count: Int, $nextToken: String) {
    getUser(id: $id) {
      id
      email
      sites(ownerId: $id, count: $count, nextToken: $nextToken)
    }
  }
`)

export const ALL_USER_SITES = print(gql`
  query AllUserSites($ownerId: String!, $count: Int, $nextToken: String) {
    allUserSites(ownerId: $ownerId, count: $count, nextToken: $nextToken) {
      sites {
        id
        status
        configuration
        info
        createdAt
      }
      nextToken
    }
  }
`)

export const GET_CORE_INSTALL_URL = print(gql`
  query GetCoreInstallUrl($siteId: ID!) {
    getCoreInstallUrl(siteId: $siteId) {
      url
    }
  }
`)

export const GET_CORE_INSTALL_URL_SERVER = print(gql`
  query GetCoreInstallUrlServer($serverId: ID!) {
    getCoreInstallUrlServer(serverId: $serverId) {
      url
    }
  }
`)

export const CREATE_SITE = print(gql`
  mutation CreateSite($input: CreateSiteInput!) {
    createSite(input: $input) {
      id
    }
  }
`)

export const UPDATE_SITE = print(gql`
  mutation UpdateSite($input: UpdateSiteInput!) {
    updateSite(input: $input) {
      id
    }
  }
`)

export const DEPLOY_SITE = print(gql`
  query DeploySite($siteId: ID!, $ownerId: String!) {
    deploySite(siteId: $siteId, ownerId: $ownerId) {
      siteId
    }
  }
`)

export const DELETE_SITE = print(gql`
  mutation DeleteSite($siteIds: [ID!]!, $ownerId: String!) {
    deleteSite(siteIds: $siteIds, ownerId: $ownerId) {
      id
    }
  }
`)

export const ALL_USER_WANS = print(gql`
  query AllUserWans($ownerId: String!, $count: Int, $nextToken: String) {
    allUserWans(ownerId: $ownerId, count: $count, nextToken: $nextToken) {
      wans {
        id
        status
        name
        type
        sites
        wanGateway
      }
      nextToken
    }
  }
`)

export const CREATE_WAN = print(gql`
  mutation CreateWan($input: CreateWanInput!) {
    createWan(input: $input) {
      id
    }
  }
`)

export const UPDATE_WAN = print(gql`
  mutation UpdateWan($input: UpdateWanInput!) {
    updateWan(input: $input) {
      id
    }
  }
`)

export const DELETE_WAN = print(gql`
  mutation DeleteWan($wanIds: [ID!]!, $ownerId: String!) {
    deleteWan(wanIds: $wanIds, ownerId: $ownerId) {
      id
    }
  }
`)

export const CREATE_COMPANY = print(gql`
  mutation CreateCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      id
    }
  }
`)

export const UPDATE_COMPANY = print(gql`
  mutation UpdateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      id
    }
  }
`)

export const ALL_COMPANIES = print(gql`
  query AllCompanies($ownerId: String!) {
    allCompanies(ownerId: $ownerId) {
      id
      name
      industry
      companyInfoLinks
      notes
    }
  }
`)

export const CREATE_IGW = print(gql`
  mutation CreateInternetGateway($input: CreateInternetGatewayInput!) {
    createInternetGateway(input: $input) {
      id
    }
  }
`)

export const UPDATE_IGW = print(gql`
  mutation UpdateInternetGateway($input: UpdateInternetGatewayInput!) {
    updateInternetGateway(input: $input) {
      id
    }
  }
`)

export const ALL_IGW = print(gql`
  query AllInternetGateways($ownerId: String!) {
    allUserInternetGateways(ownerId: $ownerId) {
      id
      name
      ip
      port
    }
  }
`)

export const GET_UNUSED_UBOND_SERVER_PORT = print(gql`
  query GetUnusedUbondServerPort($ownerId: String!, $serverId: ID!) {
    getUnusedUbondServerPort(ownerId: $ownerId, serverId: $serverId) {
      port
    }
  }
`)

export const DROP_UBOND_SERVER_PORT = print(gql`
  query DropUbondServerPort($ownerId: String!, $serverId: ID!, $port: Int!) {
    dropUbondServerPort(ownerId: $ownerId, serverId: $serverId, port: $port) {
      port
    }
  }
`)

export const CREATE_UBOND_SERVER = print(gql`
  mutation CreateUbondServer($input: CreateUbondServerInput!) {
    createUbondServer(input: $input) {
      id
    }
  }
`)

export const ALL_UBOND_SERVERS = print(gql`
  query AllUbondServers($ownerId: String!) {
    allUserUbondServers(ownerId: $ownerId) {
      id
    }
  }
`)
