import React from "react"
import { Heading, Box, Flex } from "rebass"
import { observer, inject } from "mobx-react"
import { Form, Formik, FastField } from "formik"
import { withStyles } from "@material-ui/core"
import {
  Title,
  SubTitle,
  FormControlLabel,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  Tree,
  PrimaryButton,
  Button,
  Link,
  GradientFlex,
  PrimarySwitch,
  IOSSwitch,
  Input,
  RadioGroup,
  Radio,
  Headline
} from "../ui"
import WANConfigForm from "./WANConfigForm"
import WANSiteConfig from "./WANSiteConfig"
import TrafficMgmtDialog from "./dialogs/TrafficMgmtDialog"
import { track } from "../../analytics"
import * as Yup from "yup"
import { validateIPAddress, validateMinMax } from "../../utils/validations"
import { NavLink } from "react-router-dom"
import { copyToClipboard } from "../../utils"

const schema = Yup.object().shape({
  name: Yup.string().required("Gateway Name is required"),
  ip: Yup.string().required("Server Ip Address is required")
})

@inject("userStore", "uxStore", "internetGatewayStore")
export default class CreateGateway extends React.Component {
  static defaultProps = {
    onError: () => {},
    onDirty: () => {},
    name: "",
    ip: ""
  }
  state = {
    url: "",
    disableSubmit: false
  }

  componentDidMount = async () => {
    const {
      allUserUbondServers,
      getCoreInstallUrlServer
    } = this.props.internetGatewayStore
    const { userId } = this.props.userStore.currentUser
    const serverId = await allUserUbondServers(userId)
    serverId.length >= 1 &&
      this.setState({
        disableSubmit: true
      })
  }

  getValues() {
    return {
      ..._.get(this.rootFormik, "initialValues"),
      ..._.get(this.rootFormik, "state.values")
    }
  }

  // handleChange = event => {
  //   this.setState({ value: event.target.value })
  //   this.props.onChange(event)
  // }

  handleSubmit = async () => {
    const { rootFormik } = this
    const values = this.getValues()
    let { ip, name } = values
    let ownerId = await _.get(this.props, "userStore.currentUser.userId")

    try {
      // Create Internet Gateway
      await this.props.internetGatewayStore.createInternetGateway({
        ownerId,
        ip,
        name,
        port: "6000"
      })
      // Create Ubond Server Instance
      const serverId = await this.props.internetGatewayStore.createUbondServer(
        ownerId
      )
      const url = await this.props.internetGatewayStore.getCoreInstallUrlServer(
        serverId
      )
      // Display URL To Clipboard
      this.setState({
        url
      })
      this.props.uxStore.openToast({ message: `Gateway Created` })

      rootFormik.resetForm()
    } catch (err) {
      console.log({ err })
      this.props.uxStore.openToast({
        status: "error",
        message: "Error occurred !"
      })
    }
  }

  cancelSetup = () => {
    this.props.history.push("/console/wans")
  }

  // handleUrlInstallScripts = url => {
  //   console.log(this.props.uxStore.openDialog({ LOG_VIEWER }))
  //   console.log(this.props.uxStore.dialog(logViewer))
  //   this.props.uxStore.openDialog(LOG_VIEWER)
  // }

  handleCopy = async url => {
    const success = await copyToClipboard(url)
    if (success) {
      this.props.uxStore.openToast({ message: "Copied !" })
    }
  }

  render() {
    const { name, ip } = this.props
    const { url, disableSubmit } = this.state
    return (
      <Formik
        onSubmit={this.handleSubmit}
        initialValues={{
          name,
          ip
        }}
        ref={_ref => (this.rootFormik = _ref)}
        validationSchema={schema}
        render={({
          values,
          isSubmitting,
          dirty,
          touched,
          errors,
          handleSubmit,
          handleChange,
          setFieldTouched,
          validateField
        }) => (
          <Form
            ref={_ref => (this.form = _ref)}
            style={{ display: "flex", width: "100%" }}>
            <Flex m="0 auto" width="100%" flexDirection="column">
              <Flex flex={"0 0 90px"} align="center" pl={65}>
                <Headline title="Create A Gateway" />
              </Flex>
              <Flex flexDirection="column" p={30} bg="greys.3" m={"0 3%"}>
                <Heading children={"Please start by creating a gateway."} />

                <Flex flex="0 0 470px" flexDirection="column" flex={1}>
                  <SubTitle mt={4}>
                    If you've already created your gateway, please click{" "}
                    <NavLink
                      style={{
                        color: "#D9E139",
                        textDecoration: "none"
                      }}
                      to="/console/sites">
                      here
                    </NavLink>{" "}
                    to access your sites.
                  </SubTitle>

                  <SubTitle mt={4}>
                    If you're creating your first gateway, please visit the
                    following pages in our help centre to get you started :
                  </SubTitle>
                  <SubTitle mt={4}>
                    1.{" "}
                    <a
                      style={{
                        color: "#D9E139",
                        textDecoration: "none"
                      }}
                      href="https://ethica.zendesk.com/hc/en-us/articles/360021899652-Sizing-a-Gateway-for-Multiple-Sites"
                      target="_blank">
                      Right-Sizing a Gateway
                    </a>
                  </SubTitle>
                  <SubTitle mt={4}>
                    2.{" "}
                    <a
                      style={{
                        color: "#D9E139",
                        textDecoration: "none"
                      }}
                      href="https://ethica.zendesk.com/hc/en-us/articles/360021899572"
                      target="_blank">
                      Provisioning-a-Server-for-Use-as-a-Gateway
                    </a>
                  </SubTitle>
                  <SubTitle mt={4}>
                    {" "}
                    If you'd like to use an IaaS service like AWS (EC2 or
                    Lightsail), or Digital Ocean, we suggest using their
                    smallest instance.
                  </SubTitle>
                  <SubTitle mt={4}>
                    {" "}
                    Once your server is ready, please simply enter its IP
                    address, give it a name, and click "create gateway" below.
                  </SubTitle>
                  <Heading mt={4} children={"Setup Gateway"} />
                  <GradientFlex flexDirection="row" mt={4} p={20} bg="greys.3">
                    <Flex flexDirection="column" p={30} m={"0 3%"}>
                      <SubTitle mt={4} children={"Gateway Name"} />
                      <Box pt={15}>
                        <FastField
                          name="name"
                          label="Gateway Name"
                          component={TextField}
                          css={{ maxWidth: "220px" }}
                        />
                      </Box>
                    </Flex>
                    <Flex flexDirection="column" p={30} m={"0 3%"}>
                      <SubTitle mt={4} children={"Server IP Address"} />
                      <Box pt={15}>
                        <FastField
                          validate={value =>
                            value ? validateIPAddress(value) : null
                          }
                          name="ip"
                          label="Server IP Address"
                          component={TextField}
                          css={{ maxWidth: "220px" }}
                        />
                      </Box>
                    </Flex>
                    {url !== "" && (
                      <Flex flexDirection="column" p={30} m={"0 3%"}>
                        {/* <SubTitle mt={4} children={"Install Scripts"} /> */}
                        <Button
                          fontSize={13}
                          bg="skyBlue"
                          fontSize={20}
                          fontWeight={300}
                          children="Copy Install Scripts"
                          type="button"
                          onClick={() => this.handleCopy(url)}
                          mt={4}
                          ml={6}
                          p={"30px 30px"}
                        />
                      </Flex>
                    )}
                  </GradientFlex>
                </Flex>
              </Flex>
              <GradientFlex
                flex={"0 0 100px"}
                align="center"
                mt={30}
                mb={30}
                css={{
                  borderRadius: "15px",
                  boxShadow: "0px 4px 10px -3px rgba(0, 0, 0, 0.34)"
                }}>
                <Box flex={1} pl={50} />
                {url !== "" && (
                  <NavLink fontSize={60} to="/console/company-profile">
                    <PrimaryButton
                      children="Create A Site"
                      type="button"
                      fontSize={13}
                      fontSize={20}
                      fontWeight={100}
                      p={"15px 30px"}
                    />
                  </NavLink>
                )}

                <Flex
                  flex={"0 0 500px"}
                  justify="space-around"
                  align="center"
                  pr={30}>
                  <Link
                    children="Cancel Setup"
                    underline={true}
                    fontSize={20}
                    onClick={this.cancelSetup}
                  />
                  <PrimaryButton
                    fontSize={13}
                    bg="skyBlue"
                    fontSize={20}
                    fontWeight={300}
                    disabled={disableSubmit}
                    // disabled={isSubmitting}
                    type="submit"
                    p={"15px 30px"}>
                    Create Gateway
                  </PrimaryButton>
                </Flex>
              </GradientFlex>
            </Flex>
          </Form>
        )}
      />
    )
  }
}
