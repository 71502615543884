import { createMuiTheme } from "@material-ui/core/styles"
import { orange } from "@material-ui/core/colors"

const rebass = {
  fonts: {
    sans: "Montserrat"
  },
  colors: {
    greys: [
      "#454545",
      "#999999",
      "#BCBCCA",
      "#949191",
      "#FAFAFA",
      "#DBDBDB",
      "#ACA7B4",
      "#969191"
    ],
    greyGradientBg:
      "-webkit-linear-gradient(-45deg, rgba(112,112,114,1) 0%, rgba(42,42,45,1) 100%)",
    navHlBg: "rgb(238, 57, 104)",
    navBg: "black",
    darkOrange: "#F15301",
    green: "#00E13F",
    skyBlue: "#00A3FD",
    greyBg: "#373737",
    greenTitle: "#009260",
    redBg: "#EB2424",
    orange
  },
  fontWeights: {
    light: 300,
    normal: 400,
    semiBold: 600,
    bold: 700,
    extraBold: 800
  }
}

const material = createMuiTheme({
  palette: {
    primary: orange, // Purple and green play nicely together.
    secondary: { main: "#DBDBDB" } // This is just green.A700 as hex.
  },
  typography: {
    fontFamily: "Montserrat"
  }
})

export default {
  rebass,
  material
}
