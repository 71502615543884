import React from "react";

const GatewayIcon = props => (
  <svg
    {...props}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="400 0 600 760"
    fill="#fff"
  >
    <g>
      <g>
        <path
          d="M732.42,397.13l3.55,30.35c-25.14,6.46-46.49,17.39-63.52,32.53c-15.68,13.95-27.76,31.52-35.9,52.22
			c-13.82,35.16-13.45,72.44-13.23,94.71c0.03,2.55,0.05,4.93,0.05,7.12h57.26c0-2.37-0.03-4.95-0.05-7.7
			c-0.51-51.21,4.1-102.45,62.14-121.26l3.45,29.46L798,448.98L732.42,397.13z"
        />
        <path
          d="M680.63,435.62v-51.59h30.5L652,324.93l-59.12,59.11h30.5v69.39c-15.55-11.76-34.14-20.46-55.46-25.94l3.55-30.35
			l-65.58,51.85l51.84,65.59l3.45-29.46c25.18,8.16,40.3,22.43,49.31,40.14c1-3.58,2.37-7.09,3.41-10.73
			C623.37,481.43,640.55,451.83,680.63,435.62z"
        />
      </g>
      <path
        d="M826.07,390.58c-2.7,0-5.41,0.1-8.11,0.29c-14.11-74.85-78.78-129.96-154.81-129.96c-63.93,0-121.57,39.56-145.77,99.22
		c-34.87,2.62-68.21,22.66-94.61,57.11C397.24,450.54,382,493.55,382,532.29c0,4.2,0.19,8.49,0.56,12.75
		c1.39,16.09,14.94,28.69,30.85,28.69h157.78v-29.4H413.41c-0.82,0-2-1.06-2.07-1.87c-0.3-3.41-0.45-6.83-0.45-10.17
		c0-32.41,12.96-68.66,34.66-96.97c22.78-29.72,51.07-46.08,79.65-46.08c0,0,0,0,0.01,0c7.45,0,14.13-4.8,16.64-11.96
		c18.25-52.02,67-86.97,121.31-86.97c65.18,0,120.14,49.58,127.84,115.34c0.57,4.87,3.11,9.34,6.96,12.27
		c3.83,2.91,8.72,4.12,13.43,3.32c4.83-0.83,9.78-1.25,14.69-1.25c46.59,0,88.03,65.04,88.03,116.32v0.2
		c-0.01,4.17-3.74,7.83-7.99,7.83h-170.9v29.4h170.9c20.29,0,36.83-16.67,36.88-37.19v-0.23C943,470.3,890.84,390.58,826.07,390.58z
		"
      />
      <polygon points="699.45,186.44 663,150 626.55,186.44 643,186.44 643,225 683,225 683,186.44 	" />
      <polygon points="916.13,339.56 923.63,288.57 872.65,281.05 882.46,294.25 851.51,317.26 875.37,349.36 906.32,326.36 	" />
      <polygon points="433.3,283.92 382.96,294.95 393.98,345.29 402.85,331.45 435.32,352.25 456.9,318.58 424.43,297.77 	" />
    </g>
  </svg>
);

export default GatewayIcon;
