import React from "react"
import _ from "lodash"
import { observer, inject } from "mobx-react"
import { Flex, Text, Box, Image } from "rebass"
import { Formik, Field, FastField, Form } from "formik"
import { withStyles } from "@material-ui/core"
import { Title, GradientFlex } from "../ui"
import {
  PrimaryButton,
  TextField,
  MenuItem,
  CreateMenuItem,
  InputLabel,
  FormControl,
  SelectFormControl,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  CreateSelect,
  ErrorFormHelperText
} from "../ui"
import CompanyForm from "./CompanyForm"

class CompanyNameSelect extends React.Component {
  static defaultProps = {
    companyList: []
  }

  state = {
    value: "None"
  }

  handleChange = event => {
    this.setState({ value: event.target.value })
    this.props.onChange(event)
  }

  handleBlur = event => {
    if (!this.state.value) {
      this.props.setFieldTouched(_.get(this.props, "field.name"), true)
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const { value } = this.state
    const { setFieldTouched, onCreate, companyList, ...other } = this.props
    return (
      <CreateSelect
        {...other}
        value={value}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        renderValue={value =>
          value === "None"
            ? "Select/Create"
            : _.get(_.find(companyList, { id: value }), "name")
        }
        displayEmpty
        inputProps={{
          name: "companyName",
          id: "companyName",
          title:
            companyList.length === 0
              ? "No Company Profile Added"
              : "Select Existing Company",
          buttonLabel: "Create New Company Profile",
          onButtonClick: onCreate
        }}>
        {_.map(companyList, (data, i) => (
          <CreateMenuItem key={_.get(data, "id")} value={_.get(data, "id")}>
            <Flex flexDirection="column" fontSize={13}>
              <Text fontWeight="semiBold" children={_.get(data, "name")} />
              <Text
                children={_.get(data, "desc")}
                color="greys.3"
                fontSize={11}
              />
            </Flex>
          </CreateMenuItem>
        ))}
      </CreateSelect>
    )
  }
}

@inject("userStore", "companyStore", "uxStore")
@observer
export default class CompanyProfile extends React.Component {
  state = {
    selectedCompany: this.getSelectedCompany(this.props),
    newCompanyForm: false,
    disableSubmit: true,
    company: null,
    isCompanyFormDirty: false
  }

  componentWillReceiveProps(nextProps) {
    if (
      _.get(nextProps, "location.pathname") ===
      _.get(this.props, "location.pathname")
    ) {
      return
    }

    this.checkAndUpdateState(nextProps)
  }

  componentWillMount() {
    this.checkAndUpdateState(this.props)
  }

  getCompanyIdFromPath(props) {
    return _.get(props, "match.params.id")
  }

  getSelectedCompany(props) {
    return _.find(props.companyStore.companies, {
      id: this.getCompanyIdFromPath(props)
    })
  }

  checkAndUpdateState(props) {
    let companyId

    if (_.get(props, "location.pathname") === "/console/company-profile/new") {
      this.setState({ newCompanyForm: true, disableSubmit: true })
    } else if ((companyId = this.getCompanyIdFromPath(props))) {
      this.setState({
        selectedCompany: this.getSelectedCompany(props),
        disableSubmit: true
      })
    } else {
      this.setState({
        newCompanyForm: false,
        selectedCompany: null,
        disableSubmit: true
      })
    }
  }

  handleCreate = () => {
    this.props.history.push("/console/company-profile/new")
  }

  handleChange = event => {
    const companyId = event.target.value
    this.props.history.push(`/console/company-profile/${companyId}`)
  }

  handleCompanyFormError = hasError => {
    if (this.state.disableSubmit !== hasError) {
      this.setState({ disableSubmit: hasError })
    }
  }

  handleCompanyFormSubmit = async values => {
    try {
      const { userId: ownerId } = this.props.userStore.currentUser
      let response = null
      let id = this.getCompanyIdFromPath(this.props)
      let newCompanyId = null
      let rootFormik = this.rootFormik

      // value.notes CANNOT be blank ( Temporary Fix )
      if (values.notes === "") {
        values.notes = " "
      }

      if (id) {
        await this.props.companyStore.updateCompany({
          ...values,
          ...{ ownerId, id }
        })
        this.props.uxStore.openToast({
          message: "Company Updated !"
        })
      } else {
        newCompanyId = await this.props.companyStore.createCompany({
          ...values,
          ...{ ownerId }
        })
        this.props.uxStore.openToast({
          status: "error",
          message: `New Company 
          Created !`
        })
      }

      rootFormik.setSubmitting(false)

      this.props.history.push({
        pathname: "/console/create-site",
        state: { companyId: id || newCompanyId }
      })
    } catch (err) {
      console.log({ err })
      this.props.uxStore.openToast({
        status: "error",
        message: "Error occurred !"
      })
    }
  }

  handleCompanyFormDirty = isCompanyFormDirty => {
    if (this.state.isCompanyFormDirty !== isCompanyFormDirty) {
      this.setState({ isCompanyFormDirty })
    }
  }

  handleContinue = () => {
    this.rootFormik.setSubmitting(false)
    this.props.history.push({
      pathname: "/console/create-site",
      state: { companyId: this.getCompanyIdFromPath(this.props) }
    })
  }

  render() {
    const {
      selectedCompany,
      newCompanyForm,
      disableSubmit,
      isCompanyFormDirty
    } = this.state
    const companyList = _.get(this.props, "companyStore.companies", [])

    return (
      <Flex
        m="0 auto"
        width="100%"
        css={{ maxWidth: "1000px", borderRadius: "100px" }}
        flexDirection="column">
        <Flex flex="0 0 150px" align="flex-end" css={{ borderRadius: "100px" }}>
          <Title children="Company Profile" color="greys.3" pb={20} />
        </Flex>
        <Flex mb={50}>
          <Formik
            ref={_ref => (this.rootFormik = _ref)}
            onSubmit={() => {
              this.form.wrappedInstance.submit()
            }}
            render={({
              values,
              isSubmitting,
              dirty,
              touched,
              errors,
              handleSubmit,
              handleChange,
              setFieldTouched
            }) => (
              <Form
                onSubmit={handleSubmit}
                style={{ display: "flex", width: "100%" }}>
                <Flex
                  flex="1"
                  p={30}
                  pb={60}
                  bg="greys.3"
                  flexDirection="column"
                  pr={50}>
                  <Title children="Company Information" />
                  <Flex flexDirection="column">
                    {!selectedCompany && !newCompanyForm && (
                      <Box width={300} mt={20}>
                        <SelectFormControl>
                          <InputLabel htmlFor="companyName">
                            Company Name
                          </InputLabel>
                          <Field
                            name="companyName"
                            render={props => (
                              <CompanyNameSelect
                                {...props}
                                companyList={companyList}
                                setFieldTouched={setFieldTouched}
                                onChange={this.handleChange}
                                onCreate={this.handleCreate}
                              />
                            )}
                          />
                          {!!errors.companyName && touched.companyName && (
                            <ErrorFormHelperText
                              children={errors.companyName}
                            />
                          )}
                        </SelectFormControl>
                      </Box>
                    )}
                    {(selectedCompany || newCompanyForm) && (
                      <Box mt={20}>
                        <CompanyForm
                          ref={_ref => (this.form = _ref)}
                          {...selectedCompany}
                          onError={this.handleCompanyFormError}
                          onSubmit={this.handleCompanyFormSubmit}
                          onDirty={this.handleCompanyFormDirty}
                        />
                      </Box>
                    )}
                  </Flex>
                </Flex>
                <GradientFlex
                  flex="0 0 250px"
                  flexDirection="column"
                  justify="flex-end"
                  p={"40px 30px"}>
                  {selectedCompany && !isCompanyFormDirty ? (
                    <PrimaryButton
                      type="submit"
                      children="Continue"
                      onClick={this.handleContinue}
                    />
                  ) : (
                    <PrimaryButton
                      type="submit"
                      children="Save and Continue"
                      disabled={disableSubmit || isSubmitting}
                    />
                  )}
                </GradientFlex>
              </Form>
            )}
          />
        </Flex>
      </Flex>
    )
  }
}
