import React from "react"
import { withRouter, NavLink } from "react-router-dom"
import _ from "lodash"
import styled from "styled-components"
import { width, space, fontSize, fontWeight } from "styled-system"
import themes from "../../themes"
import GatewayIcon from "./GatewayIcon"
import {
  Box,
  Button as BaseButton,
  Link as BaseLink,
  Toolbar as BaseToolbar,
  Heading as BaseHeading,
  Flex,
  Image,
  Text
} from "rebass"
import {
  TextField as BaseTextField,
  Paper as BasePaper,
  Dialog as BaseDialog,
  MenuItem as BaseMenuItem,
  InputLabel as BaseInputLabel,
  FormControl as BaseFormControl,
  Select as BaseSelect,
  FormLabel,
  Radio as BaseRadio,
  FormControlLabel as BaseFormControlLabel,
  RadioGroup as BaseRadioGroup,
  FormHelperText as BaseFormHelperText,
  Checkbox as BaseCheckbox,
  Switch as BaseSwitch,
  Input as BaseInput,
  withStyles
} from "@material-ui/core"
import Check from "@material-ui/icons/Check"
import Close from "@material-ui/icons/Close"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import BaseCreateSelect from "./CreateSelect"
import InputShim from "./InputShim"

const lighterGrey = _.nth(_.get(themes, "rebass.colors.greys"), 5)
const lightGrey = _.nth(_.get(themes, "rebass.colors.greys"), 3)
const green = _.get(themes, "rebass.colors.green")

const StyledCreateSelect = styled(BaseCreateSelect)`
  &&:before {
    border-bottom-color: ${lighterGrey} !important;
  }
  svg {
    color: ${lighterGrey};
  }
`
export const CreateSelect = withStyles({
  input: {
    color: "#FFFFFF"
  }
})(({ classes, inputProps, ...other }) => {
  return (
    <StyledCreateSelect
      {...other}
      classes={classes}
      inputProps={{
        className: classes.input,
        ...inputProps
      }}
    />
  )
})

export { default as Loader } from "./Loader"
export { default as Toast } from "./Toast"

export const InputLabel = withStyles({
  root: {
    fontSize: "13px",
    color: lighterGrey
  },
  shrink: {
    fontSize: "15px"
  }
})(BaseInputLabel)

export const FormControl = withStyles({
  input: {
    fontSize: "13px",
    color: "#FFFFFF",
    "&:before": {
      borderBottomColor: "#FFFFFF !important" //TODO: find a better way to override border
    }
  }
})(({ classes, ...other }) => {
  return <BaseFormControl {...other} className={classes.root} />
})

export const SelectFormControl = withStyles({
  root: {
    minWidth: 180
  }
})(FormControl)

export const Button = styled(BaseButton)`
  cursor: pointer;
  ${width};
  padding: ${props => props.p} !important;
`
export const PrimaryButton = props => (
  <Button
    bg="rgb(238, 57, 104)"
    borderRadius={"3px"}
    fontWeight={300}
    {...props}
  />
)

export const WANButton = props => (
  <Button
    bg="rgb(238, 57, 104)"
    borderRadius={25}
    fontWeight="light"
    {...props}
  />
)

const StyledTextField = withStyles({
  root: {
    fontSize: "13px",
    color: lighterGrey
  },
  input: {
    fontSize: "13px",
    color: "#FFFFFF",
    "&:before": {
      borderBottomColor: `${lighterGrey} !important` //TODO: find a better way to override border
    }
  },
  inputDisabled: {
    color: "#ffffff78 !important"
  },
  label: {
    fontSize: "13px",
    color: lighterGrey
  },
  labelShrink: {
    fontSize: "15px",
    whiteSpace: "nowrap"
  },
  error: {
    fontSize: "11px"
  }
})(({ classes, labelStyle, value, ...other }) => {
  return (
    <BaseTextField
      {...other}
      value={value || ""}
      className={classes.root}
      InputLabelProps={{
        classes: {
          root: classes.label,
          shrink: classes.labelShrink
        },
        style: labelStyle || {}
      }}
      InputProps={{
        className: classes.input,
        classes: {
          disabled: classes.inputDisabled
        }
      }}
      FormHelperTextProps={{
        className: classes.error
      }}
      autoComplete={`new-${_.get(other, "name")}`}
    />
  )
})

export const TextField = ({ field: { ...fields }, form, css, ...props }) => {
  return (
    <Box css={css}>
      <StyledTextField
        {...fields}
        {...form}
        {...props}
        fullWidth
        error={Boolean(
          form && form.touched[fields.name] && form.errors[fields.name]
        )}
        helperText={
          form && form.touched[fields.name] && form.errors[fields.name]
        }
      />
    </Box>
  )
}

export const Link = styled(BaseLink)`
  font-size: ${props => props.fontSize}px;
  color: ${props => props.color || (props.disabled ? "#CCCCCC" : "#FFFFFF")}
  cursor: ${props => (!props.disabled ? "pointer" : "default")};
  text-decoration:  ${props => (props.underline ? "underline" : "none")};
  &:hover {
    opacity: 0.9;
  }
`

export const Nav = styled(Box)`
  overflow-y: auto;
`

const NAV_ITEM_MAP = {
  wans: { icon: "organization" },
  dashboard: { icon: "dashboard" },
  gateways: {
    component: (
      <GatewayIcon
        style={{ width: "25px", height: "25px", marginRight: "5px" }}
      />
    )
  },
  sites: { icon: "building" },
  profiles: { icon: "view-list" },
  help: { icon: "help-info", text: "HELP CENTRE" },
  admin: { icon: "cog" },
  ticket: { icon: "dashboard", text: "Support Ticket" }
}

const li = styled.li``

export const NavItem = withRouter(props => {
  const {
    item,
    to,
    children,
    css,
    location: { pathname },
    history
  } = props
  const isActive = pathname === to
  const { icon, text, component } = NAV_ITEM_MAP[item]
  const url = icon
    ? `https://icongr.am/clarity/${icon}.svg?size=22&color=FFFFFF`
    : null
  const { staticContext, ...rest } = props
  return (
    <Flex
      align="flex-end"
      css={{ cursor: "pointer", ...css }}
      pt={3}
      pb={3}
      pl={4}
      onClick={() => history.push(to)}
      bg={isActive ? "navHlBg" : null}>
      {component ? (
        component
      ) : (
        <Image width={20} height={25} src={url} mr={10} />
      )}
      <Link
        {...rest}
        is={NavLink}
        color="white"
        fontWeight="light"
        css={{ fontSize: "0.9em" }}
        to={to}
        children={text || item}
      />
    </Flex>
  )
})

export const Toolbar = styled(BaseToolbar)`
  display: flex;
  padding: 0px;
  flex: 0 0 70px;
`

export const Heading = styled(BaseHeading)`
  font-weight: 300;
  font-family: "Sunflower";
`

export const HeadlineText = Heading.extend`
  color: ${lightGrey};
  font-size: 28px;
`

export const Headline = ({ title, ...other }) => (
  <HeadlineText children={title} {...other} />
)

export const Title = Heading.extend`
  font-size: ${props => (props.fontSize ? props.fontSize : 25)}px;
`

export const SubTitle = Heading.extend`
  font-size: 15px;
  font-family: "Montserrat";
`

export const DialogSubTitle = Heading.extend`
  font-size: 1.3em;
`

export const DialogTitle = Heading.extend`
  font-size: 1.4em;
`

export const Main = styled(Box)`
  flex: 1;
  background: #eeeeee;
  min-height: 100vh;
  background: linear-gradient(
    129deg,
    rgba(71, 71, 71, 1) 0%,
    rgba(127, 127, 127, 1) 80%
  );
`

const SearchTextField = withStyles({
  root: {
    width: "100%"
  },
  input: {
    fontSize: "13px",
    color: "#FFFFFF",
    marginTop: "5px"
  }
})(({ classes, value, ...other }) => {
  return (
    <BaseTextField
      {...other}
      value={value || ""}
      className={classes.root}
      InputProps={{
        className: classes.input
      }}
    />
  )
})

export const Search = props => {
  return (
    <Flex px={3} flex="1 1 0">
      <Image
        width={18}
        src="https://icongr.am/clarity/search.svg?size=18&color=FFFFFF"
      />
      <Box flex={1} ml={10}>
        <SearchTextField placeholder="Search sites, WANs or help" />
      </Box>
    </Flex>
  )
}

export const Sidebar = styled(Flex)`
  background-color: #2a2a2d;

  .sidebar-close-icon {
    display: none;
  }

  .sidebar-header {
    justify-content: flex-start;
  }

  @media (max-width: 1054px) {
    position: fixed;
    height: 100%;
    left: 0;
    top: 0;
    transform: translateX(-220px);
    transition: 0.3s;
    z-index: 1;

    .sidebar-close-icon {
      cursor: pointer;
      display: block;
    }

    .sidebar-header {
      justify-content: space-around;
    }

    .sidebar-header-title {
      padding-left: 7px;
    }
  }

  ${props =>
    props.show &&
    `
    transform: translateX(0px) !important;
  `}
`

export const MainContainer = styled(Flex)`
  &:after {
    pointer-events: none;
    background: #333;
    content: "";
    width: 100%;
    height: 100%;
    top: 0px;
    left: 220px;
    opacity: 0;
    position: fixed;
  }
  ${props =>
    props.showBg &&
    `
      &:after {
        transition: 0.2s;
        background: #333;
        content: "";
        width: 100%;
        height: 100%;
        top: 0px;
        left: 220px;
        opacity: .5;
        position: fixed;
      }
    `}
`

const NavMenuFlex = styled(Flex)`
  cursor: pointer;
  display: none;
  @media (max-width: 1054px) {
    display: block;
  }
`

export const NavMenu = props => {
  return (
    <NavMenuFlex px={3} {...props}>
      <Image
        width={25}
        src="https://icongr.am/clarity/menu.svg?size=25&color=FFFFFF"
      />
    </NavMenuFlex>
  )
}

export const SearchInput = props => {
  return (
    <Flex px={3} flex="1 1 0">
      <SearchTextField placeholder={props.placeholder} />
      <Image
        width={18}
        src="https://icongr.am/clarity/search.svg?size=18&color=FFFFFF"
      />
    </Flex>
  )
}

// DIALOGS

export const Dialog = styled(BaseDialog)`
  [role="dialog"] {
    top: -5%;
  }

  [role="document"] {
    background: #333;
    max-width: 700px;
    height: 270px;
    width: 100%;
  }
`

export const FormDialog = styled(BaseDialog)`
  [role="document"] {
    background: #969191;
    border-radius: 0;
    max-width: 1400px;
    max-height: 600px;
    margin: 0 12%;
    height: 100%;
    width: 100%;
  }
`

export const GradientBox = styled(Box)([], {
  backgroundImage: _.get(themes, "rebass.colors.greyGradientBg")
})

export const GradientFlex = styled(Flex)([], {
  backgroundImage: _.get(themes, "rebass.colors.greyGradientBg")
})

export const Paper = BasePaper

export const FlatPaper = withStyles({
  root: {
    borderRadius: 0
  }
})(Paper)

export const MenuItem = withStyles({
  root: {
    fontSize: 13
  }
})(BaseMenuItem)

export const CreateMenuItem = withStyles({
  root: {
    background: "#FAFAFA",
    padding: 20
  }
})(MenuItem)

const StyledSelect = withStyles({
  root: {
    fontSize: 13,
    paddingBottom: 3.5
  },
  select: {
    fontSize: 13,
    paddingBottom: 0
  },
  selectMenu: {
    fontSize: 13,
    borderRadius: 0
  },
  icon: {
    color: lighterGrey
  },
  input: {
    fontSize: "13px",
    color: "#FFFFFF",
    "&:before": {
      borderBottomColor: `${lighterGrey} !important`
    }
  },
  disabled: {
    color: "#ffffff78 !important"
  }
})(({ classes: { input, selectMenu, ...otherClasses }, error, ...other }) => {
  return (
    <BaseSelect
      {...other}
      classes={otherClasses}
      inputProps={{
        className: other.input || input
      }}
      MenuProps={{
        className: selectMenu,
        PaperProps: {
          style: {
            borderRadius: 0
          }
        }
      }}
    />
  )
})

const ExtendedStyledSelect = styled(StyledSelect)`
  &&:before {
    border-bottom-color: ${lighterGrey} !important;
  }
`

export class Select extends React.PureComponent {
  static defaultProps = {
    onChange: () => {},
    setFieldTouched: () => {},
    value: ""
  }

  state = {
    value: this.props.value || ""
  }

  handleChange = event => {
    const { value } = event.target
    this.setState({ value })
    this.props.field && this.props.field.onChange(event)
    this.props.onChange(value)
  }

  handleBlur = () => {
    if (!this.state.value) {
      this.props.setFieldTouched(_.get(this.props, "field.name"), true)
    }
  }

  render() {
    const {
      htmlFor,
      labelText,
      children,
      field,
      form,
      error,
      touched,
      frmControlStyle,
      labelStyle,
      displayEmpty,
      renderValue,
      classes,
      disabled
    } = this.props
    return (
      <SelectFormControl
        style={frmControlStyle}
        error={Boolean(
          form && form.touched[field.name] && form.errors[field.name]
        )}>
        <InputLabel htmlFor={htmlFor} style={labelStyle}>
          {labelText}
        </InputLabel>
        <ExtendedStyledSelect
          {...field}
          classes={classes}
          value={this.state.value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          inputProps={{
            name: htmlFor,
            id: htmlFor
          }}
          displayEmpty={displayEmpty}
          renderValue={renderValue}
          children={children}
          disabled={disabled}
        />
        {!!error && touched && <ErrorFormHelperText children={error} />}
      </SelectFormControl>
    )
  }
}

export const FormControlLabel = withStyles({
  label: {
    color: "#FFFFFF"
  }
})(BaseFormControlLabel)

const StyledRadio = withStyles({
  root: {
    color: lightGrey
  }
})(BaseRadio)

export const Radio = styled(StyledRadio)`
  svg {
    width: 1.5em;
    height: 1.5em;
  }
`
const StyledRadioGroup = withStyles({
  root: {
    flexDirection: "row"
  }
})(BaseRadioGroup)

export class RadioGroup extends React.Component {
  state = {
    value: this.props.value
  }

  handleChange = event => {
    this.setState({ value: event.target.value })
    this.props.field && this.props.field.onChange(event)
    this.props.onChange && this.props.onChange(event)
  }

  render() {
    const {
      htmlFor,
      labelText,
      children,
      field,
      value,
      labelComponent
    } = this.props
    return (
      <FormControl component="fieldset">
        {labelComponent ? (
          labelComponent
        ) : (
          <FormLabel component={"legend"}>{labelText}</FormLabel>
        )}
        <StyledRadioGroup
          value={value}
          {...field}
          onChange={this.handleChange}
          aria-label={labelText}
          name={htmlFor}>
          {children}
        </StyledRadioGroup>
      </FormControl>
    )
  }
}

export const ErrorFormHelperText = withStyles({
  root: {
    color: "red",
    fontSize: 11
  }
})(BaseFormHelperText)

const StyledCheckBox = withStyles({
  colorPrimary: {
    color: "#FFFFFF"
  },
  sizeIcon: {
    fontSize: 30
  }
})(({ classes: { sizeIcon, label, ...otherClasses }, ...other }) => {
  return (
    <BaseCheckbox
      {...other}
      classes={otherClasses}
      icon={<CheckBoxOutlineBlankIcon className={sizeIcon} />}
      checkedIcon={<CheckBoxIcon className={sizeIcon} />}
    />
  )
})

export class Checkbox extends React.Component {
  static defaultProps = {
    field: {}
  }

  constructor(props) {
    super(props)
    const fieldName = _.get(props.field, "name", props.name)
    this.state = {
      [fieldName]: _.get(props.field, "value") || _.get(props, "checked")
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked })
    this.props.field && this.props.field.onChange(event)
    this.props.onChange(name, event.target.checked)
  }

  componentWillReceiveProps(nextProps) {
    const fieldName = _.get(nextProps.field, "name", nextProps.name)

    if (
      _.get(nextProps, "field.value") !== _.get(this.props, "field.value") &&
      this.state[fieldName] !== _.get(nextProps, "field.value")
    ) {
      this.setState({
        [fieldName]: _.get(nextProps, "field.value")
      })
    }

    if (
      (nextProps.reset ||
        (!_.isUndefined(_.get(nextProps, "checked")) &&
          _.get(nextProps, "checked") !== _.get(this.props, "checked"))) &&
      _.get(nextProps, "checked") !== this.state[fieldName]
    ) {
      this.setState({
        [fieldName]: _.get(nextProps, "checked")
      })
    }
  }

  render() {
    const {
      label,
      classes: {
        label: labelClass,
        labelRoot = {},
        labelChecked,
        labelDisabled,
        ...otherClasses
      },
      name,
      checked,
      field,
      ...other
    } = this.props
    const fieldName = _.get(field, "name", name)

    return label ? (
      <FormControlLabel
        control={
          <StyledCheckBox
            {...other}
            {...field}
            name={fieldName}
            value={fieldName}
            classes={otherClasses}
            checked={this.state[fieldName]}
            color="primary"
            disableRipple
            onChange={this.handleChange(fieldName)}
          />
        }
        label={label}
        classes={{
          label: (this.state[fieldName] && labelChecked) || labelClass,
          root: labelRoot,
          disabled: labelDisabled
        }}
      />
    ) : (
      <StyledCheckBox
        {...other}
        {...field}
        name={fieldName}
        value={fieldName}
        color="primary"
        disableRipple
        classes={otherClasses}
        checked={this.state[name]}
        onChange={this.handleChange(name)}
      />
    )
  }
}

Checkbox.defaultProps = {
  onChange: () => {},
  checked: false,
  classes: {}
}

export { default as Tree } from "./Tree"

const SwitchFormLabel = withStyles({
  label: {
    fontSize: "13px"
  }
})(FormControlLabel)

class Switch extends React.Component {
  static defaultProps = {
    field: { onChange: () => {} },
    onChange: () => {}
  }

  constructor(props) {
    super(props)
    const fieldName = _.get(props.field, "name", props.name)
    this.state = {
      [fieldName]: _.get(props.field, "value") || _.get(props, "checked")
    }
  }

  componentWillReceiveProps(nextProps) {
    const fieldName = _.get(nextProps.field, "name", nextProps.name)

    if (
      _.get(nextProps, "field.value") !== _.get(this.props, "field.value") &&
      this.state[fieldName] !== _.get(nextProps, "field.value")
    ) {
      this.setState({
        [fieldName]: _.get(nextProps, "field.value")
      })
    }

    if (
      (nextProps.reset ||
        (!_.isUndefined(_.get(nextProps, "checked")) &&
          _.get(nextProps, "checked") !== _.get(this.props, "checked"))) &&
      _.get(nextProps, "checked") !== this.state[fieldName]
    ) {
      this.setState({
        [fieldName]: _.get(nextProps, "checked")
      })
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked })
    this.props.field && this.props.field.onChange(event)
    this.props.onChange(name, event.target.checked)
  }

  render() {
    const { label, classes, name, checked, field, ...other } = this.props
    const fieldName = _.get(field, "name", name)

    return (
      <SwitchFormLabel
        style={this.props.labelStyle}
        control={
          <BaseSwitch
            {...other}
            {...field}
            name={fieldName}
            value={fieldName}
            disableRipple
            checked={this.state[fieldName]}
            classes={this.props.classes}
            color="primary"
            onChange={this.handleChange(fieldName)}
          />
        }
        label={this.props.label}
      />
    )
  }
}

const switchStyle = {
  colorPrimary: {
    color: "#FFF"
  },
  icon: {
    width: "15px",
    height: "15px"
  },
  bar: {
    backgroundColor: "#CCC",
    opacity: 1
  },
  iconChecked: {
    color: "#FFF"
  },
  checked: {
    "& + $bar": {
      opacity: 1
    }
  }
}

export const PrimarySwitch = withStyles(switchStyle)(Switch)

export const SecondarySwitch = withStyles({
  ...switchStyle,
  checked: {
    "& + $bar": {
      backgroundColor: `${green} !important`,
      opacity: 1
    }
  }
})(Switch)

export const Input = withStyles({
  root: {
    color: lighterGrey,
    "&:before": {
      borderBottomColor: `${lighterGrey} !important` //TODO: find a better way to override border
    }
  },
  disabled: {
    color: "#ffffff78 !important"
  }
})(BaseInput)

const styles = theme => ({
  iOSSwitchBase: {
    "&$iOSChecked": {
      color: theme.palette.common.white,
      "& + $iOSBar": {
        backgroundColor: "#52d869"
      }
    },
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp
    })
  },
  iOSChecked: {
    transform: "translateX(25px)",
    "& + $iOSBar": {
      opacity: 1,
      border: "none"
    }
  },
  iOSBar: {
    borderRadius: 13,
    width: 55,
    height: 26,
    marginTop: -13,
    marginLeft: -21,
    border: "solid 1px",
    borderColor: theme.palette.grey[400],
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"])
  },
  iOSIcon: {
    width: 20,
    height: 20
  },
  iOSIconChecked: {
    boxShadow: theme.shadows[1]
  }
})

class BaseIOSSwitch extends React.Component {
  render() {
    const { classes, ...other } = this.props
    return (
      <Switch
        {...other}
        classes={{
          switchBase: classes.iOSSwitchBase,
          bar: classes.iOSBar,
          icon: classes.iOSIcon,
          iconChecked: classes.iOSIconChecked,
          checked: classes.iOSChecked
        }}
      />
    )
  }
}

export const IOSSwitch = withStyles(styles)(BaseIOSSwitch)

export const SmallIOSSwitch = withStyles({
  iOSSwitchBase: {
    transform: "translateX(10px)"
  },
  iOSBar: {
    width: 29,
    height: 14,
    marginTop: -7,
    marginLeft: -6
  },
  iOSIcon: {
    width: 12,
    height: 12
  },
  iOSChecked: {
    transform: "translateX(23px)"
  }
})(IOSSwitch)

export { default as Plus } from "./Plus"

export const SearchInputShim = styled(InputShim)`
  background: none;
  border: 0;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  outline: none;
  padding: 10px;
  text-align: right;
`

const legendCSS = {
  width: "2em",
  height: "2em",
  borderRadius: "100%",
  justifyContent: "center",
  alignItems: "center"
}

const innerLegendCSS = {
  boxShadow: "0px 4px 10px -3px rgba(0, 0, 0, 0.34)",
  width: ".9em",
  height: ".9em",
  borderRadius: "100%"
}

export const Legends = {
  Good: ({ style = {}, innerStyle = {} }) => (
    <Flex css={{ ...{ background: "#C1F5E0" }, ...legendCSS, ...style }}>
      <Box
        css={{ ...{ background: "#00FD1E" }, ...innerLegendCSS, ...innerStyle }}
      />
    </Flex>
  ),
  Warning: ({ style = {}, innerStyle = {} }) => (
    <Flex css={{ ...{ background: "#F4DFBC" }, ...legendCSS, ...style }}>
      <Box
        css={{ ...{ background: "#FF8D00" }, ...innerLegendCSS, ...innerStyle }}
      />
    </Flex>
  ),
  Critical: ({ style = {}, innerStyle = {} }) => (
    <Flex css={{ ...{ background: "#FFD1C0" }, ...legendCSS, ...style }}>
      <Box
        css={{ ...{ background: "#FF7B42" }, ...innerLegendCSS, ...innerStyle }}
      />
    </Flex>
  ),
  On: ({ style = {}, innerStyle = {} }) => (
    <Flex css={{ ...{ background: "#DBF7E8" }, ...legendCSS, ...style }}>
      <Image src="https://icongr.am/fontawesome/check.svg?size=18&color=00DE1E" />
    </Flex>
  ),
  Off: ({ style = {}, innerStyle = {} }) => (
    <Flex css={{ ...{ background: "#EBEBEB" }, ...legendCSS, ...style }}>
      <Close style={{ color: "#373737", width: "0.7em", ...innerStyle }} />
    </Flex>
  )
}

export { default as VerticalMenu } from "./VerticalMenu"
export { default as Menu } from "./Menu"
