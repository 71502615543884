import React from "react"
import ReactDOM from "react-dom"
import _ from "lodash"
import { Switch, Route, NavLink } from "react-router-dom"
import { inject } from "mobx-react"
import { Box, Flex, Text, Image, Border, Circle, Heading } from "rebass"
import { withStyles } from "@material-ui/core"
import ArrowDropDownIcon from "@material-ui/core/internal/svg-icons/ArrowDropDown"
import {
  Nav,
  Toolbar,
  NavItem,
  Main,
  Search,
  MainContainer,
  Sidebar,
  NavMenu,
  WANButton,
  PrimaryButton,
  Loader,
  GradientFlex,
  Toast,
  Menu,
  MenuItem
} from "../ui"
import Dashboard from "./Dashboard"
import Site from "./Site"
import SiteList from "./SiteList"
import CreateSite from "./CreateSite"
import CompanyProfile from "./CompanyProfile"
import SiteConfig from "./SiteConfig"
import Wans from "./Wans"
import WANList from "./WANList"
import GatewayList from "./GatewayList"
import ProfileList from "./ProfileList"
import WANConfig from "./WANConfig"
import CreateGateway from "./CreateGateway"
import Profiles from "./Profiles"
import Help from "./Help.js"
import Admin from "./Admin.js"
import CreateDialog from "./dialogs/CreateDialog"
import { CREATE_DIALOG } from "./dialogs/constants"
import DevTools from "mobx-react-devtools"
import { hot } from "react-hot-loader"

const NavMenuItem = withStyles({
  root: {
    color: "#fff",
    fontSize: 14
  }
})(MenuItem)

@inject(
  "uxStore",
  "userStore",
  "companyStore",
  "siteStore",
  "wanStore",
  "internetGatewayStore"
)
class Console extends React.Component {
  state = {
    loaded: false,
    showSidebar: false,
    showBg: false
  }

  renderLoader() {
    return (
      <GradientFlex justify="center" align="center" css={{ height: "100vh" }}>
        <Loader />
      </GradientFlex>
    )
  }

  showSidebar = () => {
    this.setState({ showSidebar: true }, () => {
      setTimeout(() => {
        this.setState({ showBg: true })
      }, 100)
    })
  }

  hideSidebar = event => {
    if (!this.state.showSidebar) return
    if (
      this.sidebarRef &&
      ReactDOM.findDOMNode(this.sidebarRef).contains(event.target)
    ) {
      return
    }
    this.setState({ showSidebar: false, showBg: false })
  }

  handleLogout = () => {
    this.props.history.push("/out")
  }

  render() {
    const { loaded, showSidebar, showBg } = this.state
    return !loaded ? (
      this.renderLoader()
    ) : (
      <MainContainer showBg={showBg} onClick={this.hideSidebar}>
        <Sidebar
          flexDirection="column"
          show={showSidebar}
          ref={_ref => (this.sidebarRef = _ref)}>
          <Flex
            className="sidebar-header"
            flex="0 0 70px"
            bg="black"
            justify="space-around"
            align="center">
            <Image
              width={100}
              ml={"20%"}
              height={20}
              src="https://www.ethica.partners/wp-content/uploads/2018/11/ethica-logo.png"
            />
          </Flex>
          <Nav flex={2} bg="navBg" color="white" width={220}>
            <Flex pt={4} flexDirection="column">
              {_.map(
                ["dashboard", "gateways", "wans", "sites", "profiles"],
                (item, i) => (
                  <NavItem
                    css={{ textTransform: "uppercase" }}
                    key={`nav-item-${i}`}
                    to={`/console/${item}`}
                    item={item}
                  />
                )
              )}
            </Flex>
            <Flex pt={200} flexDirection="column">
              <Flex
                align="flex-end"
                css={{ cursor: "pointer" }}
                pt={3}
                pb={3}
                pl={4}>
                <a href="https://ethica.zendesk.com/hc/en-us" target="_blank">
                  <Image
                    width={20}
                    height={25}
                    src="https://icongr.am/clarity/help-info.svg?size=22&color=FFFFFF"
                    mr={10}
                  />
                </a>
                <a
                  fontWeight="light"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "0.9em"
                  }}
                  href="https://ethica.zendesk.com/hc/en-us"
                  target="_blank">
                  HELP CENTRE
                </a>
              </Flex>
              <NavItem
                css={{ textTransform: "uppercase" }}
                to="/console/admin"
                item="admin"
              />
              <Flex
                align="flex-end"
                css={{ cursor: "pointer" }}
                pt={3}
                pb={3}
                pl={4}>
                <a href="mailto:help@ethica.zendesk.com">
                  <Image
                    width={20}
                    height={25}
                    src="https://icongr.am/material/account-question-outline.svg?size=22&color=FFFFFF"
                    mr={10}
                  />
                </a>
                <a
                  fontWeight="light"
                  style={{
                    color: "white",
                    textDecoration: "none",

                    fontSize: "0.9em"
                  }}
                  href="mailto:help@ethica.zendesk.com">
                  Support Ticket
                </a>
              </Flex>
            </Flex>
          </Nav>
        </Sidebar>
        <Flex width={1} flexDirection="column">
          <Toolbar css={{ minWidth: "830px" }}>
            <NavMenu onClick={this.showSidebar} />
            <Search />

            <Menu
              align="center"
              title={() => (
                <Flex
                  align="center"
                  flexDirection="row"
                  css={{ cursor: "pointer" }}>
                  <PrimaryButton>
                    <Flex flexDirection="row">
                      <Text
                        ml={2}
                        pr={1}
                        mt={1}
                        children="Create"
                        css={{ overflow: "hidden", textOverflow: "ellipsis" }}
                        fontSize={14}>
                        Create
                      </Text>
                      <ArrowDropDownIcon ml={3} pr={1} />
                    </Flex>
                  </PrimaryButton>
                </Flex>
              )}>
              <NavMenuItem
                onClick={() => {
                  this.props.history.push("/console/company-profile")
                }}>
                Create Site
              </NavMenuItem>
              <NavMenuItem
                onClick={() => {
                  this.props.history.push("/console/create-gateway")
                }}>
                {" "}
                Create Gateway
              </NavMenuItem>
            </Menu>
            <Flex mx={3} flex="0 0 320px">
              <Flex flex="0 0 150px" align="center" justify="space-around">
                <Image
                  width={18}
                  src="https://icongr.am/clarity/help-info.svg?size=18&color=BCBCCA"
                />
                <Image
                  width={18}
                  src="https://icongr.am/clarity/talk-bubbles.svg?size=18&color=BCBCCA"
                />
                <Image
                  width={18}
                  src="https://icongr.am/clarity/notification.svg?size=18&color=BCBCCA"
                />
              </Flex>
              <Border
                mx={10}
                borderColor="greys.3"
                border={0}
                borderRight={1}
              />
              <Flex flex="0 0 150px" align="center">
                <Menu
                  title={() => (
                    <Flex align="center" css={{ cursor: "pointer" }}>
                      <Text
                        ml={3}
                        pr={3}
                        children="Admin"
                        width={65}
                        css={{ overflow: "hidden", textOverflow: "ellipsis" }}
                        fontSize={13}
                      />
                      <Image
                        width={17}
                        src="https://icongr.am/material/chevron-down.svg?color=BCBCCA"
                      />
                    </Flex>
                  )}>
                  <NavMenuItem onClick={this.handleLogout}>Logout</NavMenuItem>
                </Menu>
                <Circle size={30} bg="greys.1">
                  JD
                </Circle>
              </Flex>
            </Flex>
          </Toolbar>
          <Main color="white">
            <Switch>
              <Route exact path="/console/dashboard" component={Dashboard} />
              <Route exact path="/console/sites" component={SiteList} />
              <Route exact path="/console/profiles" />
              <Route exact path="/console/sites/:id" component={Site} />
              <Route
                exact
                path="/console/company-profile"
                component={CompanyProfile}
              />
              <Route
                exact
                path="/console/company-profile/new"
                component={CompanyProfile}
              />
              <Route
                exact
                path="/console/company-profile/:id"
                component={CompanyProfile}
              />
              <Route exact path="/console/create-site" component={CreateSite} />
              <Route exact path="/console/site-config" component={SiteConfig} />
              <Route exact path="/console/wans" component={WANConfig} />
              <Route exact path="/console/gateways" component={GatewayList} />
              <Route exact path="/console/wans/:id" component={WANConfig} />
              <Route exact path="/console/wan-config" component={WANConfig} />
              <Route exact path="/console/profiles" component={Profiles} />
              <Route exact path="/console/admin" />
              <Route
                exact
                path="/console/create-gateway"
                component={CreateGateway}
              />
            </Switch>
          </Main>
        </Flex>
        <CreateDialog />
        <Toast />
        {process.env.REACT_APP_ENV !== "production" && <DevTools />}
      </MainContainer>
    )
  }

  componentDidMount() {
    const { userId } = this.props.userStore.currentUser
    Promise.all([
      this.props.companyStore.fetchCompanies(userId),
      this.props.siteStore.allUserSites(userId),
      this.props.wanStore.allUserWans(userId),
      this.props.internetGatewayStore.allUserInternetGateways(userId),
      this.props.userStore.getMiscConfig()
    ]).then(() => {
      this.setState({ loaded: true })
    })
  }
}

export default hot(module)(Console)
