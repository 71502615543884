import React from "react"
import { Flex, Image, Text, Box } from "rebass"
import styled from "styled-components"
import { inject } from "mobx-react"
import { withStyles } from "@material-ui/core"

import { Dialog as BaseDialog } from "@material-ui/core"
import { LOG_VIEWER } from "./constants"
import ssDialog from "./decorator"
import { Select, MenuItem, Link } from "../../ui"
import { copyToClipboard } from "../../../utils"

const dateTimeData = ["2018-11-13 10:56 AM"]

export const DatetimeSelect = withStyles({
  input: {
    fontWeight: "bold"
  }
})(Select)

export const LogDialog = styled(BaseDialog)`
  [role="document"] {
    background: #333;
    border-radius: 0;
    max-width: 850px;
    height: 750px;
    margin: 0 12%;
    height: 100%;
    width: 100%;
  }
`

const Pre = props => (
  <Box width="100%" {...props} flex={1}>
    <pre {...props}>{JSON.stringify(JSON.parse(props.children), null, 10)}</pre>
  </Box>
)

const CloseLink = styled(Link)`
  font-weight: bold;
`

const Code = styled(Pre)`
  pre {
    font-size: 13px;
    height: 100%;
    max-height: 710px;
    width: 100%;
    padding: 0 15px;
    margin: 0;
    overflow: auto;
    color: #333;
    background: #f1f1f1;
    font-family: Sunflower;
  }
`

const LOG = `
[{}]
`

@inject("uxStore")
@ssDialog({ key: LOG_VIEWER, component: LogDialog })
export default class LogViewerDialog extends React.Component {
  handleCopy = async () => {
    const success = await copyToClipboard(
      JSON.stringify(JSON.parse(LOG), null, 10)
    )
    if (success) {
      this.props.uxStore.openToast({ message: "Copied !" })
    }
  }

  render() {
    return (
      <Flex align="center" flexDirection="column" flex="1" justify="flex-start">
        <Flex
          justify="space-between"
          width="100%"
          align="flex-end"
          flex={"0 0 90px"}
          p={"0 20px 20px 20px"}>
          <DatetimeSelect
            labelText="Date and time Created"
            labelStyle={{ width: "200px" }}>
            {_.map(dateTimeData, (data, i) => (
              <MenuItem key={i} children={data} value={data} />
            ))}
          </DatetimeSelect>
          <Flex
            align="flex-end"
            onClick={this.handleCopy}
            css={{ cursor: "pointer" }}>
            <Image
              className="copy-to-clipboard-icon"
              width={22}
              src="https://icongr.am/clarity/copy.svg?size=22&color=BCBCCA"
            />
            <Text color="#FFFFFF" fontSize={12} ml={"5px"} fontWeight="bold">
              Copy to Clipboard
            </Text>
          </Flex>
        </Flex>
        <Code>{LOG}</Code>
        <Flex
          flex={"0 0 50px"}
          align="center"
          width="100%"
          justify="flex-end"
          pr={20}>
          <CloseLink
            children="Close"
            underline
            fontSize={13}
            onClick={this.props.onClose}
          />
        </Flex>
      </Flex>
    )
  }
}
