import React from "react"
import { Flex, Text, Box } from "rebass"
import _ from "lodash"
import { withStyles } from "@material-ui/core"
import ArrowDropDownIcon from "@material-ui/core/internal/svg-icons/ArrowDropDown"
import Collapse from "@material-ui/core/Collapse"
import { SubTitle } from "./index"
import themes from "../../themes"

const lighterGrey = _.nth(_.get(themes, "rebass.colors.greys"), 5)
const UpArrowDropDownIcon = withStyles({
  root: {
    fill: lighterGrey,
    marginLeft: 15,
    transform: "rotate(-90deg)"
  }
})(ArrowDropDownIcon)
const DownArrowDropDownIcon = withStyles({
  root: {
    transform: "rotate(0deg)"
  }
})(UpArrowDropDownIcon)

class Tree extends React.Component {
  state = {
    show: this.props.show
  }

  toggle = () => {
    this.setState(prevState => {
      return { show: !prevState.show }
    })
  }

  render() {
    const { show } = this.state
    return (
      <Flex flexDirection="column" width={this.props.width}>
        <Flex
          onClick={this.toggle}
          css={{ cursor: "pointer", ...this.props.css }}>
          {this.props.labelComponent}
          {show ? <DownArrowDropDownIcon /> : <UpArrowDropDownIcon />}
        </Flex>
        <Collapse in={show}>
          <Box>{this.props.children}</Box>
        </Collapse>
      </Flex>
    )
  }
}

export default Tree
