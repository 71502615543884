import React from "react";
import _ from "lodash";
import { Flex, Text, Box, Image } from "rebass";

export default class Plus extends React.Component {
  static defaultProps = {
    onAdd: () => {},
    onRemove: () => () => {},
    showMinus: true
  };

  render() {
    const {
      children,
      onAdd,
      onRemove,
      imgStyle,
      flexStyle,
      showMinus
    } = this.props;
    const count = React.Children.count(children);

    return (
      <Flex flexDirection="column" width="100%">
        {React.Children.map(children, (child, index) => {
          const isLast = index === React.Children.count(children) - 1;
          return (
            <Flex
              align="flex-end"
              width="100%"
              css={{ position: "relative", ...flexStyle }}
            >
              {child}
              <Image
                width={16}
                m={"0 0 5px 20px"}
                css={{
                  ...{
                    position: "absolute",
                    right: "-30px",
                    cursor: "pointer"
                  },
                  ...imgStyle
                }}
                src={`${
                  isLast || !showMinus
                    ? "https://icongr.am/octicons/plus.svg?color=DBDBDB"
                    : "https://icongr.am/octicons/dash.svg?color=DBDBDB"
                }`}
                onClick={isLast ? onAdd : onRemove(index)}
              />
            </Flex>
          );
        })}
      </Flex>
    );
  }
}
