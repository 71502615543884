import React from "react"
import { Heading, Box, Flex } from "rebass"
import { observer, inject } from "mobx-react"
import { Formik } from "formik"
import { withStyles } from "@material-ui/core"
import {
  Title,
  SubTitle,
  FormControlLabel,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  Tree,
  PrimaryButton,
  Button,
  Link,
  GradientFlex,
  PrimarySwitch,
  IOSSwitch,
  Input,
  RadioGroup,
  Radio,
  Headline
} from "../ui"
import WANConfigForm from "./WANConfigForm"
import WANSiteConfig from "./WANSiteConfig"
import TrafficMgmtDialog from "./dialogs/TrafficMgmtDialog"
import { track } from "../../analytics"

@inject("userStore", "wanStore", "uxStore")
export default class WANConfig extends React.Component {
  static defaultProps = {
    classATrafficPriorities: [{ desc: "", type: "" }],
    classBTrafficPriorities: [{ desc: "", type: "" }]
  }

  state = {
    wanType: _.get(
      _.find(_.get(this.props, "wanStore.wans"), {
        id: this.getWANId(this.props)
      }),
      "type",
      "MESH"
    ),
    wanId: this.getWANId(this.props)
  }

  componentDidMount() {
    if (this.getWANId(this.props)) {
      track("WAN Edit")
    } else {
      track("WAN Create")
    }
  }

  handleWANChange = wanType => {
    this.setState({ wanType })
  }

  handleSave = status => async () => {
    const rootFormik = this.rootFormik

    rootFormik.submitForm()

    if (this.state.wanId) {
      await this.props.wanStore.updateWan({
        id: this.state.wanId,
        ownerId: _.get(this.props, "userStore.currentUser.userId"),
        status,
        ...this.wanConfigForm.wrappedInstance.getValues(),
        ...this.wanSiteConfig.getValues()
      })

      rootFormik.setSubmitting(false)

      this.props.uxStore.openToast({ message: "Updated WAN !" })
    } else {
      await this.props.wanStore
        .createWan({
          ownerId: _.get(this.props, "userStore.currentUser.userId"),
          status,
          ...this.wanConfigForm.wrappedInstance.getValues(),
          ...this.wanSiteConfig.getValues()
        })
        .then(wanId => {
          this.setState({ wanId })
          if (status === "DRAFT") {
            this.props.uxStore.openToast({ message: "Saved Draft" })
          } else {
            this.props.uxStore.openToast({ message: "Deployed" })
            setTimeout(() => {
              this.props.history.push("/console/wans")
            }, 100)
          }
        })
        .catch(() => {
          this.props.uxStore.openToast({
            status: "error",
            message: "Error occurred !"
          })
        })

      rootFormik.setSubmitting(false)
    }
  }

  getWANId(props) {
    return _.get(props, "match.params.id")
  }

  componentWillReceiveProps(nextProps) {
    if (this.getWANId(nextProps) !== this.getWANId(this.props)) {
      this.setState({ wanId: this.getWANId(nextProps) })
    }
  }

  cancelSetup = () => {
    this.props.history.push("/console/wans")
  }

  handleDeleteWAN = async () => {
    const { userId } = this.props.userStore.currentUser
    await this.props.wanStore.deleteWan(this.getWANId(this.props), userId)
    this.props.uxStore.openToast({ message: "Deleted !" })
    setTimeout(() => {
      this.props.history.push("/console/wans")
    }, 100)
  }

  render() {
    const { wanType } = this.state
    const wanId = this.getWANId(this.props)
    const wan = _.find(_.get(this.props, "wanStore.wans"), {
      id: this.getWANId(this.props)
    })
    const wanSites = _.flatMap(_.get(this.props, "wanStore.wans"), "sites")

    return (
      <Formik
        onSubmit={() => {}}
        ref={_ref => (this.rootFormik = _ref)}
        render={({ isSubmitting }) => (
          <Flex m="0 auto" width="100%" flexDirection="column">
            <Flex flex={"0 0 90px"} align="center" pl={45}>
              <Headline title="WAN Setup [ Work In Progress ]" />
            </Flex>
            <Flex flexDirection="row" p={30} bg="greys.3" m={"0 3%"}>
              <Tree
                labelComponent={<SubTitle children={"WAN Preferences"} />}
                show={true}
                width="100%">
                <Flex flex="0 0 470px" flexDirection="column" flex={1}>
                  <WANConfigForm
                    {...wan}
                    onWANChange={this.handleWANChange}
                    ref={_ref => (this.wanConfigForm = _ref)}
                  />
                </Flex>
              </Tree>
            </Flex>
            {this.state.wanType !== "select" && (
              <Flex flexDirection="row" p={30} bg="greys.3" mt={30} m={"0 3%"}>
                <Tree
                  labelComponent={<SubTitle children={"WAN Sites Settings"} />}
                  show={true}
                  width="100%">
                  <Flex flexDirection="column" flex={1} mt={20}>
                    <WANSiteConfig
                      {...wan}
                      wanSites={wanSites}
                      wanType={wanType}
                      {...this.props}
                      ref={_ref => (this.wanSiteConfig = _ref)}
                    />
                  </Flex>
                </Tree>
              </Flex>
            )}
            {!wanId && (
              <GradientFlex
                flex={"0 0 100px"}
                align="center"
                mt={30}
                mb={30}
                css={{
                  borderRadius: "15px",
                  boxShadow: "0px 4px 10px -3px rgba(0, 0, 0, 0.34)"
                }}>
                <Box flex={1} pl={50}>
                  <Title color="greys.6" fontSize={32}>
                    All done? Let's add your site !
                  </Title>
                </Box>
                <Flex
                  flex={"0 0 500px"}
                  justify="space-between"
                  align="center"
                  pr={30}>
                  <Link
                    children="Cancel Setup"
                    underline="true"
                    fontSize={20}
                    onClick={this.cancelSetup}
                  />
                  <Button
                    fontSize={13}
                    bg="skyBlue"
                    fontSize={20}
                    fontWeight={300}
                    disabled={isSubmitting}
                    p={"15px 30px"}
                    onClick={this.handleSave("DRAFT")}>
                    Save Draft
                  </Button>
                  <PrimaryButton
                    fontSize={20}
                    p={"15px 25px"}
                    disabled={isSubmitting}
                    onClick={this.handleSave("ACTIVE")}>
                    Deploy
                  </PrimaryButton>
                </Flex>
              </GradientFlex>
            )}
            {wanId && (
              <GradientFlex
                flex={"0 0 100px"}
                align="center"
                justify="flex-end"
                mt={30}
                mb={30}
                css={{
                  borderRadius: "15px",
                  boxShadow: "0px 4px 10px -3px rgba(0, 0, 0, 0.34)"
                }}>
                <Link
                  children="Delete WAN"
                  underline
                  fontSize={20}
                  mr={30}
                  onClick={this.handleDeleteWAN}
                />
                <PrimaryButton
                  fontSize={20}
                  p={"15px 55px"}
                  mr={70}
                  disabled={isSubmitting}
                  onClick={this.handleSave("ACTIVE")}>
                  Save
                </PrimaryButton>
              </GradientFlex>
            )}
            <TrafficMgmtDialog
              classATrafficPriorities={this.props.classATrafficPriorities}
              classBTrafficPriorities={this.props.classBTrafficPriorities}
            />
          </Flex>
        )}
      />
    )
  }
}
