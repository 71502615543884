import React from "react"
import ReactDOM from "react-dom"
import { Heading, Box, Flex } from "rebass"
import { observer, inject } from "mobx-react"
import { Formik, Form, FastField } from "formik"
import { withStyles } from "@material-ui/core"
import {
  Title,
  SubTitle,
  FormControlLabel,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  Tree,
  PrimaryButton,
  Button,
  Link,
  GradientFlex,
  PrimarySwitch,
  IOSSwitch,
  Input,
  RadioGroup,
  Radio,
  Headline
} from "../ui"
import SiteConfigForm from "./SiteConfigForm"
import SiteContactForm from "./SiteContactForm"
import SiteConfigTunnel from "./SiteConfigTunnel"
import SiteConfigTest from "./SiteConfigTest"
import CompanyForm from "./CompanyForm"
import TrafficMgmtDialog from "./dialogs/TrafficMgmtDialog"
import CreateWANDialog from "./dialogs/CreateWANDialog"
import LogViewerDialog from "./dialogs/LogViewerDialog"
import { LOG_VIEWER } from "./dialogs/constants"
import { track } from "../../analytics"

const StatusFormControlLabel = withStyles({
  label: {
    fontSize: 20,
    marginRight: 20
  },
  root: {
    flexDirection: "row-reverse"
  }
})(FormControlLabel)

@inject("siteStore", "userStore", "uxStore", "companyStore")
@observer
export default class Site extends React.Component {
  static defaultProps = {
    classATrafficPriorities: [{ desc: "", type: "" }],
    classBTrafficPriorities: [{ desc: "", type: "" }]
  }

  constructor(props) {
    super(props)
    const site = _.find(_.get(props, "siteStore.sites"), {
      id: this.getSiteId()
    })
    this.state = {
      upload:
        _.map(
          _.get(site, "configuration.links"),
          ({ upload, standbyEnabled }) => ({ value: upload, standbyEnabled })
        ) || [],
      download:
        _.map(
          _.get(site, "configuration.links"),
          ({ download, standbyEnabled }) => ({
            value: download,
            standbyEnabled
          })
        ) || [],
      disableSubmit: false,
      disableSubmitST: false,
      disableSubmitSCT: false,
      disableSubmitSC: false,
      disableSubmitCF: false,
      isDeploying: false,
      wanSiteEnabled: _.get(site, "configuration.preferences.wanSiteEnabled")
    }
  }

  getSiteId() {
    return _.get(this.props, "match.params.id")
  }

  handleUploadChange = (value, index, standbyEnabled) => {
    const { upload } = this.state
    upload[index] = { value, standbyEnabled }
    this.setState({ upload: [...upload] })
  }

  handleDownloadChange = (value, index, standbyEnabled) => {
    const { download } = this.state
    download[index] = { value, standbyEnabled }
    this.setState({ download: [...download] })
  }

  handleDeleteLink = index => {
    this.setState({
      download: _.filter(this.state.download, (__, i) => i !== index),
      upload: _.filter(this.state.upload, (__, i) => i !== index)
    })
  }

  debouncedHandleUploadChange = _.debounce(this.handleUploadChange, 250)
  debouncedHandleDownloadChange = _.debounce(this.handleDownloadChange, 250)

  resetThroughput = (standbyEnabled, index) => {
    let { upload, download } = this.state
    upload[index] = { ...upload[index], standbyEnabled }
    download[index] = { ...download[index], standbyEnabled }
    this.setState({ download: [...download], upload: [...upload] })
  }

  handleSiteConfigError = hasError => {
    if (this.state.disableSubmit !== hasError) {
      this.setState({ disableSubmit: hasError })
    }
  }

  handleSiteContactError = hasError => {
    if (this.state.disableSubmitSC !== hasError) {
      this.setState({ disableSubmitSC: hasError })
    }
  }

  handleSiteTunnelError = hasError => {
    if (this.state.disableSubmitST !== hasError) {
      this.setState({ disableSubmitST: hasError })
    }
  }

  handleCompanyFormError = hasError => {
    if (this.state.disableSubmitCF !== hasError) {
      this.setState({ disableSubmitCF: hasError })
    }
  }

  handleSiteConfigTestError = hasError => {
    if (this.state.disableSubmitSCT !== hasError) {
      this.setState({ disableSubmitSCT: hasError })
    }
  }

  handleDeploy = async () => {
    this.setState({ isDeploying: true })
    await this.props.siteStore
      .deploySite(
        this.getSiteId(),
        _.get(this.props, "userStore.currentUser.userId")
      )
      .then(() => {
        this.props.uxStore.openToast({ message: "Site Deployed !" })
      })
      .catch(ex => {
        this.props.uxStore.openToast({
          status: "error",
          message: "Error occurred !"
        })
      })
    this.setState({ isDeploying: false })
  }

  handleDeleteSite = async () => {
    const { userId } = this.props.userStore.currentUser
    await this.props.siteStore.deleteSite(this.getSiteId(), userId)
    this.props.uxStore.openToast({ message: "Deleted !" })
    setTimeout(() => {
      this.props.history.push("/console/sites")
    }, 100)
  }

  // handleSitePrefChange = (pref, isEnabled) => {
  //   this.setState({ [pref]: isEnabled })
  // }

  handleDirty = name =>
    _.debounce(isDirty => {
      if (this.state[name] !== isDirty) {
        this.setState({ [name]: isDirty })
      }
    }, 250)

  resetDirty = () => {
    this.setState({
      isCompanyFormDirty: false,
      isSiteContactFormDirty: false,
      isSiteConfigFormDirty: false,
      isSiteConfigTestDirty: false,
      isSiteConfigTunnelDirty: false
    })
  }

  handleViewLog = () => {
    this.props.uxStore.openDialog(LOG_VIEWER)
  }

  render() {
    const site = _.find(_.get(this.props, "siteStore.sites"), {
      id: this.getSiteId()
    })
    const {
      upload,
      download,
      disableSubmit,
      disableSubmitST,
      disableSubmitSCT,
      disableSubmitSC,
      disableSubmitCF,
      isCompanyFormDirty,
      isSiteContactFormDirty,
      isSiteConfigFormDirty,
      isSiteConfigTestDirty,
      isSiteConfigTunnelDirty,
      isDeploying
    } = this.state
    const company = _.find(_.get(this.props, "companyStore.companies"), {
      id: _.get(site, "info.companyId")
    })

    return (
      <Formik
        ref={_ref => (this.rootFormik = _ref)}
        onSubmit={async (values, { setSubmitting }) => {
          if (!this.state.wanSiteEnabled) {
            const hasError = await this.siteConfigTest.wrappedInstance.runValidations()
            if (hasError) {
              setSubmitting(false)
              return
            }
          }

          await this.props.siteStore
            .updateSite({
              id: this.getSiteId(),
              ownerId: _.get(this.props, "userStore.currentUser.userId"),
              status: "DRAFT",
              info: {
                companyId: _.get(site, "info.companyId"),
                ...this.siteContactForm.getValues(),
                ...this.companyForm.wrappedInstance.getValues()
              },
              configuration: {
                preferences: this.siteConfigForm.wrappedInstance.getValues(),
                links: this.siteConfigTunnel.getValues(),
                tunnel: this.siteConfigTest.wrappedInstance.getValues()
                // isEnabled: _.get(values, "isEnabled")
              }
            })
            .then(() => {
              this.props.uxStore.openToast({ message: "Site Updated !" })
              this.siteContactForm.resetForm()
              this.companyForm.wrappedInstance.resetForm()
              this.siteConfigForm.wrappedInstance.resetForm()
              this.siteConfigTunnel.resetForm()
              this.siteConfigTest.wrappedInstance.resetForm()
              this.resetDirty()
            })
            .catch(err => {
              console.log(":::::: Update Site Error ::::::", err)
              this.props.uxStore.openToast({
                status: "error",
                message: "Error occurred !"
              })
            })

          setSubmitting(false)
        }}
        // initialValues={{
        //   isEnabled: _.get(site, "configuration.isEnabled", true)
        // }}
        render={({ handleSubmit, isSubmitting }) => (
          <Form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              minWidth: "830px"
            }}>
            <Flex flexDirection="column" p={"3% 4%"}>
              <Flex flex={"0 0 90px"}>
                <Flex flex={1} pl={20} align="center">
                  {company && (
                    <Headline>
                      {company.name} {site.info.streetAddr} {site.info.state}
                      {", "}
                      {site.info.country}
                    </Headline>
                  )}
                </Flex>
                <Flex align="center" flex={"0 0 240px"} justify="flex-end">
                  {/* <FastField
                    name="isEnabled"
                    render={props => (
                      <StatusFormControlLabel
                        control={<IOSSwitch {...props} />}
                        label={"Site Status"}
                      />
                    )}
                  /> */}
                </Flex>
              </Flex>
              <Flex flexDirection="row" p={40} bg="greys.3">
                <Tree
                  labelComponent={<SubTitle children={"Contact Information"} />}
                  show={true}
                  width="100%">
                  <Flex
                    ref={_ref => {
                      this.contactInfoRef = _ref
                    }}
                    align="baseline"
                    flexWrap="wrap">
                    <Flex flex={1} flexDirection="column" mr="5%">
                      <CompanyForm
                        disabled={true}
                        {..._.get(site, "info")}
                        ref={_ref => (this.companyForm = _ref)}
                        onError={this.handleCompanyFormError}
                        onDirty={this.handleDirty("isCompanyFormDirty")}
                      />
                    </Flex>
                    <Flex flex={1} css={{ minWidth: "650px" }} mt={30}>
                      <SiteContactForm
                        disabled={true}
                        {..._.get(site, "info")}
                        ref={_ref => (this.siteContactForm = _ref)}
                        onError={this.handleSiteContactError}
                        onDirty={this.handleDirty("isSiteContactFormDirty")}
                      />
                    </Flex>
                  </Flex>
                </Tree>
              </Flex>
              <Flex flexDirection="row" p={40} bg="greys.3" mt={25}>
                <Tree
                  labelComponent={<SubTitle children={"Site Preferences"} />}
                  show={true}
                  width="100%">
                  <Box>
                    <SiteConfigForm
                      {..._.get(site, "configuration.preferences")}
                      ref={_ref => (this.siteConfigForm = _ref)}
                      onError={this.handleSiteConfigError}
                      onPrefChange={this.handleSitePrefChange}
                      onDirty={this.handleDirty("isSiteConfigFormDirty")}
                    />
                  </Box>
                </Tree>
              </Flex>
              <Flex flexDirection="row" p={40} bg="greys.3" mt={25}>
                <Tree
                  labelComponent={<SubTitle children={"Site Links"} />}
                  show={true}
                  width="100%">
                  <Box width="100%">
                    <SiteConfigTunnel
                      links={_.get(site, "configuration.links")}
                      ref={_ref => (this.siteConfigTunnel = _ref)}
                      onUploadChange={this.debouncedHandleUploadChange}
                      onDownloadChange={this.debouncedHandleDownloadChange}
                      onStandbyChange={this.resetThroughput}
                      onDeleteLink={this.handleDeleteLink}
                      onError={this.handleSiteTunnelError}
                      onDirty={this.handleDirty("isSiteConfigTunnelDirty")}
                    />
                  </Box>
                </Tree>
              </Flex>
              <Flex flexDirection="column" p={40} bg="greys.3" mt={25}>
                <Tree
                  labelComponent={<SubTitle children={"Tunnel"} />}
                  show={true}
                  width="100%">
                  <Flex
                    flexDirection="column"
                    flex={1}
                    mt={15}
                    css={{ borderTop: "1px solid #ccc" }}>
                    <SiteConfigTest
                      ref={_ref => (this.siteConfigTest = _ref)}
                      {..._.get(site, "configuration.tunnel")}
                      upload={upload}
                      download={download}
                      wanSiteEnabled={this.state.wanSiteEnabled}
                      onError={this.handleSiteConfigTestError}
                      onDirty={this.handleDirty("isSiteConfigTestDirty")}
                      disabled={true}
                    />
                  </Flex>
                </Tree>
              </Flex>
              <GradientFlex
                flex={"0 0 100px"}
                align="center"
                justify="flex-end"
                mt={40}
                mb={30}
                css={{
                  borderRadius: "15px",
                  boxShadow: "0px 4px 10px -3px rgba(0, 0, 0, 0.34)"
                }}>
                <Flex align="center">
                  <PrimaryButton
                    type="button"
                    onClick={this.handleDeploy}
                    mr={30}
                    disabled={
                      disableSubmit ||
                      disableSubmitSC ||
                      disableSubmitST ||
                      disableSubmitSCT ||
                      disableSubmitCF ||
                      isSubmitting ||
                      isCompanyFormDirty ||
                      isSiteContactFormDirty ||
                      isSiteConfigFormDirty ||
                      isSiteConfigTestDirty ||
                      isSiteConfigTunnelDirty ||
                      isDeploying
                    }
                    ml={25}
                    fontSize={20}
                    css={{
                      borderRadius: "20px"
                    }}
                    p={"15px 45px"}>
                    Deploy
                  </PrimaryButton>
                  <Link
                    children="View Logs"
                    underline
                    fontSize={20}
                    onClick={this.handleViewLog}
                  />
                </Flex>
                <Flex flex={1} justify="flex-end" align="center">
                  <Link
                    children="Delete Site"
                    underline
                    fontSize={20}
                    mr={30}
                    disabled={isSubmitting || isDeploying}
                    onClick={this.handleDeleteSite}
                  />
                  <PrimaryButton
                    type="submit"
                    fontSize={20}
                    p={"15px 55px"}
                    mr={30}
                    disabled={
                      disableSubmit ||
                      disableSubmitST ||
                      disableSubmitSCT ||
                      disableSubmitSC ||
                      disableSubmitCF ||
                      isSubmitting ||
                      (!isCompanyFormDirty &&
                        !isSiteContactFormDirty &&
                        !isSiteConfigFormDirty &&
                        !isSiteConfigTestDirty &&
                        !isSiteConfigTunnelDirty) ||
                      isDeploying
                    }
                    bg="skyBlue">
                    Save
                  </PrimaryButton>
                </Flex>
              </GradientFlex>
            </Flex>
            <TrafficMgmtDialog
              classATrafficPriorities={this.props.classATrafficPriorities}
              classBTrafficPriorities={this.props.classBTrafficPriorities}
            />
            <CreateWANDialog />
            <LogViewerDialog />
          </Form>
        )}
      />
    )
  }

  componentDidMount() {
    track("Edit Site")
  }
}
