import React from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  withStyles,
  MenuItem as BaseMenuItem,
  Menu,
  IconButton
} from "@material-ui/core";

export const MenuItem = withStyles({
  root: {
    fontSize: 13
  }
})(BaseMenuItem);

const ITEM_HEIGHT = 48;

class VerticalMenu extends React.Component {
  static defaultProps = {
    options: []
  };

  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { options } = this.props;
    const open = Boolean(anchorEl);

    return (
      <div>
        <IconButton
          aria-label="More"
          aria-owns={open ? "long-menu" : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          disableRipple
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5
            }
          }}
        >
          {_.map(options, (option, index) => (
            <MenuItem
              key={index}
              selected={option === "Pyxis"}
              onClick={() => {
                if (option.onClick) {
                  option.onClick(this.handleClose);
                }
                if (!option.manual) {
                  this.handleClose();
                }
              }}
            >
              {_.get(option, "text")}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

export default VerticalMenu;
