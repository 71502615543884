import React from "react"
import { Flex, Text, Box } from "rebass"
import { withStyles } from "@material-ui/core"
import SvgIcon from '@material-ui/core/SvgIcon';
import styled from "styled-components"

import themes from "../../themes"
import { TextField } from "./index"

const ArrowDropDownIcon = props => (
  <SvgIcon {...props}>
    <path d="M7 10l10 10 10-10z" />
  </SvgIcon>
);

ArrowDropDownIcon.muiName = 'SvgIcon';

const lighterGrey = _.nth(_.get(themes, "rebass.colors.greys"), 5)
const UpArrowDropDownIcon = withStyles({
  root: {
    fill: lighterGrey,
    transform: "rotate(180deg)",
    width: ".5em",
    height: ".5em",
    position: "relative",
    top: "5px"
  }
})(ArrowDropDownIcon)
const DownArrowDropDownIcon = withStyles({
  root: {
    fill: lighterGrey,
    width: ".5em",
    height: ".5em"
  }
})(ArrowDropDownIcon)

const StyledTextField = styled(TextField)`
  input[type=number]
  {
    -moz-appearance: textfield;
  }
`

class NumberTextField extends React.Component {
  state = {
    value: _.get(this.props, "field.value", this.props.value)
  }

  increment = () => {
    this.setState((prevState) => {
      return { value: Math.min(prevState.value + 1, this.props.max) }
    })
  }

  decrement = () => {
    this.setState((prevState) => {
      return { value: Math.max(prevState.value - 1, this.props.min) }
    })
  }

  handleChange = (event) => {
    const value = !event.target.value ? '' : event.target.value;
    this.setState({ value });
    this.props.field && this.props.field.onChange(event);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.value !== this.state.value && this.props.field &&
      this.props.field.value !== this.state.value) {
      this.props.form && this.props.form.setFieldValue(this.props.field.name, this.state.value)
    }
  }

  render() {
    const { value } = this.state;
    const { label, min, max, inputWidth, labelStyle, ...other} = this.props;
    const { form, field } = other;
    return (
      <Flex css={this.props.css}>
        <StyledTextField  type="number" label={label}
          inputProps={{min: min, max: max}}
          value={value}
          style={{width: inputWidth}} 
          labelStyle={labelStyle}
          {...form}
          {...field}
          onChange={this.handleChange}
        />
        <Flex flexDirection="column" justify="flex-end" css={{position: "relative", right: "12px", top: "-3px"}}>
          <UpArrowDropDownIcon viewBox={"5 0 24 24"} onClick={this.increment}/>
          <DownArrowDropDownIcon viewBox={"5 0 24 24"} onClick={this.decrement}/>
        </Flex>
      </Flex>
    )
  }
}

NumberTextField.defaultProps = {
  value: 0,
  min: 0,
  max: Infinity,
  css: {}
}

export default NumberTextField
