import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import { observer, inject } from "mobx-react";
import { Flex, Text, Box, Image } from "rebass";
import { Formik, Field, FastField, Form } from "formik";
import { withStyles } from "@material-ui/core";
import {
  Title,
  SubTitle,
  FormControlLabel,
  Checkbox,
  TextField,
  Headline,
  Select,
  MenuItem,
  Button,
  PrimaryButton,
  Link,
  GradientFlex,
  SecondarySwitch
} from "../ui";
import matchMedia from "../../utils/matchMedia";
import { TRAFFIC_MGMT_PROFILE } from "./dialogs/constants";

const wanTypeData = [
  {
    value: "MESH",
    text: "Mesh"
  },
  {
    value: "HUB_SPOKE",
    text: "Hub and Spoke"
  }
];

const alertProfileData = ["Default"];

const SitePrefCheckbox = withStyles({
  label: {
    fontSize: ".9em"
  },
  labelChecked: {
    color: "orange",
    fontSize: ".9em"
  },
  labelRoot: {
    marginRight: 0
  }
})(Checkbox);

@inject("uxStore")
class WANConfigForm extends React.PureComponent {
  static defaultProps = {
    info: {},
    name: null,
    type: "MESH"
  };

  getValues() {
    const { type, name } = _.get(this.form, "state.values");
    return {
      ..._.pick(_.get(this.form, "initialValues"), ["type", "name"]),
      ...{ type, name }
    };
  }

  handleCreateTrafficProfile = () => {
    this.props.uxStore.openDialog(TRAFFIC_MGMT_PROFILE);
  };

  componentWillReceiveProps(nextProps) {
    if (_.get(nextProps, "id") !== _.get(this.props, "id")) {
      this.form.resetForm(nextProps);
    }
  }

  render() {
    const {
      type,
      name,
      id,
      info: { alertsEnabled, alertProfile, trafficMgmtEnabled, trafficProfile }
    } = this.props;

    console.log("WAN name: ", name, id, type);

    return (
      <Formik
        ref={_ref => (this.form = _ref)}
        initialValues={{
          type,
          name,
          alertsEnabled,
          alertProfile,
          trafficMgmtEnabled,
          trafficProfile
        }}
        render={({
          values,
          isSubmitting,
          dirty,
          touched,
          errors,
          handleSubmit,
          handleChange,
          setFieldTouched
        }) => (
          <Form
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between"
            }}
            ref={_ref => (this.formRef = _ref)}
          >
            <Flex flexDirection="column" p={10} flex={"0 0 260px"} mt={"30px"}>
              <SubTitle children={"WAN"} />
              <Box pt={15}>
                <FastField
                  name="name"
                  label="WAN Name"
                  component={TextField}
                  css={{ maxWidth: "220px" }}
                />
              </Box>
              <Box pt={40}>
                <FastField
                  name="type"
                  render={props => (
                    <Select
                      {...props}
                      error={errors.type}
                      touched={touched.type}
                      setFieldTouched={setFieldTouched}
                      labelText="WAN Type"
                      htmlFor="type"
                      value={values.type}
                      frmControlStyle={{ flex: "0 0 50px", maxWidth: 100 }}
                      onChange={this.props.onWANChange}
                    >
                      {_.map(wanTypeData, (data, i) => (
                        <MenuItem
                          key={i}
                          children={data.text}
                          value={data.value}
                        />
                      ))}
                    </Select>
                  )}
                />
              </Box>
            </Flex>
            <GradientFlex
              flexDirection="column"
              p={"15px 35px 40px 35px"}
              css={{
                boxShadow: "0px 4px 10px -3px rgba(0, 0, 0, 0.34)",
                maxWidth: "300px",
                height: "300px"
              }}
              flex={"0 0 300px"}
              mt={"30px"}
              mr={"5px"}
            >
              <SubTitle
                ref={_ref => (this.alertProfileTitleRef = _ref)}
                children={"Alerts Profile"}
              />
              <Box p={"10px 0"}>
                <FastField
                  name="alertsEnabled"
                  component={SitePrefCheckbox}
                  label="Enable Alerts and Dashboard"
                />
              </Box>
              <Flex flexDirection="column" p={"10px 0"}>
                <FastField
                  name="alertProfile"
                  render={props => (
                    <Select
                      {...props}
                      error={errors.alertProfile}
                      touched={touched.alertProfile}
                      setFieldTouched={setFieldTouched}
                      labelText="Alert Profile"
                      htmlFor="alertProfile"
                      value={values.alertProfile}
                      frmControlStyle={{ flex: "0 0 50px", minWidth: 135 }}
                    >
                      {_.map(alertProfileData, (data, i) => (
                        <MenuItem key={i} children={data} value={data} />
                      ))}
                    </Select>
                  )}
                />
                <Link
                  children="Create New"
                  underline
                  fontSize={11}
                  css={{ textAlign: "right" }}
                />
              </Flex>
              <FastField
                name="clientEmail"
                label="Client Email"
                component={TextField}
              />
            </GradientFlex>

            <GradientFlex
              flexDirection="column"
              p={"15px 35px 40px 35px"}
              css={{
                boxShadow: "0px 4px 10px -3px rgba(0, 0, 0, 0.34)",
                maxWidth: "300px",
                height: "300px"
              }}
              flex={"0 0 300px"}
              mt={"30px"}
              mr={"5px"}
            >
              <SubTitle
                ref={_ref => (this.trafficMgmtTitleRef = _ref)}
                children={"Traffic Management"}
              />
              <Box p={"10px 0"}>
                <FastField
                  name="trafficMgmtEnabled"
                  component={SitePrefCheckbox}
                  label="Enable Traffic Management"
                />
              </Box>
              <Flex flexDirection="column" p={"10px 0"}>
                <FastField
                  name="trafficProfile"
                  render={props => (
                    <Select
                      {...props}
                      displayEmpty
                      error={errors.trafficProfile}
                      touched={touched.trafficProfile}
                      frmControlStyle={{ flex: "0 0 50px", minWidth: 195 }}
                      htmlFor="trafficProfile"
                      labelText="Select Traffic Profile"
                      renderValue={value =>
                        value === "None" ? "Recommended Settings" : value
                      }
                      setFieldTouched={setFieldTouched}
                      value={values.trafficProfile}
                    />
                  )}
                />
                <Link
                  children="Create New"
                  underline
                  fontSize={11}
                  css={{ textAlign: "right" }}
                  onClick={this.handleCreateTrafficProfile}
                />
              </Flex>
            </GradientFlex>

            <GradientFlex
              flexDirection="column"
              p={"15px 35px 40px 35px"}
              css={{
                boxShadow: "0px 4px 10px -3px rgba(0, 0, 0, 0.34)",
                maxWidth: "330px",
                height: "300px"
              }}
              flex={"0 0 300px"}
              mt={"30px"}
              mr={"5px"}
            >
              <SubTitle
                ref={_ref => (this.advRoutingTitleRef = _ref)}
                children={"Advanced Routing"}
              />
              <Box p={"10px 0"}>
                <FastField
                  name="advRoutingEnabled"
                  label="Enabled Advanced Routing"
                  component={SitePrefCheckbox}
                />
              </Box>
              <Flex p={"10px 0"} width={195} align="flex-end">
                <Image
                  width={18}
                  src="https://icongr.am/fontawesome/cog.svg?size=18&color=FFFFFF"
                />
                <Link
                  children="Settings"
                  underline
                  fontSize={12}
                  css={{ textAlign: "left" }}
                  pl={8}
                />
              </Flex>
            </GradientFlex>
          </Form>
        )}
      />
    );
  }

  componentDidMount() {
    // const advRoutingTitleNode =
    //   this.advRoutingTitleRef && ReactDOM.findDOMNode(this.advRoutingTitleRef);
    // const alertProfileTitleNode =
    //   this.alertProfileTitleRef &&
    //   ReactDOM.findDOMNode(this.alertProfileTitleRef);
    // const trafficMgmtTitleNode =
    //   this.trafficMgmtTitleRef &&
    //   ReactDOM.findDOMNode(this.trafficMgmtTitleRef);
    // matchMedia("(max-width: 1500px)", () => {
    //   if (
    //     advRoutingTitleNode &&
    //     alertProfileTitleNode &&
    //     trafficMgmtTitleNode
    //   ) {
    //     advRoutingTitleNode.style.fontSize = "20px";
    //     alertProfileTitleNode.style.fontSize = "20px";
    //     trafficMgmtTitleNode.style.fontSize = "20px";
    //   }
    // });
    // const formNode = this.formRef && ReactDOM.findDOMNode(this.formRef);
    // matchMedia("(max-width: 1360px)", () => {
    //   if (formNode) {
    //     formNode.style.flexDirection = "column";
    //     formNode.firstElementChild.style.flex = "0 0 220px";
    //     formNode.lastElementChild.style.justifyContent = "space-between";
    //   }
    // });
  }
}

export default WANConfigForm;
