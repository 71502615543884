export const validateIPAddress = ipaddress => {
  if (
    !/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ipaddress
    )
  ) {
    return "IP Address Is Invalid"
  }
}

export const validateMinMax = ({ min, max, name, field }) => value => {
  if (value < min) {
    return `${name} must be greater than or equal to ${field ? field : min}`
  } else if (value > max) {
    return `${name} must be less than or equal to ${field ? field : max}`
  }
}

export const validateCIDR = cidr => {
  if (!/^(\/([0-9]|[1-2][0-9]|3[0-2]))$/.test(cidr)) {
    return "CIDR Is Invalid"
  }
}
