import React from "react";
import { findDOMNode } from "react-dom";
import { Heading, Box, Flex, Text, Image } from "rebass";
import { observer, inject } from "mobx-react";
import _ from "lodash";
import update from "immutability-helper";
import { withStyles } from "@material-ui/core/styles";
import { List } from "react-virtualized";
import {
  DragSource,
  DropTarget,
  ConnectDropTarget,
  ConnectDragSource,
  DropTargetMonitor,
  DropTargetConnector,
  DragSourceConnector,
  DragSourceMonitor
} from "react-dnd";

import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import {
  Title,
  SubTitle,
  FormControlLabel,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  Tree,
  PrimaryButton,
  Button,
  Link,
  GradientFlex,
  PrimarySwitch,
  IOSSwitch,
  Input,
  RadioGroup,
  Radio,
  Headline,
  SearchInput,
  SearchInputShim
} from "../ui";
import matchMedia from "../../utils/matchMedia";

const SiteItemCheckBox = withStyles({
  root: {
    alignItems: "flex-start",
    padding: "5px 0"
  },
  colorPrimary: {
    color: "#CCCCCC"
  },
  checked: {
    color: "#373737 !important"
  }
})(Checkbox);

const WANGatewayCheckbox = withStyles({
  root: {
    height: "30px",
    marginRight: "5px",
    marginLeft: "10px",
    width: "30px"
  },
  label: {
    fontSize: ".8em"
  },
  labelChecked: {
    color: "orange",
    fontSize: ".8em"
  }
})(Checkbox);

const SITE = "site";

const siteSource = {
  beginDrag(props) {
    return {
      index: props.index,
      type: props.type,
      site: props.site
    };
  }
};

const siteTarget = {
  drop(props, monitor, component) {
    if (!component) {
      return null;
    }

    const dragIndex = monitor.getItem().index;
    const dragType = monitor.getItem().type;
    const hoverIndex = props.index;

    if (props.type === dragType && dragIndex === hoverIndex) {
      return;
    }

    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    if (
      dragType === props.type &&
      dragIndex < hoverIndex &&
      hoverClientY < hoverMiddleY
    ) {
      return;
    }
    if (
      dragType === props.type &&
      dragIndex > hoverIndex &&
      hoverClientY > hoverMiddleY
    ) {
      return;
    }

    props.moveSite(
      dragIndex,
      hoverIndex,
      monitor.getItem(),
      props.type,
      dragType,
      hoverClientY < hoverMiddleY ? "up" : "down"
    );
    monitor.getItem().index = hoverIndex;
  }
};

const siteItemStyle = {
  alignItems: "center",
  borderBottom: "1px solid #333",
  color: "#333",
  display: "flex",
  background: "#EFEFEF",
  justifyContent: "space-between",
  flex: "0 0 50px"
};

@DropTarget(SITE, siteTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOverCurrent: monitor.isOver({ shallow: true })
}))
@DragSource(SITE, siteSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
}))
@inject("companyStore")
class SiteItem extends React.Component {
  handleCheck = (name, checked) => {
    this.props.onSiteCheck(this.props.site, checked);
  };

  render() {
    const {
      isDragging,
      connectDragSource,
      connectDropTarget,
      site,
      disableItemDrop,
      isOverCurrent,
      companyStore,
      style,
      selected
    } = this.props;
    const companyName = _.get(
      _.find(companyStore.companies, { id: _.get(site, "info.companyId") }),
      "name"
    );

    return connectDragSource && disableItemDrop
      ? connectDragSource(
          <div
            style={{
              ...siteItemStyle,
              ...{ opacity: isDragging ? 0.5 : 1 },
              background: "#00DD9B",
              ...style
            }}
          >
            <SiteItemCheckBox
              name="site"
              checked={selected}
              onChange={this.handleCheck}
              reset
            />
            <div style={{ flexDirection: "column", display: "flex", flex: 1 }}>
              <div style={{ fontSize: 14 }}>
                {_.get(site, "info.streetAddr")}
              </div>
              <div style={{ fontSize: 11 }}>{companyName}</div>
            </div>
            <div
              style={{
                position: "relative",
                flex: "0 0 50px",
                alignItems: "center",
                flexDirection: "column"
              }}
            >
              <img
                width={20}
                src="https://icongr.am/feather/move.svg?color=373737"
              />
            </div>
          </div>
        )
      : connectDragSource &&
          connectDropTarget &&
          connectDragSource(
            connectDropTarget(
              <div
                style={{
                  ...siteItemStyle,
                  ...{ opacity: isDragging ? 0.5 : 1 },
                  ...{ background: isOverCurrent ? "#d4d4d4" : "#EFEFEF" },
                  ...style
                }}
              >
                <SiteItemCheckBox
                  name="site"
                  checked={selected}
                  onChange={this.handleCheck}
                  reset
                />
                <div
                  style={{ flexDirection: "column", display: "flex", flex: 1 }}
                >
                  <div style={{ fontSize: 14 }}>
                    {_.get(site, "info.streetAddr")}
                  </div>
                  <div style={{ fontSize: 11 }}>{companyName}</div>
                </div>
                <div
                  style={{
                    position: "relative",
                    flex: "0 0 50px",
                    alignItems: "center",
                    flexDirection: "column"
                  }}
                >
                  <img
                    width={20}
                    src="https://icongr.am/feather/move.svg?color=373737"
                  />
                </div>
              </div>
            )
          );
  }
}

const siteListStyle = {
  display: "flex",
  flexDirection: "column",
  height: "100%",
  minHeight: "240px",
  maxHeight: "400px",
  overflow: "auto"
};

const siteListTarget = {
  drop(props, monitor, component) {
    if (monitor.didDrop()) {
      return;
    }
    props.onListDrop(
      monitor.getItem().index,
      monitor.getItem().site,
      props.type,
      monitor.getItem().type
    );
  }
};

@DropTarget(SITE, siteListTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget()
}))
class SiteList extends React.Component {
  noRowsRenderer = () => {
    return (
      this.props.showEmpty && (
        <Flex
          p={10}
          css={{ border: "2px dashed #CCCCCC", borderRadius: "5px" }}
          justify="center"
          align="center"
        >
          <Image
            width={11}
            src="https://icongr.am/fontawesome/plus.svg?color=FFFFFF"
          />
          <Text fontSize={13} p={"0 5px"}>
            Add Site
          </Text>
        </Flex>
      )
    );
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectAll !== this.props.selectAll) {
      this.update();
    }
  }

  update() {
    this.List.forceUpdateGrid();
  }

  rowRenderer = ({ index, key, style }) => {
    const {
      type,
      moveSite,
      sites,
      selectedSites,
      disableItemDrop,
      onSiteCheck
    } = this.props;
    const site = sites[index];
    const selected = _.some(selectedSites, { id: site.id });
    return (
      <SiteItem
        key={key}
        index={index}
        type={type}
        site={site}
        style={style}
        selected={selected}
        moveSite={moveSite(type)}
        onSiteCheck={onSiteCheck}
        disableItemDrop={disableItemDrop}
      />
    );
  };

  render() {
    const {
      connectDropTarget,
      type,
      moveSite,
      sites,
      disableItemDrop,
      onSiteCheck,
      showEmpty
    } = this.props;
    return connectDropTarget(
      <div style={siteListStyle}>
        <List
          ref={_ref => (this.List = _ref)}
          height={400}
          overscanRowCount={8}
          noRowsRenderer={this.noRowsRenderer}
          rowCount={_.size(sites)}
          rowHeight={50}
          rowRenderer={this.rowRenderer}
          width={290}
        />
      </div>
    );
  }
}

SiteList.defaultProps = {
  showEmpty: true
};

@inject("siteStore", "companyStore", "internetGatewayStore")
export default class WANMeshConfig extends React.PureComponent {
  static defaultProps = {
    sites: [],
    wanSites: []
  };

  constructor(props) {
    super(props);
    const sites = [...props.siteStore.sites];
    this.state = {
      sites: _.filter(_.slice(sites), s => {
        return (
          _.indexOf(props.wanSites, s.id) < 0 &&
          _.get(s, "configuration.preferences.wanSiteEnabled")
        );
      }),
      wanSites: _.filter(_.slice(sites), s => {
        return _.indexOf(props.sites, s.id) > -1;
      }),
      wanGateway: _.filter(_.slice(sites), s => {
        return s.id === _.get(props, "wanGateway.id");
      }),
      selectAll: false,
      selectedSites: [],
      selectedWANSites: [],
      selectedWanGateway: [],
      siteSearch: null,
      wanSiteSearch: null,
      primaryGateway: _.get(props, "wanGateway.primaryGateway"),
      secondaryGateway: _.get(props, "wanGateway.secondaryGateway"),
      internetGatewayEnabled: _.get(props, "wanGateway.internetGatewayEnabled")
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.id !== this.props.id) {
      const sites = [...nextProps.siteStore.sites];
      this.setState({
        sites: _.filter(_.slice(sites), s => {
          return (
            _.indexOf(nextProps.wanSites, s.id) < 0 &&
            _.get(s, "configuration.preferences.wanSiteEnabled")
          );
        }),
        wanSites: _.filter(_.slice(sites), s => {
          return _.indexOf(nextProps.sites, s.id) > -1;
        }),
        wanGateway: _.filter(_.slice(sites), s => {
          return s.id === _.get(nextProps, "wanGateway.id");
        }),
        selectAll: false,
        selectedSites: [],
        selectedWANSites: [],
        selectedWanGateway: [],
        siteSearch: null,
        wanSiteSearch: null,
        primaryGateway: _.get(nextProps, "wanGateway.primaryGateway"),
        secondaryGateway: _.get(nextProps, "wanGateway.secondaryGateway"),
        internetGatewayEnabled: _.get(
          nextProps,
          "wanGateway.internetGatewayEnabled"
        )
      });
    }
  }

  moveSite = type => {
    return (dragIndex, hoverIndex, dragItem, newType, oldType, dir) => {
      const sites = this.state[type];

      if (oldType === newType) {
        this.setState(
          update(this.state, {
            [type]: {
              $splice: [[dragIndex, 1], [hoverIndex, 0, dragItem.site]]
            }
          }),
          () => {
            this[type].getDecoratedComponentInstance().update();
          }
        );
      } else {
        this.setState(
          update(this.state, {
            [type]: {
              $splice: [
                [
                  dir === "up" ? hoverIndex : hoverIndex + 1,
                  0,
                  { ...dragItem.site, ...{ selected: false } }
                ]
              ]
            },
            [oldType]: {
              $splice: [[dragIndex, 1]]
            }
          }),
          () => {
            this[type].getDecoratedComponentInstance().update();
            this[oldType].getDecoratedComponentInstance().update();
          }
        );
        this.setState({
          selectedWANSites: _.filter(
            this.state.selectedWANSites,
            site => site.id !== dragItem.site.id
          ),
          selectedSites: _.filter(
            this.state.selectedSites,
            site => site.id !== dragItem.site.id
          )
        });
      }
    };
  };

  handleListDrop = (dragIndex, item, type, oldType) => {
    if (type !== oldType) {
      this.setState(
        update(this.state, {
          [type]: {
            $push: [{ ...item, ...{ selected: false } }]
          },
          [oldType]: {
            $splice: [[dragIndex, 1]]
          }
        })
      );
      this.setState({
        selectedWANSites: _.filter(
          this.state.selectedWANSites,
          site => site.id !== item.id
        ),
        selectedSites: _.filter(
          this.state.selectedSites,
          site => site.id !== item.id
        )
      });
    }
  };

  handleGatewayDrop = (dragIndex, site, type, oldType) => {
    if (!_.isEmpty(this.state.wanGateway) || oldType !== "wanSites") {
      return;
    }

    this.setState(
      update(this.state, {
        wanGateway: {
          $set: [site]
        },
        [oldType]: {
          $splice: [[dragIndex, 1]]
        }
      })
    );
  };

  selectAllCheck = () => {
    const { selectAll, sites } = this.state;
    let nSelectAll = !selectAll;
    this.setState({
      selectAll: nSelectAll,
      selectedSites: nSelectAll ? [...sites] : []
    });
  };

  deSelectAll = () => {
    this.setState({ selectAll: false });
  };

  handleWANSiteCheck = (item, checked) => {
    const { selectedWANSites } = this.state;
    if (checked) {
      this.setState({
        selectedWANSites: [...selectedWANSites, item]
      });
    } else {
      this.setState({
        selectedWANSites: _.filter(
          selectedWANSites,
          site => site.id !== item.id
        )
      });
    }
  };

  handleSiteCheck = (item, checked) => {
    const { selectedSites } = this.state;
    if (checked) {
      this.setState({
        selectedSites: [...selectedSites, item]
      });
    } else {
      this.setState({
        selectedSites: _.filter(selectedSites, site => site.id !== item.id)
      });
    }
    this.deSelectAll();
  };

  moveSitesToWAN = () => {
    const selectedSites = _.map(this.state.selectedSites, site => {
      return { ...site, ...{ selected: false } };
    });
    this.setState({
      selectedSites: [],
      wanSites: [...this.state.wanSites, ...selectedSites],
      sites: _.differenceBy(this.state.sites, selectedSites, "id")
    });
  };

  moveSitesToAll = () => {
    const selectedWANSites = _.map(this.state.selectedWANSites, site => {
      return { ...site, ...{ selected: false } };
    });
    this.setState({
      selectedWANSites: [],
      sites: [...this.state.sites, ...selectedWANSites],
      wanSites: _.differenceBy(this.state.wanSites, selectedWANSites, "id")
    });
  };

  moveSitesToGateway = () => {
    const selectedWANSites = [...this.state.selectedWANSites];
    if (_.size(selectedWANSites) > 1 || !_.isEmpty(this.state.wanGateway)) {
      return;
    }

    this.setState({
      selectedWANSites: [],
      wanGateway: [...this.state.wanGateway, ...selectedWANSites],
      wanSites: _.differenceBy(
        this.state.wanSites,
        this.state.selectedWANSites,
        "id"
      )
    });
  };

  moveSitesToWANFrmGt = () => {
    const selectedWanGateway = [...this.state.selectedWanGateway];
    this.setState({
      selectedWanGateway: [],
      wanSites: [...this.state.wanSites, ...selectedWanGateway],
      wanGateway: _.differenceBy(
        this.state.wanGateway,
        this.state.selectedWanGateway,
        "id"
      )
    });
  };

  handleWANGatewayCheck = (item, checked) => {
    const { selectedWanGateway } = this.state;
    if (checked) {
      this.setState({
        selectedWanGateway: [...selectedWanGateway, item]
      });
    } else {
      this.setState({
        selectedWanGateway: _.filter(
          selectedWanGateway,
          site => site.id !== item.id
        )
      });
    }
  };

  handleSiteSearch = value => {
    this.setState({
      siteSearch: value
    });
  };

  handleWANSiteSearch = value => {
    this.setState({
      wanSiteSearch: value
    });
  };

  filterSites = (sites, search, companies) => {
    if (search) {
      search = _.toLower(search);
      return _.filter(sites, site => {
        const companyName = _.get(
          _.find(companies, { id: _.get(site, "info.companyId") }),
          "name"
        );
        return (
          _.toLower(_.get(site, "info.streetAddr")).indexOf(search) > -1 ||
          _.toLower(companyName).indexOf(search) > -1
        );
      });
    }
    return sites;
  };

  componentDidUpdate(prevProps, prevState) {
    if (_.isEmpty(prevState.sites) && this.state.selectAll) {
      this.setState({ selectAll: false });
    }
  }

  getValues() {
    const {
      wanGateway,
      secondaryGateway,
      primaryGateway,
      internetGatewayEnabled,
      wanSites
    } = this.state;
    return {
      sites: _.map(wanSites, "id"),
      wanGateway: {
        id: _.last(_.map(wanGateway, "id")),
        internetGatewayEnabled,
        primaryGateway,
        secondaryGateway
      }
    };
  }

  handlePrimaryGatewayChange = primaryGateway => {
    this.setState({ primaryGateway });
  };

  handleSecondaryGatewayChange = secondaryGateway => {
    this.setState({ secondaryGateway });
  };

  handleGatewayChange = (name, internetGatewayEnabled) => {
    this.setState({ internetGatewayEnabled });
  };

  render() {
    const {
      sites,
      siteSearch,
      wanSiteSearch,
      wanSites,
      selectedSites,
      selectedWANSites,
      selectedWanGateway,
      wanGateway,
      selectAll,
      primaryGateway,
      secondaryGateway,
      internetGatewayEnabled
    } = this.state;
    const { companies } = this.props.companyStore;
    const { gateways } = this.props.internetGatewayStore;

    return (
      <Flex flexWrap="wrap">
        <Flex flexDirection="column" flex={"0 0 290px"} pt={45}>
          <Title children="Available Sites" fontSize={20} />
          <Flex
            justify={_.isEmpty(sites) ? "flex-end" : "space-between"}
            css={{
              borderTop: "1px solid #CCCCCC",
              borderBottom: "1px solid #CCCCCC",
              height: "49px"
            }}
            mt="5px"
            mb="7px"
            p={"3px 0"}
          >
            {!_.isEmpty(sites) && (
              <SiteItemCheckBox
                name="all"
                label="All"
                onChange={this.selectAllCheck}
                checked={selectAll && !_.isEmpty(sites)}
              />
            )}
            <Flex ml={-10}>
              <SearchInputShim
                placeholder="Find Sites"
                style={{ marginLeft: "-15px" }}
                onChange={this.handleSiteSearch}
              />
              <Image
                width={18}
                src="https://icongr.am/clarity/search.svg?size=18&color=FFFFFF"
              />
            </Flex>
          </Flex>
          <SiteList
            ref={_ref => (this.sites = _ref)}
            showEmpty={false}
            selectAll={selectAll}
            sites={this.filterSites(sites, siteSearch, companies)}
            selectedSites={selectedSites}
            type="sites"
            moveSite={this.moveSite}
            onListDrop={this.handleListDrop}
            onSiteCheck={this.handleSiteCheck}
          />
        </Flex>

        <Flex
          flexDirection="column"
          justify="center"
          align="center"
          flex={"0 0 55px"}
        >
          <Flex
            mb={10}
            p={"5px 0"}
            css={{
              borderRadius: "5px",
              cursor: "pointer",
              opacity: !_.isEmpty(selectedSites) ? 1 : 0.5
            }}
            bg="greyBg"
            onClick={this.moveSitesToWAN}
          >
            <ChevronRight />
          </Flex>
          <Flex
            p={"5px 0"}
            css={{
              borderRadius: "5px",
              cursor: "pointer",
              opacity: !_.isEmpty(selectedWANSites) ? 1 : 0.5
            }}
            bg={"greyBg"}
            onClick={this.moveSitesToAll}
          >
            <ChevronLeft />
          </Flex>
        </Flex>

        <GradientFlex
          flex={1}
          p={15}
          css={{
            boxShadow: "0px 4px 10px -3px rgba(0, 0, 0, 0.34)",
            minWidth: "700px",
            maxWidth: "800px"
          }}
          mt={"7px"}
        >
          <Flex
            flexDirection="column"
            css={{ maxWidth: "290px" }}
            flex={"0 0 290px"}
          >
            <Title children="WAN Sites" fontSize={20} mt={25} />
            <Flex
              align="center"
              justify="flex-end"
              css={{
                borderTop: "1px solid #CCCCCC",
                borderBottom: "1px solid #CCCCCC",
                width: "100%"
              }}
              mt="5px"
              mb="7px"
              p={"4px 0"}
            >
              <SearchInputShim
                placeholder="Find Sites"
                onChange={this.handleWANSiteSearch}
              />
              <Image
                width={18}
                src="https://icongr.am/clarity/search.svg?size=18&color=FFFFFF"
              />
            </Flex>
            <SiteList
              ref={_ref => (this.wanSites = _ref)}
              sites={this.filterSites(wanSites, wanSiteSearch, companies)}
              type="wanSites"
              selectedSites={selectedWANSites}
              moveSite={this.moveSite}
              onListDrop={this.handleListDrop}
              onSiteCheck={this.handleWANSiteCheck}
            />
          </Flex>

          <Flex
            flexDirection="column"
            justify="center"
            align="center"
            flex={"0 0 55px"}
          >
            <Flex
              mb={10}
              p={"5px 0"}
              css={{
                borderRadius: "5px",
                cursor: "pointer",
                opacity:
                  !_.isEmpty(selectedWANSites) &&
                  _.size(selectedWANSites) < 2 &&
                  _.isEmpty(wanGateway)
                    ? 1
                    : 0.5
              }}
              bg="greyBg"
              onClick={this.moveSitesToGateway}
            >
              <ChevronRight />
            </Flex>
            <Flex
              p={"5px 0"}
              css={{
                borderRadius: "5px",
                cursor: "pointer",
                opacity: !_.isEmpty(selectedWanGateway) ? 1 : 0.5
              }}
              bg="greyBg"
            >
              <ChevronLeft onClick={this.moveSitesToWANFrmGt} />
            </Flex>
          </Flex>

          <Flex
            flex={1}
            flexDirection="column"
            css={{
              border: "1px dashed #00B14C",
              padding: "10px 10px 10px 20px"
            }}
            mt={"15px"}
            justify="space-between"
          >
            <Flex
              flex={"0 0 160px"}
              css={{ maxWidth: "290px" }}
              flexDirection="column"
            >
              <Title
                children="WAN Gateway"
                fontSize={20}
                p={"0 0 35px 0"}
                css={{ borderBottom: "1px solid #CCCCCC" }}
                mb="7px"
              />
              <SiteList
                ref={_ref => (this.wanGateway = _ref)}
                sites={wanGateway}
                type="wanGateway"
                moveSite={this.moveSite}
                onListDrop={this.handleGatewayDrop}
                disableItemDrop
                onSiteCheck={this.handleWANGatewayCheck}
              />
            </Flex>
            <Flex flexDirection="column" flex={"0 0 290px"} align="flex-start">
              <Title children="Internet Gateway" fontSize={20} mr={35} />
              <Box p={"10px 0"}>
                <WANGatewayCheckbox
                  name="internetGatewayEnabled"
                  label="Enable Internet Gateway"
                  checked={internetGatewayEnabled}
                  onChange={this.handleGatewayChange}
                />
              </Box>
              <Select
                labelText="Primary Gateway"
                htmlFor="gateway"
                value={primaryGateway}
                onChange={this.handlePrimaryGatewayChange}
                frmControlStyle={{ flex: "0 0 50px", maxWidth: 100 }}
              >
                {_.map(gateways, (data, i) => (
                  <MenuItem key={i} children={data.name} value={data.id} />
                ))}
              </Select>
              {/* <Flex flexDirection="column" pt={10}>
                <Select
                  labelText="Secondary Gateway"
                  htmlFor="secondaryGateway"
                  value={secondaryGateway}
                  onChange={this.handleSecondaryGatewayChange}
                  frmControlStyle={{ flex: "0 0 50px", maxWidth: 100 }}
                >
                  {_.map(gateways, (data, i) => (
                    <MenuItem key={i} children={data.name} value={data.id} />
                  ))}
                </Select>
                <Text children="Requires advanced BGP setup" fontSize={11} />
              </Flex> */}
            </Flex>
          </Flex>
        </GradientFlex>
      </Flex>
    );
  }
}
