import React from "react";
import styled from "styled-components"
import { Box } from "rebass";

const BaseLoader = ({className}) => (
  <Box className={`${className} lds-spinner`}>
    <div/>
    <div/>
  </Box>
)

export const Loader = styled(BaseLoader)`
  &.lds-spinner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    div {
      position: absolute;
      border: 2px solid #fff;
      opacity: 1;
      border-radius: 50%;
      animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

    div:nth-child(2) {
      animation-delay: -0.5s;
    }
  }

  @keyframes lds-ripple {
    0% {
      top: 38px;
      left: 38px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: -1px;
      left: -1px;
      width: 78px;
      height: 78px;
      opacity: 0;
    }
  }
`

export default Loader;