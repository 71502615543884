import React, { Component } from "react"
import { Text, Box, Message, Heading, Flex, Image } from "rebass"
import { Auth, Logger } from "aws-amplify"
import { PrimaryButton, TextField as BaseTextField, Link } from "../ui"
import { withStyles } from "@material-ui/core"
import { Formik, Field, Form } from "formik"
import { withRouter } from "react-router-dom"
import * as Yup from "yup"
import { identify, track } from "../../analytics"

const schema = Yup.object().shape({
  username: Yup.string()
    .email("Email not valid")
    .required("Email is required"),
  password: Yup.string()
    .min(7, "Password must be 7 characters or longer")
    .required("Password is required")
})

const TextField = withStyles({
  input: {
    color: "black"
  }
})(BaseTextField)

@withRouter
export default class SignIn extends Component {
  state = {
    authState: null,
    authMsg: "Welcome back! Please login to your account",
    cognitoUser: null
  }

  handleSubmit = async (
    values,
    { setValues, resetForm, setSubmitting, setFieldError, setFieldTouched }
  ) => {
    const { authState, cognitoUser } = this.state
    const { history } = this.props
    const { username, password, code } = values

    if (_.includes([null, "NotAuthorizedException"], authState)) {
      try {
        const cognitoUser = await Auth.signIn(username, password)
        if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.setState({
            authState: "NEW_PASSWORD_REQUIRED",
            authMsg: "A password reset is required",
            cognitoUser: cognitoUser
          })
          setValues({ ...values, password: "" })
          setFieldError("password", "")
          setFieldTouched("password", false)
          setSubmitting(false)
          // resetForm() <- this ends up crippling the onSubmit event
        } else {
          identify(username)
          track("Login")
          history.push("/console/dashboard")
        }
      } catch (err) {
        const { code: authCode } = err
        console.log("Auth error: ", authCode)

        if (authCode === "PasswordResetRequiredException") {
          setValues({ ...values, password: "" })
          setFieldError("password", "")
          setFieldTouched("password", false)
          setSubmitting(false)
          // resetForm() <- this ends up crippling the onSubmit event
          this.setState({
            authState: "PasswordResetRequiredException",
            authMsg:
              "A password reset was requested. Please enter the reset code that was just emailed to you and your new password"
          })
        }

        if (authCode === "UserNotFoundException") {
          this.setState({
            authState: null,
            authMsg: "The provided email address does not have an account"
          })
          resetForm()
        }

        if (authCode === "NotAuthorizedException") {
          this.setState({
            authState: "NotAuthorizedException",
            authMsg: "There was an error logging in"
          })
          resetForm()
        }
      }
    } else if (authState === "PasswordResetRequiredException") {
      await Auth.forgotPasswordSubmit(username, code, password)
      console.log("authState", authState)
      // this feels like a terrible hack, but Auth.currentUserInfo(),
      // used in loadUserCredentials, will not seem to function otherwise
      await Auth.signIn(username, password)
      history.push("/console")
    } else if (authState === "NEW_PASSWORD_REQUIRED") {
      console.log("authState", authState)
      const r = await Auth.completeNewPassword(cognitoUser, password, null)
      history.push("/console")
    }
  }

  handleMessageDismiss = () => {
    this.errors = []
  }

  render() {
    const { authState, authMsg } = this.state
    console.log(authState)
    return (
      <Flex flexDirection="column" alignItems="center">
        {/* Temporarily hosted in free cloud server for mvp */}
        <Image mb={2} src="https://i.imgur.com/ZJz1OXv.png" />
        <Box width={320}>
          <Text
            textAlign="center"
            color={authState === null ? "greys.1" : "red"}
            fontSize={1}
            fontWeight="light"
            mt={2}
            children={authMsg}
          />
          <Formik
            initialValues={{
              username: "",
              password: "",
              code: ""
            }}
            validationSchema={schema}
            onSubmit={(values, formikBag) => {
              this.handleSubmit(values, formikBag)
            }}
            render={({ values, isSubmitting, errors }) => (
              <Form>
                {_.includes([null, "NotAuthorizedException"], authState) && (
                  <Flex flexDirection="column">
                    <Box pt={10}>
                      <Field
                        color="black"
                        name="username"
                        label="Email"
                        component={TextField}
                      />
                    </Box>
                    <Box pt={10}>
                      <Field
                        type="password"
                        name="password"
                        label="Password"
                        component={TextField}
                      />
                    </Box>
                  </Flex>
                )}
                {authState === "NEW_PASSWORD_REQUIRED" && (
                  <Field
                    type="password"
                    name="password"
                    label="New Password"
                    component={TextField}
                  />
                )}
                {authState === "PasswordResetRequiredException" && (
                  <Box>
                    <Field
                      type="text"
                      name="code"
                      label="Reset Code"
                      component={TextField}
                    />
                    <Field
                      type="password"
                      name="password"
                      label="New Password"
                      component={TextField}
                    />
                  </Box>
                )}
                <Flex justifyContent="center" mt={20}>
                  <PrimaryButton
                    disabled={isSubmitting}
                    type="submit"
                    width={100}>
                    {authState === null ? "Login" : "Submit"}
                  </PrimaryButton>
                </Flex>
              </Form>
            )}
          />
        </Box>
      </Flex>
    )
  }
}
