import { observable, computed, action, decorate, toJS } from "mobx"
import { API, graphqlOperation } from "aws-amplify"
import userStore from "./UserStore"

import {
  ALL_USER_SITES,
  CREATE_SITE,
  UPDATE_SITE,
  DEPLOY_SITE,
  GET_CORE_INSTALL_URL,
  GET_UNUSED_UBOND_SERVER_PORT,
  DROP_UBOND_SERVER_PORT,
  DELETE_SITE
} from "../queries"

const PORT_RANGE = [6000, 6007]

class SiteStore {
  sites = []
  unsavedPorts = []

  _paginationTokens = []

  @computed
  get usedPorts() {
    return this.sites
      ? _.reduce(
          this.sites,
          (result, { configuration: { links } }, key) => {
            const remotePorts = _.map(links, "remotePort")
            result = [
              ...result,
              ..._.filter(remotePorts, p => p !== null),
              ...this.unsavedPorts
            ]
            return _.uniq(result)
          },
          []
        )
      : []
  }

  /**
   * Retrieves a list of Sites associated with User
   *
   * @param {String!} ownerId - id of the current user
   * @param {Int} count - count of records to retrieve (positive to go forward, negative to go back)
   *
   * @return {Promise.<null>} empty promise
   *
   * Does not return site list but instead updates the local sites array, which is a mobx observable.
   * The sites array contains Site objects with the following shape:
   *
   * @typedef Site
   * @type {Object}
   * @property {String} id - id
   * @property {String} status - ('DRAFT'|'ACTIVE'|'ARCHIVED')
   * @property {Object} info - an arbitrary object that represents all fields in the "New Site Information" dialog (companyId, street, city, country, etc)
   * @property {Object} configuration - an arbitrary object representing the site configuration form
   */
  allUserSites = async (ownerId, count = null) => {
    let startToken = null
    if (count !== null) {
      if (count < 0) {
        this._paginationTokens = _.slice(
          this._paginationTokens,
          0,
          this._paginationTokens.length - 2
        )
      }
      // startToken = _.last(this._paginationTokens) || null
    }

    const {
      data: {
        allUserSites: { sites, nextToken }
      }
    } = await API.graphql(
      graphqlOperation(ALL_USER_SITES, {
        ownerId,
        count: count ? Math.abs(count) : null,
        nextToken: startToken
      })
    )

    if (nextToken) {
      this._paginationTokens.push(nextToken)
    }

    this.sites = _.reverse(
      _.sortBy(
        _.map(sites, s => ({
          ...s,
          configuration: s.configuration ? JSON.parse(s.configuration) : null,
          info: s.info ? JSON.parse(s.info) : null
        })),
        ({ createdAt }) => new Date(createdAt)
      )
    )

    return Promise.resolve()
  }

  /**
   * Create a Site
   *
   * @param {input} - site info
   * @param {String!} input.ownerId - id of the current user
   * @param {String!} input.status - site status: ('DRAFT'|'ACTIVE'|'ARCHIVED')
   * @param {Object} input.info - an arbitrary object that represents all fields in the "New Site Information" dialog (companyId, street, city, country, etc)
   * @param {Object} input.configuration - site configuration
   * @return {Promise} Promise containing the site id
   */
  createSite = async input => {
    try {
      const {
        data: {
          createSite: { id }
        }
      } = await API.graphql(
        graphqlOperation(CREATE_SITE, {
          input: {
            ...input,
            configuration: JSON.stringify(input.configuration),
            info: JSON.stringify(input.info)
          }
        })
      )

      this.sites = [{ ...input, id }, ...this.sites]
      return Promise.resolve(id)
    } catch (ex) {
      return Promise.reject()
    }
  }

  /**
   * Update a Site
   * @param {input} - site info
   * @param {String!} input.id - id of the site to update
   * @param {String!} input.ownerId - id of the current user
   * @param {String!} input.status - site status: ('DRAFT'|'ACTIVE'|'ARCHIVED')
   * @param {Object} input.info - site info (name, address, etc.)
   * @param {Object} input.configuration - site configuration
   * @returns {Promise} Promise containing the site id
   */
  updateSite = async input => {
    try {
      await API.graphql(
        graphqlOperation(UPDATE_SITE, {
          input: {
            ...input,
            configuration: JSON.stringify(input.configuration),
            info: JSON.stringify(input.info)
          }
        })
      )
    } catch (ex) {
      const msg = `:::: Errors :::: ${_.map(ex.errors, "message").join(", ")}`
      console.log(msg)
      return Promise.reject(msg)
    }

    const index = _.findIndex(this.sites, ({ id }) => id === input.id)
    this.sites = Object.assign([...this.sites.slice()], {
      [index]: input
    })

    return Promise.resolve(input.id)
  }

  /**
   * Deploy a Site
   * @param {String!} id - site id
   * @param {String!} ownerId - id of the current user
   * @returns {Promise} Promise containing the site id
   */
  deploySite = async (siteId, ownerId) => {
    try {
      await API.graphql(graphqlOperation(DEPLOY_SITE, { siteId, ownerId }))
    } catch (ex) {
      const msg = `:::: Errors :::: ${_.map(ex.errors, "message").join(", ")}`
      console.error(msg)
      return Promise.reject(msg)
    }
    return Promise.resolve(siteId)
  }

  /**
   * Get install url for CPE core
   * @param {String!} id - site id
   * @returns {Promise} Promise containing the url
   */
  getCoreInstallUrl = async siteId => {
    const {
      data: {
        getCoreInstallUrl: { url }
      }
    } = await API.graphql(graphqlOperation(GET_CORE_INSTALL_URL, { siteId }))
    return Promise.resolve(
      `/usr/bin/curl ${url.replace(/\&/g, "\\&")} | /bin/bash`
    )
  }

  /**
   * Get port for a link
   * @param {String!} ownerId - id of the current user
   * @param {ID!} serverId - server id
   * @returns {Promise} Promise containing the port OR undefined if there are no remaining ports available
   */
  getUnusedUbondServerPort = async () => {
    const [start, end] = PORT_RANGE
    const allowable = _.range(start, end + 1)
    const available = _.without(
      allowable,
      ...[...this.usedPorts, ...this.unsavedPorts]
    )
    const port = available[0]
    this.unsavedPorts.push(port)
    return Promise.resolve(port)
  }

  /**
   * Drop a port for a link
   * @param {String!} ownerId - id of the current user
   * @param {ID!} serverId - server id
   * @param {Int!} port - port
   * @returns {Promise} Promise containing the port
   */
  dropUbondServerPort = async (ownerId, serverId, portToDrop) => {
    this.unsavedPorts = _.without(this.unsavedPorts, portToDrop)
    console.log("this.unsavedPorts", this.unsavedPorts.slice())
    return Promise.resolve(portToDrop)
  }

  clearUnsavedPorts = () => {
    this.unsavedPorts = []
  }

  /**
   * Delete Sites
   * @param {input} - wan info
   * @param {Array|String!} input.siteIds - ids of the sites to delete
   * @param {String!} input.ownerId - id of the current user
   * @return {Promise} Promise containing the wan id
   */
  deleteSite = async (siteIds, ownerId) => {
    if (!_.isArray(siteIds)) siteIds = [siteIds]
    await API.graphql(graphqlOperation(DELETE_SITE, { siteIds, ownerId }))
    this.sites = [
      ..._.filter(this.sites, site => _.indexOf(siteIds, site.id) === -1)
    ]
    return Promise.resolve()
  }
}

decorate(SiteStore, {
  allUserSites: action,
  createSite: action,
  updateSite: action,
  deploySite: action,
  deleteSite: action,
  getCoreInstallUrl: action,
  clearUnsavedPorts: action,
  getUnusedUbondServerPort: action,
  sites: observable,
  unsavedPorts: observable
})

export default new SiteStore()
