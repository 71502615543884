import React from "react"
import _ from "lodash"
import { observer, inject } from "mobx-react"
import { Flex, Text, Box } from "rebass"
import { withStyles } from "@material-ui/core"
import { Formik, Field, Form } from "formik"
import {
  Title,
  SubTitle,
  FormControlLabel,
  Checkbox,
  TextField,
  Headline,
  Select,
  MenuItem,
  Button,
  PrimaryButton,
  Link,
  GradientFlex,
  Tree
} from "../ui"
import SiteConfigForm from "./SiteConfigForm"
import SiteConfigTunnel from "./SiteConfigTunnel"
import SiteConfigTest from "./SiteConfigTest"
import TrafficMgmtDialog from "./dialogs/TrafficMgmtDialog"
import CreateWANDialog from "./dialogs/CreateWANDialog"

@inject("siteStore", "userStore", "uxStore")
export default class SiteConfig extends React.Component {
  state = {
    upload: [],
    download: [],
    disableSubmitSC: false,
    disableSubmitST: false,
    disableSubmitSCT: false,
    wanSiteEnabled: false,
    siteId: null
  }

  handleSave = status => async () => {
    const {
      rootFormik,
      siteConfigForm,
      siteConfigTest,
      siteConfigTunnel
    } = this
    ;``

    await rootFormik.runValidations()
    rootFormik.submitForm()

    if (!this.state.wanSiteEnabled) {
      const hasError = await siteConfigTest.wrappedInstance.runValidations()
      this.setState({ disableSubmitSCT: hasError })
      if (hasError) {
        rootFormik.setSubmitting(false)
        return
      }
    }

    if (this.state.siteId) {
      await this.props.siteStore
        .updateSite({
          id: this.state.siteId,
          ownerId: _.get(this.props, "userStore.currentUser.userId"),
          status: status,
          info: _.get(this.props, "location.state.info"),
          configuration: {
            preferences: siteConfigForm.wrappedInstance.getValues(),
            links: siteConfigTunnel.getValues(),
            tunnel: siteConfigTest.wrappedInstance.getValues(),
            isEnabled: true
          }
        })
        .then(() => {
          if (status === "DRAFT") {
            this.props.uxStore.openToast({ message: "Saved Draft" })
          } else {
            this.props.uxStore.openToast({ message: "Site Deployed !" })
          }
        })
        .catch(() => {
          this.props.uxStore.openToast({
            status: "error",
            message: "Error occurred !"
          })
        })
      rootFormik.setSubmitting(false)
      return
    }

    await this.props.siteStore
      .createSite({
        ownerId: _.get(this.props, "userStore.currentUser.userId"),
        configuration: {
          preferences: siteConfigForm.wrappedInstance.getValues(),
          links: siteConfigTunnel.getValues(),
          tunnel: siteConfigTest.wrappedInstance.getValues(),
          isEnabled: true
        },
        info: _.get(this.props, "location.state.info"),
        status
      })
      .then(siteId => {
        this.setState({ siteId })
        if (status === "DRAFT") {
          this.props.uxStore.openToast({ message: "Saved Draft" })
        } else {
          this.props.siteStore
            .deploySite(
              this.state.siteId,
              _.get(this.props, "userStore.currentUser.userId")
            )
            .then(() => {
              this.props.uxStore.openToast({ message: "Site Deployed !" })
            })
            .catch(ex => {
              this.props.uxStore.openToast({
                status: "error",
                message: "Error occurred !"
              })
            })
        }
      })
      .catch(() => {
        this.props.uxStore.openToast({
          status: "error",
          message: "Error occurred !"
        })
      })

    rootFormik.setSubmitting(false)
  }

  handleUploadChange = (value, index, standbyEnabled) => {
    const { upload } = this.state
    upload[index] = { value, standbyEnabled }
    this.setState({ upload: [...upload] })
  }

  handleDownloadChange = (value, index, standbyEnabled) => {
    const { download } = this.state
    download[index] = { value, standbyEnabled }
    this.setState({ download: [...download] })
  }

  handleDeleteLink = index => {
    this.setState({
      download: _.filter(this.state.download, (__, i) => i !== index),
      upload: _.filter(this.state.upload, (__, i) => i !== index)
    })
  }

  debouncedHandleUploadChange = _.debounce(this.handleUploadChange, 250)
  debouncedHandleDownloadChange = _.debounce(this.handleDownloadChange, 250)

  resetThroughput = (standbyEnabled, index) => {
    let { upload, download } = this.state
    upload[index] = { ...upload[index], standbyEnabled }
    download[index] = { ...download[index], standbyEnabled }
    this.setState({ download: [...download], upload: [...upload] })
  }

  cancelSetup = () => {
    this.props.history.push("/console/sites")
  }

  handleSiteConfigError = hasError => {
    if (this.state.disableSubmitSC !== hasError) {
      this.setState({ disableSubmitSC: hasError })
    }
  }

  handleSiteTunnelError = hasError => {
    if (this.state.disableSubmitST !== hasError) {
      this.setState({ disableSubmitST: hasError })
    }
  }

  handleSiteConfigTestError = hasError => {
    if (this.state.disableSubmitSCT !== hasError) {
      this.setState({ disableSubmitSCT: hasError })
    }
  }

  handleSitePrefChange = (pref, isEnabled) => {
    const hasError = this.siteConfigTest.wrappedInstance.runValidations()
    this.setState({ disableSubmitSCT: hasError })
    this.setState({ [pref]: isEnabled })
  }

  render() {
    const {
      upload,
      download,
      disableSubmitSC,
      disableSubmitST,
      disableSubmitSCT
    } = this.state

    const companyName = _.get(this.props, "location.state.info.companyName")
    const Company = _.get(this.props, "location.state.info")
    return (
      <Formik
        onSubmit={() => {}}
        ref={_ref => (this.rootFormik = _ref)}
        render={({ isSubmitting }) => (
          <Form
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              margin: "0 auto"
            }}>
            <Flex flex={"0 0 90px"} align="center" mt={30} pl={45}>
              <Headline>
                {companyName} {Company.streetAddr} {Company.state}
                {", "}
                {Company.country}
              </Headline>
            </Flex>
            <Flex flex={"0 0 90px"} align="center" pl={45}>
              <Text fontSize={23}>Site Configuration Setup</Text>
            </Flex>
            <Flex flexDirection="row" p={30} bg="greys.3" m={"0 3%"}>
              <Tree
                labelComponent={<SubTitle children={"Site Preferences"} />}
                show={true}
                width="100%">
                <Flex
                  flex="0 0 470px"
                  flexDirection="column"
                  flex={1}
                  mt={15}
                  css={{ borderTop: "1px solid #ccc" }}>
                  <SiteConfigForm
                    ref={_ref => (this.siteConfigForm = _ref)}
                    onError={this.handleSiteConfigError}
                    onPrefChange={this.handleSitePrefChange}
                  />
                </Flex>
              </Tree>
            </Flex>
            <Flex flexDirection="row" p={30} bg="greys.3" mt={30} m={"0 3%"}>
              <Tree
                labelComponent={<SubTitle children={"Site Links"} />}
                show={true}
                width="100%">
                <Flex
                  flexDirection="column"
                  flex={1}
                  mt={15}
                  css={{ borderTop: "1px solid #ccc" }}>
                  <SiteConfigTunnel
                    ref={_ref => (this.siteConfigTunnel = _ref)}
                    onUploadChange={this.debouncedHandleUploadChange}
                    onDownloadChange={this.debouncedHandleDownloadChange}
                    onStandbyChange={this.resetThroughput}
                    onDeleteLink={this.handleDeleteLink}
                    onError={this.handleSiteTunnelError}
                  />
                </Flex>
              </Tree>
            </Flex>
            <Flex flexDirection="column" p={30} bg="greys.3" mt={30} m={"0 3%"}>
              <Tree
                labelComponent={<SubTitle children={"Tunnel"} />}
                show={true}
                width="100%">
                <Flex
                  flexDirection="column"
                  flex={1}
                  mt={15}
                  css={{ borderTop: "1px solid #ccc" }}>
                  <SiteConfigTest
                    ref={_ref => (this.siteConfigTest = _ref)}
                    wanSiteEnabled={this.state.wanSiteEnabled}
                    upload={upload}
                    download={download}
                    onError={this.handleSiteConfigTestError}
                  />
                </Flex>
              </Tree>
            </Flex>
            <GradientFlex
              flex={"0 0 100px"}
              align="center"
              mt={100}
              m={30}
              // mb={30}
              css={{
                borderRadius: "40px",
                boxShadow: "0px 4px 10px -3px rgba(0, 0, 0, 0.34)"
              }}>
              <Box flex={1} pl={30} />
              <Flex
                flex={"0 0 500px"}
                justify="space-between"
                align="center"
                pr={30}>
                <Link
                  children="Cancel Setup"
                  underline={true}
                  fontSize={20}
                  onClick={this.cancelSetup}
                />
                <Button
                  type="button"
                  onClick={this.handleSave("DRAFT")}
                  disabled={
                    disableSubmitSC ||
                    disableSubmitST ||
                    disableSubmitSCT ||
                    isSubmitting
                  }
                  fontSize={13}
                  bg="skyBlue"
                  fontSize={20}
                  fontWeight={300}
                  p={"15px 30px"}>
                  Save Draft
                </Button>
                <PrimaryButton
                  onClick={this.handleSave("ACTIVE")}
                  disabled={
                    disableSubmitSC ||
                    disableSubmitST ||
                    disableSubmitSCT ||
                    isSubmitting
                  }
                  fontSize={20}
                  p={"15px 25px"}>
                  Deploy
                </PrimaryButton>
              </Flex>
            </GradientFlex>
            <TrafficMgmtDialog />
            <CreateWANDialog />
          </Form>
        )}
      />
    )
  }
}
