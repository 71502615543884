import mixpanel from "mixpanel-browser"

export const track = (event, properties = null) => {
  console.log(`track: ${event}`)
  mixpanel.track(event, properties)
}

export const identify = email => {
  console.log(`identify: ${email}`)
  mixpanel.identify(email)
  mixpanel.people.set({ $email: email })
}
