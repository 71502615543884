import React from "react"
import ReactDOM from "react-dom"
import Popper from "@material-ui/core/Popper"
import Paper from "@material-ui/core/Paper"
import Grow from "@material-ui/core/Grow"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import MenuList from "@material-ui/core/MenuList"
import { withStyles } from "@material-ui/core/styles"

const StyledMenuList = withStyles({
  root: {
    background: "#959191",
    color: "#fff",
    marginTop: "10px",
    padding: 0
  }
})(MenuList)

class SimpleMenu extends React.Component {
  state = {
    open: false
  }

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }))
  }

  handleClose = () => {
    if (this.anchorEl && this.anchorEl.contains(event.target)) {
      return
    }

    this.setState({ open: false })
  }

  render() {
    const { open } = this.state

    return (
      <div>
        {React.cloneElement(this.props.title(), {
          onClick: this.handleToggle,
          ref: node => (this.anchorEl = ReactDOM.findDOMNode(node))
        })}
        <Popper
          id="simple-menu"
          style={{ width: "160px" }}
          anchorEl={this.anchorEl}
          open={open}
          onClose={this.handleClose}
          transition
          disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}>
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <StyledMenuList>
                    {React.Children.map(this.props.children, child => {
                      return React.cloneElement(child, {
                        onClick: () => {
                          child.props.onClick()
                          this.handleClose()
                        }
                      })
                    })}
                  </StyledMenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    )
  }
}

export default SimpleMenu
