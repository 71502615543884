import React from "react"
import { findDOMNode } from "react-dom"

class InputShimWrapper extends React.Component {

  // this component supports valueLink or value/onChange.
  // borrowed from LinkedValueMixin.js
  getValue() {
    if (this.props.valueLink) {
        return this.props.valueLink.value;
    }

    return this.props.value;
  }

  getOnChange() {
    if (this.props.valueLink) {
        return this._handleLinkedValueChange;
    }

    return this.props.onChange;
  }

  _handleLinkedValueChange = (e) => {
    this.props.valueLink.requestChange(e.target.value);
  }

  // keep track of focus
  onFocus = (e) => {
    this.hasFocus = true;
    this.setSelectionIfNeeded(e.target);

    if (this.props.onFocus) {
        return this.props.onFocus(e);
    }
  }

  onBlur = (e) => {
    this.hasFocus = false;

    if (this.props.onBlur) {
        return this.props.onBlur(e);
    }
  }

  setSelectionIfNeeded(node) {
    if ('setSelectionRange' in node
      && this.hasFocus
      && this.isPlaceholding
      && (node.selectionStart !== 0 || node.selectionEnd !== 0)) 
    {
      node.setSelectionRange(0, 0);
    }
  }

  onChange = (e) => {
    let onChange = this.getOnChange();
    let value;
    let index;

    if (this.isPlaceholding) {
        // remove placeholder when text is added
        value = e.target.value;
        index = value.indexOf(this.props.placeholder);

        if (index !== -1) {
            e.target.value = value.slice(0, index);
        }
    }

    if (onChange) {
        return onChange(e);
    }
  }

  onSelect = (e) => {
    if (this.isPlaceholding) {
        this.setSelectionIfNeeded(e.target);

        return false;
    } else if (this.props.onSelect) {
        return this.props.onSelect(e);
    }
  }

  componentDidUpdate() {
    this.setSelectionIfNeeded(this.inputNode)
  }

  componentDidMount() {
    this.inputNode = findDOMNode(this.input)
  }

  render() {
    let props = {};
    let value;
    let key;

    for (key in this.props) {
        if (this.props.hasOwnProperty(key)) {
            props[key] = this.props[key];
        }
    }

    // override valueLink and event handlers
    props.onFocus = this.onFocus;
    props.onBlur = this.onBlur;
    props.onChange = this.onChange;
    props.onSelect = this.onSelect;
    props.valueLink = undefined;
    delete props.valueLink;

    value = this.getValue();

    if (!value) {
        this.isPlaceholding = true;
        value = this.props.placeholder;
        props.className += ' placeholder';
    } else {
        this.isPlaceholding = false;
    }

    props.value = value;

    return <input ref={((_ref) => ( this.input = _ref ))} {...props}/>
  }
}

export default class InputShim extends React.Component {
  state = {
    value: ''
  }

  handleChange = (e) => {
    const { value } = e.target
    this.setState({ value })
    this.props.onChange(value)
  }
  
  render() {
    return <InputShimWrapper value={this.state.value} {...this.props} onChange={this.handleChange}/>
  }
}
