import { observable, action, decorate, toJS } from "mobx"
import { API, graphqlOperation } from "aws-amplify"

import { ALL_USER_WANS, CREATE_WAN, UPDATE_WAN, DELETE_WAN } from "../queries"

class WanStore {
  wans = []

  _paginationTokens = []

  /**
   * Retrieves a list of Wans associated with User
   *
   * @param {String!} ownerId - id of the current user
   * @param {Int} count - count of records to retrieve (positive to go forward, negative to go back)
   *
   * @return {Promise.<null>} empty promise
   *
   * Does not return wans list but instead updates the local wans array, which is a mobx observable.
   * The wans array contains Wan objects with the following shape:
   *
   * @typedef Wan
   * @type {Object}
   * @property {String} id - id
   * @property {String} status - ('DRAFT'|'ACTIVE'|'ARCHIVED')
   * @property {String} type - wan type ('HUB_SPOKE'|'MESH')
   * @property {String} name - wan name
   * @property {Array} sites - an array of members site ids
   * @property {Object} wanGateway - object containing "WAN Gateway" fields
   */
  allUserWans = async (ownerId, count = null) => {
    let startToken = null
    if (count !== null) {
      if (count < 0) {
        this._paginationTokens = _.slice(
          this._paginationTokens,
          0,
          this._paginationTokens.length - 2
        )
      }
      startToken = _.last(this._paginationTokens) || null
    }

    const {
      data: {
        allUserWans: { wans, nextToken }
      }
    } = await API.graphql(
      graphqlOperation(ALL_USER_WANS, {
        ownerId,
        count: count ? Math.abs(count) : null,
        nextToken: startToken
      })
    )

    if (nextToken) {
      this._paginationTokens.push(nextToken)
    }

    this.wans = _.reverse(
      _.sortBy(
        _.map(wans, w => ({
          ...w,
          wanGateway: w.wanGateway ? JSON.parse(w.wanGateway) : null
        })),
        ({ createdAt }) => new Date(createdAt)
      )
    )
    return Promise.resolve()
  }

  /**
   * Create a Wan
   *
   * @param {input} - wan info
   * @param {String!} input.ownerId - id of the current user
   * @param {String!} input.status - wan status: ('DRAFT'|'ACTIVE'|'ARCHIVED')
   * @param {String} input.name - wan name
   * @param {String} input.type - wan type ('HUB_SPOKE'|'MESH')
   * @param {Array} input.sites - site members as an array of site ids
   * @param {Object} input.wanGateway - object containing "WAN Gateway" fields
   * @return {Promise} Promise containing the wan id
   */
  createWan = async input => {
    console.log("input: ", input)
    try {
      const {
        data: {
          createWan: { id }
        }
      } = await API.graphql(
        graphqlOperation(CREATE_WAN, {
          input: {
            ...input,
            wanGateway: JSON.stringify(input.wanGateway)
          }
        })
      )

      console.log("WANID", id)

      this.wans = [{ ...input, id }, ...this.wans]
      return Promise.resolve(id)
    } catch (ex) {
      return Promise.reject()
    }
  }

  /**
   * Update a Wan
   * @param {input} - wan info
   * @param {String!} input.id - id of the wan to update
   * @param {String!} input.ownerId - id of the current user
   * @param {String!} input.status - wan status: ('DRAFT'|'ACTIVE'|'ARCHIVED')
   * @param {String} input.name - wan name
   * @param {String} input.type - wan type ('HUB_SPOKE'|'MESH')
   * @param {Array} input.sites - site members as an array of site ids
   * @param {Object} input.wanGateway - object containing "WAN Gateway" fields
   * @return {Promise} Promise containing the wan id
   */
  updateWan = async input => {
    await API.graphql(
      graphqlOperation(UPDATE_WAN, {
        input: {
          ...input,
          wanGateway: JSON.stringify(input.wanGateway)
        }
      })
    )

    const index = _.findIndex(this.wans, ({ id }) => id === input.id)
    this.wans = Object.assign([...this.wans.slice()], {
      [index]: input
    })

    return Promise.resolve(input.id)
  }

  /**
   * Delete a Wan
   * @param {input} - wan info
   * @param {Array|String} input.wanIds - ids of the wan to delete
   * @param {String!} input.ownerId - id of the current user
   * @return {Promise} Promise containing the wan id
   */
  deleteWan = async (wanIds, ownerId) => {
    if (!_.isArray(wanIds)) wanIds = [wanIds]
    await API.graphql(graphqlOperation(DELETE_WAN, { wanIds, ownerId }))
    this.wans = [
      ..._.filter(this.wans, wan => _.indexOf(wanIds, wan.id) === -1)
    ]
    return Promise.resolve()
  }
}

decorate(WanStore, {
  allUserWans: action,
  createWan: action,
  updateWan: action,
  deleteWan: action,
  wans: observable
})

export default new WanStore()
