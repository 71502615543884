import React from "react";
import { Heading, Box, Flex, Text } from "rebass";
import { observer, inject } from "mobx-react";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import { NavLink } from "react-router-dom";

import {
  HeadlineText,
  Title,
  WANButton,
  Checkbox,
  FlatPaper,
  Link,
  VerticalMenu,
  Button
} from "../ui";
import { Table, TablePagination } from "../ui/Table";
import { track } from "../../analytics";

const rows = [
  {
    id: "wanName",
    numeric: false,
    disablePadding: true,
    label: "WAN Name",
    style: { textAlign: "left" }
  },
  {
    id: "noOfsites",
    numeric: true,
    disablePadding: true,
    label: "# of sites",
    style: { textAlign: "left" }
  },
  {
    id: "prioritySettings",
    numeric: true,
    disablePadding: true,
    label: "Priority Settings"
  },
  {
    id: "alertsSettings",
    numeric: true,
    disablePadding: true,
    label: "Alerts Settings"
  },
  { id: "status", numeric: true, disablePadding: true, label: "Status" },
  { id: "actions", numeric: false, disablePadding: true, label: "Actions" }
];

@inject("userStore", "wanStore", "uxStore")
@observer
export default class WANList extends React.Component {
  state = {
    selected: [],
    wans: [],
    page: 0,
    rowsPerPage: 15
  };

  async componentDidMount() {
    track("WAN List");
    const { userId } = this.props.userStore.currentUser;
    await this.props.wanStore.allUserWans(userId);
  }

  handleChangePage = (event, page) => {};

  handleChangeRowsPerPage = event => {};

  handleNewWAN = () => {
    this.props.history.push("/console/wan-config");
  };

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  getCellProps(id) {
    switch (id) {
      case "wanName":
        return {
          component: "th",
          scope: "row",
          padding: "none",
          style: { textAlign: "left" }
        };
      case "noOfsites":
        return { numeric: true, style: { textAlign: "left" } };
      default:
        return {};
    }
  }

  handleDeleteWAN = id => async () => {
    const { userId } = this.props.userStore.currentUser;
    await this.props.wanStore.deleteWan(id, userId);
    this.props.uxStore.openToast({ message: "Deleted !" });
  };

  handleDeleteAllWans = async () => {
    const { userId } = this.props.userStore.currentUser;
    await this.props.wanStore.deleteWan(this.state.selected, userId);
    this.props.uxStore.openToast({ message: "Deleted !" });
  };

  getSortValue = (n, orderBy) => {
    switch (orderBy) {
      case "wanName":
        return _.get(n, "name", "");
      case "noOfsites":
        return _.size(n.sites);
      default:
        return _.get(n, orderBy);
    }
  };

  getCellChildren(row, n) {
    switch (row.id) {
      case "wanName":
        return (
          <Link
            color="#373737"
            css={{ textDecoration: "none" }}
            is={NavLink}
            to={`wans/${n.id}`}
          >
            {`${_.get(n, "name", "")}`}
          </Link>
        );
      case "noOfsites":
        return _.size(n.sites);
      case "actions":
        return (
          <VerticalMenu
            options={[
              {
                text: "Delete",
                onClick: this.handleDeleteWAN(n.id),
                manual: true
              }
            ]}
          />
        );
      default:
        return n[row.id];
    }
  }

  handleSelect = selected => {
    this.setState({ selected });
  };

  render() {
    const { wanStore } = this.props;
    const { wans } = wanStore;
    const { rowsPerPage, page, selected } = this.state;

    return (
      <Flex align="flex-start" flexDirection="column">
        <Flex
          flex="0 0 70px"
          css={{
            width: "100%",
            background: "linear-gradient(212deg, #a2a2a9 0%, #2b2b2b 100%)"
          }}
          pt={10}
          pb={10}
        >
          <Flex flex={1} align="center">
            <HeadlineText children={"WANs"} px={3} />
            <WANButton
              children="Create WAN"
              onClick={this.handleNewWAN}
              ml={"25px"}
            />
          </Flex>
          <Flex flex="1" width="100%" justify="flex-end">
            <TablePagination
              disableRipple
              component="div"
              count={_.size(wans)}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[]}
              page={page}
              backIconButtonProps={{
                "aria-label": "Previous Page"
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page"
              }}
              labelDisplayedRows={({ from, to, count }) => `${to} of ${count}`}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Flex>
        </Flex>
        <FlatPaper style={{ width: "100%" }}>
          <Table
            data={wans}
            ref={_ref => (this.wanList = _ref)}
            orderBy="siteName"
            page={page}
            rows={rows}
            sortByValue={this.getSortValue}
            rowsPerPage={rowsPerPage}
            onSelect={this.handleSelect}
            cellRenderer={(row, n) => {
              return {
                cellProps: this.getCellProps(row.id),
                children: this.getCellChildren(row, n)
              };
            }}
          />
        </FlatPaper>
        {!_.isEmpty(selected) && (
          <Button
            type="button"
            bg={"greys.3"}
            fontSize={13}
            fontWeight={300}
            p={"15px 25px"}
            ml={20}
            mt={20}
            onClick={this.handleDeleteAllWans}
          >
            Delete All Selected
          </Button>
        )}
      </Flex>
    );
  }
}
