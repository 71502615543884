import React from "react"
import _ from "lodash"
import { observer, inject } from "mobx-react"
import { Flex, Text, Box, Image } from "rebass"
import { Formik, Field, FastField, Form } from "formik"
import { withStyles } from "@material-ui/core"
import * as Yup from "yup"
import {
  Title,
  SubTitle,
  FormControlLabel,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  Tree,
  PrimaryButton,
  Button,
  Link,
  GradientFlex,
  PrimarySwitch,
  SecondarySwitch,
  SmallIOSSwitch,
  Input,
  RadioGroup,
  Radio,
  ErrorFormHelperText
} from "../ui"
import {
  interfacePortData,
  netMaskData,
  linkTypeData
} from "../ui/DropdownConstants"
import NumberTextField from "../ui/NumberTextField"
import { validateIPAddress, validateMinMax } from "../../utils/validations"

const carrierData = ["Rogers", "Teksavvy"]

const byteData = ["GB", "MB", "KB"]

const schema = Yup.object().shape({
  interfacePort: Yup.string()
    .nullable()
    .required("Interface port is required"),
  downloadThroughput: Yup.string()
    .nullable()
    .required("Missing Download Cap"),
  uploadThroughput: Yup.string()
    .nullable()
    .required("Missing Upload Cap")
})

const TunnelListData = [
  {
    active: false,
    carrier: null,
    tunnelBypassEnabled: false,
    connectionType: "dhcp",
    download: 0,
    id: 1,
    interfacePort: null,
    linkType: null,
    linkUsageEnabled: false,
    upload: 0,
    resetDay: 0,
    standbyEnabled: false,
    cidr: null,
    usageLimit: 0,
    usageLimitByte: null,
    notes: null,
    isEnabled: true
  }
]

const OnFormControlLabel = withStyles({
  label: {
    fontSize: 13,
    marginLeft: -15
  }
})(FormControlLabel)

const TextArea = withStyles({
  input: {
    fontSize: 11
  }
})(TextField)

const ResetErrFormHelperText = withStyles({
  root: {
    fontSize: 11,
    margin: "15px 0 0 30px"
  }
})(ErrorFormHelperText)

const ThroughputErrFormHelperText = withStyles({
  root: {
    fontSize: 11,
    margin: "15px 0 0 15px"
  }
})(ErrorFormHelperText)

const ThroughputCapCheckbox = withStyles({})(Checkbox)

class SpeedTest extends React.Component {
  state = {
    down: 0,
    up: 0
  }

  handleDownChange = event => {
    this.setState({ down: event.target.value })
  }

  handleUpChange = event => {
    this.setState({ up: event.target.value })
  }

  render() {
    return (
      <Flex
        width="100%"
        pb={20}
        css={{ borderBottom: "1px solid #ccc" }}
        justify="space-between">
        <Flex
          bg="greyBg"
          p={16.5}
          css={{
            boxShadow: "0px 4px 10px -3px rgba(0, 0, 0, 0.34)",
            maxWidth: "300px"
          }}
          mr={20}
          flex={1}>
          <Select
            labelText="Default Tunnel"
            htmlFor="defaultTunnel"
            value={defaultTunnelData[0]}
            frmControlStyle={{ minWidth: 150 }}>
            {_.map(defaultTunnelData, (data, i) => (
              <MenuItem key={i} children={data} value={data} />
            ))}
          </Select>
        </Flex>
        <Flex
          bg="greyBg"
          p={20}
          justify="flex-end"
          flex={1}
          align="center"
          css={{
            boxShadow: "0px 4px 10px -3px rgba(0, 0, 0, 0.34)",
            minWidth: "65%",
            maxWidth: "830px"
          }}>
          <Title fontSize={"1.5em"} pr={15} textAlign="end">
            Aggregated Speed
          </Title>
          <Flex pr={25} justify="flex-end">
            <Text fontSize={"1em"}>
              <b>Down</b> {this.state.down}
              Mbps <b style={{ paddingLeft: "5px" }}>Up</b> {this.state.up}
              Mbps
            </Text>
          </Flex>
          <PrimaryButton
            children="Run Aggregated Speed Test"
            fontSize={13}
            p={"10px 10px"}
          />
        </Flex>
      </Flex>
    )
  }
}

@inject("siteStore", "userStore")
class TunnelItem extends React.Component {
  static defaultProps = {
    standbyEnabled: false,
    connectionType: "dhcp",
    carrier: null,
    staticIpAddr: null,
    cidr: null,
    staticGateway: null,
    interfacePort: null,
    linkType: null,
    notes: null,
    download: 0,
    upload: 0,
    linkUsageEnabled: false,
    usageLimitByte: 0,
    usageLimit: 0,
    resetDay: 0,
    tunnelBypassEnabled: false,
    isEnabled: true,
    remotePort: null,
    onUploadChange: () => {},
    onDownloadChange: () => {},
    onTunnelBypass: () => {},
    onDirty: () => {}
  }

  state = {
    remotePort: this.props.remotePort
  }

  debouncedOnError = _.debounce(this.props.onError, 250)
  debouncedOnTunnelBypass = _.debounce(this.props.onTunnelBypass, 10)

  getValues() {
    const values = _.get(this.form, "state.values")
    return {
      ..._.get(this.form, "initialValues"),
      ...values,
      ...{
        downloadThroughput: values.throughputCapEnabled
          ? values.downloadThroughput
          : 0,
        uploadThroughput: values.throughputCapEnabled
          ? values.uploadThroughput
          : 0,
        remotePort: this.state.remotePort,
        interfacePort: this.props.interfacePort
      }
    }
  }

  getErrors(errors, touched) {
    return !_.isEmpty(
      _.filter(_.keys(errors), key => {
        return !!touched[key]
      })
    )
  }

  resetForm = () => {
    this.form.resetForm(this.getValues())
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.tunnelBypassEnabled &&
      prevProps.tunnelBypassEnabled !== this.props.tunnelBypassEnabled
    ) {
      this.form.setFieldValue(
        "tunnelBypassEnabled",
        this.props.tunnelBypassEnabled
      )
    }
  }

  handleDeleteLink = async () => {
    this.props.onDeleteLink()
    await this.props.siteStore.dropUbondServerPort(
      _.get(this.props, "userStore.currentUser.userId"),
      _.get(this.props, "userStore.config.serverId"),
      this.state.remotePort
    )
  }

  async componentDidMount() {
    this.form.setFieldTouched("linkType", true)
    this.form.setFieldTouched("carrier", true)

    this.form.setFieldTouched("interfacePort", true)
    if (this.state.remotePort) return
    const userId = _.get(this.props, "userStore.currentUser.userId")
    const remotePort = await this.props.siteStore.getUnusedUbondServerPort()
    this.setState({ remotePort })
  }

  render() {
    const {
      standbyEnabled,
      interfacePort,
      connectionType,
      staticIpAddr,
      cidr,
      staticGateway,
      linkType,
      carrier,
      download,
      upload,
      linkUsageEnabled,
      usageLimitByte,
      usageLimit,
      resetDay,
      tunnelBypassEnabled,
      isEnabled,
      throughputCapEnabled,
      downloadThroughput,
      uploadThroughput,
      itemIndex,
      onUploadChange,
      onDownloadChange,
      onStandbyChange,
      onDeleteLink,
      notes,
      id
    } = this.props

    return (
      <Formik
        ref={_ref => (this.form = _ref)}
        validationSchema={schema}
        initialValues={{
          standbyEnabled,
          interfacePort: interfacePortData[this.props.itemIndex + 1],
          connectionType,
          staticIpAddr,
          cidr,
          staticGateway,
          linkType,
          carrier,
          download,
          upload,
          linkUsageEnabled,
          usageLimitByte,
          usageLimit,
          resetDay,
          tunnelBypassEnabled,
          isEnabled,
          throughputCapEnabled,
          downloadThroughput,
          uploadThroughput,
          notes,
          id
        }}
        render={({
          values,
          isSubmitting,
          dirty,
          touched,
          errors,
          handleSubmit,
          handleChange,
          setFieldTouched
        }) => (
          <Box flex={"0 0 400px"} mr={25} mt={30}>
            <GradientFlex flex={1} flexDirection="column" width="100%">
              <Flex p={"3px 0"} />
              <Flex
                bg="greyBg"
                p={"3px 0 3px 28px"}
                justify="space-between"
                css={{ borderBottom: "1px solid #777" }}>
                <FastField
                  label="Standby"
                  name="standbyEnabled"
                  component={Checkbox}
                  onChange={(name, value) => {
                    onStandbyChange(value, itemIndex)
                  }}
                />
                <FastField
                  name="isEnabled"
                  render={props => (
                    <OnFormControlLabel
                      control={<SmallIOSSwitch {...props} />}
                      label={"Enable"}
                    />
                  )}
                />
              </Flex>

              <Flex pl={25} pt={20} justify="space-between">
                <Field
                  disabled={true}
                  flex={"0 0 60px"}
                  name="interfacePort"
                  error={errors.interfacePort}
                  touched={touched.interfacePort}
                  label="Interface Port"
                  component={TextField}
                  css={{ maxWidth: "170px", marginRight: "5%" }}
                  value={interfacePort}
                  // render={props => (
                  //   // <Select
                  //   //   {...props}
                  //   //   error={errors.interfacePort}
                  //   //   touched={touched.interfacePort}
                  //   //   htmlFor="interfacePort"
                  //   //   labelText="Interface Port"
                  //   //   value={values.interfacePort}
                  //   //   frmControlStyle={{ maxWidth: 115, minWidth: 115 }}
                  //   //   labelStyle={{ width: "200px" }}
                  //   //   setFieldTouched={setFieldTouched}
                  //   // >
                  //     {/* {_.map(interfacePortData, (data, i) => (
                  //       <MenuItem key={i} children={data} value={data} />
                  //     ))} */}
                  //   </Select>
                  // )}
                />
                <Box width={200}>
                  <FastField
                    name="connectionType"
                    render={props => (
                      <RadioGroup
                        {...props}
                        htmlFor="connectionType"
                        onChange={this.handleConnectionChange}>
                        <FormControlLabel
                          value="static"
                          control={<Radio color="primary" disableRipple />}
                          label="Static"
                        />
                        <FormControlLabel
                          value="dhcp"
                          control={<Radio color="primary" disableRipple />}
                          label="DHCP"
                        />
                      </RadioGroup>
                    )}
                  />
                </Box>
              </Flex>

              {values.connectionType === "static" && (
                <Flex p={"15px 27px 0 27px"} justify="space-between">
                  <Flex>
                    <FastField
                      validate={value =>
                        value ? validateIPAddress(value) : null
                      }
                      label="IP"
                      name="staticIpAddr"
                      component={TextField}
                      style={{ maxWidth: "109px" }}
                    />
                    <FastField
                      name="cidr"
                      render={props => (
                        <Select
                          {...props}
                          labelText="CIDR"
                          htmlFor="cidr"
                          renderValue={value => `/${value}`}
                          value={values.cidr}
                          frmControlStyle={{
                            maxWidth: 80,
                            minWidth: 80,
                            marginLeft: "10px"
                          }}>
                          {_.map(netMaskData, (data, i) => (
                            <MenuItem
                              key={i}
                              children={`/${data}`}
                              value={data}
                            />
                          ))}
                        </Select>
                      )}
                    />
                  </Flex>
                  <FastField
                    validate={value =>
                      value ? validateIPAddress(value) : null
                    }
                    name="staticGateway"
                    label="Gateway"
                    component={TextField}
                    style={{ maxWidth: "109px" }}
                  />
                </Flex>
              )}

              <Flex justify="space-around" p={"20px 0"}>
                <FastField
                  name="linkType"
                  render={props => (
                    <Select
                      {...props}
                      error={errors.linkType}
                      touched={touched.linkType}
                      setFieldTouched={setFieldTouched}
                      labelText="Link Type"
                      htmlFor="linkType"
                      value={values.linkType}
                      frmControlStyle={{ maxWidth: 150, minWidth: 150 }}>
                      {_.map(linkTypeData, (data, i) => (
                        <MenuItem key={i} children={data} value={data} />
                      ))}
                    </Select>
                  )}
                />
                <FastField
                  name="carrier"
                  render={props => (
                    <Select
                      {...props}
                      error={errors.carrier}
                      touched={touched.carrier}
                      setFieldTouched={setFieldTouched}
                      labelText="Carrier"
                      htmlFor="carrier"
                      value={values.carrier}
                      frmControlStyle={{ maxWidth: 150, minWidth: 150 }}>
                      {_.map(carrierData, (data, i) => (
                        <MenuItem key={i} children={data} value={data} />
                      ))}
                    </Select>
                  )}
                />
              </Flex>

              <Flex
                p={10}
                align="flex-start"
                bg="greyBg"
                css={{ borderTop: "1px solid #777" }}>
                <Box pl={14} flex={1}>
                  <Text fontSize={"0.875rem"}>Expected Throughput</Text>
                  <Text fontSize={12}>(Mbps)</Text>
                </Box>
                <Flex flex={"0 0 165px"} justify="flex-end">
                  <Flex
                    flexDirection="column"
                    flex={"0 0 95px"}
                    align="flex-start">
                    <Text fontSize={12}>Download</Text>
                    <Field
                      name="download"
                      render={({ field, form }) => (
                        <Input
                          type="number"
                          style={{
                            width: "40px",
                            fontSize: "15px",
                            fontWeight: 800
                          }}
                          {...field}
                          {...form}
                          onChange={e => {
                            if (!values.throughputCapEnabled) {
                              onDownloadChange(
                                e.target.value,
                                itemIndex,
                                values.standbyEnabled
                              )
                            }
                            field.onChange(e)
                          }}
                        />
                      )}
                    />
                  </Flex>
                  <Flex
                    flexDirection="column"
                    flex={"0 0 60px"}
                    align="flex-start">
                    <Text fontSize={12}>Upload</Text>
                    <Field
                      name="upload"
                      render={({ field, form }) => (
                        <Input
                          type="number"
                          style={{
                            width: "40px",
                            fontSize: "15px",
                            fontWeight: 800
                          }}
                          {...field}
                          {...form}
                          onChange={e => {
                            if (!values.throughputCapEnabled) {
                              onUploadChange(
                                e.target.value,
                                itemIndex,
                                values.standbyEnabled
                              )
                            }
                            field.onChange(e)
                          }}
                        />
                      )}
                    />
                  </Flex>
                </Flex>
              </Flex>

              <Flex
                flexDirection="column"
                p={10}
                align="flex-start"
                bg="greyBg"
                css={{ borderTop: "1px solid #777" }}>
                <Flex width="100%">
                  <Flex pl={18} flex={1}>
                    <Field
                      name="throughputCapEnabled"
                      label={
                        <Text>
                          <Text>Set Throughput Cap</Text>
                          <Text fontSize={12}>(Mbps)</Text>
                        </Text>
                      }
                      component={props => (
                        <ThroughputCapCheckbox
                          {...props}
                          onChange={(__, checked) => {
                            setFieldTouched("downloadThroughput", true)
                            setFieldTouched("uploadThroughput", true)
                            if (!checked) {
                              onDownloadChange(
                                values.download,
                                itemIndex,
                                values.standbyEnabled
                              )
                              onUploadChange(
                                values.upload,
                                itemIndex,
                                values.standbyEnabled
                              )
                            } else {
                              onDownloadChange(
                                values.downloadThroughput,
                                itemIndex,
                                values.standbyEnabled
                              )
                              onUploadChange(
                                values.uploadThroughput,
                                itemIndex,
                                values.standbyEnabled
                              )
                            }
                          }}
                        />
                      )}
                    />
                  </Flex>
                  <Flex flex={"0 0 155px"} justify="flex-end">
                    <Flex
                      flexDirection="column"
                      flex={"0 0 95px"}
                      align="flex-start">
                      <Text fontSize={12}>Download</Text>
                      <Field
                        validate={value => {
                          if (values.throughputCapEnabled) {
                            return validateMinMax({
                              min: 0,
                              max: values.download,
                              name: "Download Cap",
                              field: "Expected download throughput"
                            })(value)
                          }
                        }}
                        name="downloadThroughput"
                        render={({ field, form }) => (
                          <Input
                            type="number"
                            style={{
                              width: "40px",
                              fontSize: "15px",
                              fontWeight: 800
                            }}
                            {...field}
                            {...form}
                            onChange={e => {
                              console.log(e.target.value)
                              onDownloadChange(
                                e.target.value,
                                itemIndex,
                                values.standbyEnabled
                              )
                              field.onChange(e)
                            }}
                            disabled={!values.throughputCapEnabled}
                          />
                        )}
                      />
                    </Flex>
                    <Flex
                      flexDirection="column"
                      flex={"0 0 60px"}
                      align="flex-start">
                      <Text fontSize={12}>Upload</Text>
                      <Field
                        name="uploadThroughput"
                        validate={value => {
                          if (values.throughputCapEnabled) {
                            return validateMinMax({
                              min: 0,
                              max: values.upload,
                              name: "Upload Cap",
                              field: "Expected upload throughput"
                            })(value)
                          }
                        }}
                        render={({ field, form }) => (
                          <Input
                            type="number"
                            style={{
                              width: "40px",
                              fontSize: "15px",
                              fontWeight: 800
                            }}
                            {...field}
                            {...form}
                            onChange={e => {
                              onUploadChange(
                                e.target.value,
                                itemIndex,
                                values.standbyEnabled
                              )
                              field.onChange(e)
                            }}
                            disabled={!values.throughputCapEnabled}
                          />
                        )}
                      />
                    </Flex>
                  </Flex>
                </Flex>
                {values.throughputCapEnabled &&
                  !!errors.downloadThroughput &&
                  touched.downloadThroughput && (
                    <ThroughputErrFormHelperText
                      children={errors.downloadThroughput}
                    />
                  )}
                {values.throughputCapEnabled &&
                  !!errors.uploadThroughput &&
                  touched.uploadThroughput && (
                    <ThroughputErrFormHelperText
                      children={errors.uploadThroughput}
                    />
                  )}
              </Flex>

              <Flex
                p={"0 0 15px 0"}
                bg="greyBg"
                css={{
                  borderTop: "1px solid #777",
                  borderBottom: "1px solid #777"
                }}
                justify="space-around"
                flexDirection="column">
                <Flex pl={28}>
                  <FastField
                    name="linkUsageEnabled"
                    label="Manage Link Usage"
                    component={Checkbox}
                  />
                </Flex>
                <Flex justify="space-around" mt={"5px"}>
                  <Flex>
                    <Flex
                      flexDirection="column"
                      flex={"0 0 75px"}
                      align="flex-start">
                      <Text fontSize={12}>Usage Limit</Text>
                      <FastField
                        name="usageLimit"
                        render={({ field, form }) => (
                          <Input
                            type="number"
                            style={{ width: "55px", fontSize: "15px" }}
                            {...field}
                            {...form}
                          />
                        )}
                      />
                    </Flex>
                    <FastField
                      name="usageLimitByte"
                      render={props => (
                        <Select
                          {...props}
                          frmControlStyle={{
                            maxWidth: 55,
                            minWidth: 55,
                            marginTop: 7
                          }}
                          htmlFor="byte"
                          value={values.usageLimitByte}>
                          {_.map(byteData, (data, i) => (
                            <MenuItem key={i} children={data} value={data} />
                          ))}
                        </Select>
                      )}
                    />
                  </Flex>
                  <Flex flexDirection="column">
                    <Text fontSize={12}>Reset Date</Text>
                    <Flex align="flex-end" mt={"4px"}>
                      <FastField
                        name="resetDay"
                        // validate={validateMinMax({
                        //   min: 1,
                        //   max: 31,
                        //   name: "Reset Date"
                        // })}
                        render={props => (
                          <NumberTextField
                            inputWidth="40px"
                            {...props}
                            max={31}
                            min={1}
                          />
                        )}
                      />
                      <Text fontSize={12}>day of the month</Text>
                    </Flex>
                  </Flex>
                </Flex>
                {!!errors.resetDay && touched.resetDay && (
                  <ResetErrFormHelperText children={errors.resetDay} />
                )}
              </Flex>

              {/* <Flex justify="flex-start" p={"10px 0 10px 20px"}>
                <Box css={{ border: "1px solid red" }} p={"0 0 0 10px"}>
                  <FastField
                    name="tunnelBypassEnabled"
                    color="priority"
                    label="Tunnel Bypass"
                    component={Checkbox}
                    onChange={this.debouncedOnTunnelBypass}
                  />
                </Box>
              </Flex> */}

              {/* <Flex
                p={10}
                justify="center"
                css={{ borderTop: "1px solid #777" }}
              >
                <Button fontSize={13} bg="green">
                  Link Speed Test
                </Button>
              </Flex> */}
              <Flex
                flexDirection="column"
                fontSize={11}
                p={"15px 20px 0 20px"}
                flex={1}
                bg="greyBg"
                css={{ borderTop: "1px solid #777" }}
                bg="navBg">
                <Box flex={"0 0 22px"}>Notes</Box>
                <Box mb={15}>
                  <FastField
                    name="notes"
                    value={values.notes}
                    render={props => <TextArea multiline rows="4" {...props} />}
                  />
                </Box>
              </Flex>

              <Flex p={10} justify="center">
                <Link
                  children="Delete"
                  underline
                  fontSize={11}
                  onClick={this.handleDeleteLink}
                />
              </Flex>
              {(dirty || touched) &&
                this.debouncedOnError(this.getErrors(errors, touched))}
              {this.props.onDirty(dirty)}
            </GradientFlex>
          </Box>
        )}
      />
    )
  }
}

const AddTunnel = props => (
  <GradientFlex
    width={85}
    css={{
      height: "75px",
      cursor: "pointer",
      "&:hover": { transform: "scale(1.1)" }
    }}
    m={"30px 5px"}
    justify="center"
    align="center"
    onClick={props.onClick}>
    <Image width={60} src="https://icongr.am/octicons/plus.svg?color=CCCCCC" />
  </GradientFlex>
)
@inject("siteStore")
class TunnelList extends React.Component {
  static defaultProps = {
    links: []
  }

  state = {
    tunnelList: [...this.props.links.slice()],
    errors: {},
    dirty: {}
  }

  getValues() {
    return _.map(this.state.tunnelList, (item, index) => {
      return this[`tunnelItem.${index}`].wrappedInstance.getValues()
    })
  }

  onAdd = () => {
    const newItem = { ...TunnelListData[0] }
    newItem.id = _.isEmpty(this.state.tunnelList)
      ? 0
      : _.last(this.state.tunnelList).id + 1
    this.setState({
      tunnelList: [...this.state.tunnelList, newItem]
    })
  }

  handleDeleteLink = (index, id) => () => {
    this.setState({
      tunnelList: _.filter(this.state.tunnelList, link => link.id !== id)
    })
    this.props.onError(false)
    this.props.onDirty(true)
    this.props.onDeleteLink(index)
  }

  handleError = (index, id) => hasError => {
    const { errors } = this.state
    if ((!errors[id] && hasError) || (errors[id] && errors[id] !== hasError)) {
      if (hasError) {
        errors[id] = hasError
      } else {
        delete errors[id]
      }

      this.setState(
        {
          errors: { ...errors }
        },
        () => {
          this.props.onError(!_.isEmpty(_.keys(this.state.errors)))
        }
      )
    }
  }

  handleDirty = (index, id) => isDirty => {
    const { dirty } = this.state
    if ((!dirty[id] && isDirty) || (dirty[id] && dirty[id] !== isDirty)) {
      if (isDirty) {
        dirty[id] = isDirty
      } else {
        delete dirty[id]
      }

      this.setState(
        {
          dirty: { ...dirty }
        },
        () => {
          this.props.onDirty(!_.isEmpty(_.keys(this.state.dirty)))
        }
      )
    }
  }

  resetForm = () => {
    _.each(this.state.tunnelList, (item, index) => {
      this[`tunnelItem.${index}`].wrappedInstance.resetForm()
    })
  }

  handleTunnelBypass = (index, id) => (name, value) => {
    this.setState({
      tunnelList: _.map(this.state.tunnelList, link => {
        return { ...link, tunnelBypassEnabled: link.id === id ? value : false }
      })
    })
  }

  render() {
    return (
      <Flex flex={1} flexWrap="wrap">
        {_.map(this.state.tunnelList, (data, index) => (
          <TunnelItem
            ref={_ref => (this[`tunnelItem.${index}`] = _ref)}
            key={`tunnel-${data.id}`}
            itemIndex={index}
            {...data}
            onUploadChange={this.props.onUploadChange}
            onDownloadChange={this.props.onDownloadChange}
            onStandbyChange={this.props.onStandbyChange}
            onDeleteLink={this.handleDeleteLink(index, data.id)}
            onError={this.handleError(index, data.id)}
            onTunnelBypass={this.handleTunnelBypass(index, data.id)}
            onDirty={this.handleDirty(index, data.id)}
            interfacePort={interfacePortData[index + 1]}
          />
        ))}
        {this.props.siteStore.usedPorts.length < 8 && (
          <AddTunnel onClick={this.onAdd} />
        )}
      </Flex>
    )
  }
}

class SiteConfigTunnel extends React.PureComponent {
  static defaultProps = {
    onDeleteLink: () => {},
    onDirty: () => {},
    onError: () => {}
  }

  getValues() {
    return this.tunnelList.wrappedInstance.getValues()
  }

  resetForm() {
    this.tunnelList.wrappedInstance.resetForm()
  }

  getSiteId() {
    return _.get(this.props, "match.params.id")
  }

  render() {
    return (
      <Flex flexDirection="column">
        <TunnelList
          ref={_ref => (this.tunnelList = _ref)}
          links={this.props.links}
          onUploadChange={this.props.onUploadChange}
          onDownloadChange={this.props.onDownloadChange}
          onStandbyChange={this.props.onStandbyChange}
          onDeleteLink={this.props.onDeleteLink}
          onError={this.props.onError}
          onDirty={this.props.onDirty}
        />
      </Flex>
    )
  }
}

export default SiteConfigTunnel
