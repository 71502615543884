import React from "react"
import { Heading, Box, Flex, Text } from "rebass"
import { observer, inject } from "mobx-react"
import Check from "@material-ui/icons/Check"
import { NavLink } from "react-router-dom"

import {
  HeadlineText,
  Title,
  PrimaryButton,
  Checkbox,
  FlatPaper,
  Link,
  Legends,
  VerticalMenu
} from "../ui"
import LogViewerDialog from "./dialogs/LogViewerDialog"
import { Table, TablePagination } from "../ui/Table"
import { LOG_VIEWER } from "./dialogs/constants"
import { track } from "../../analytics"
import { copyToClipboard } from "../../utils"

const { On, Off } = Legends

const getActive = status => {
  switch (status) {
    case true:
      return (
        <On
          style={{ width: "2.5em", height: "2.5em" }}
          innerStyle={{ width: "1.2em", height: "1.2em" }}
        />
      )
    case false:
      return (
        <Off
          style={{ width: "2.5em", height: "2.5em" }}
          innerStyle={{ width: "0.8em", height: "0.8em" }}
        />
      )
  }
}

const rows = [
  {
    id: "siteName",
    numeric: false,
    disablePadding: true,
    label: "Site Name",
    style: { textAlign: "left", paddingLeft: "30px" }
  },
  {
    id: "companyName",
    numeric: true,
    disablePadding: true,
    label: "Company Name",
    style: { textAlign: "left" }
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
    style: { textAlign: "left" }
  },
  {
    id: "wanSiteEnabled",
    numeric: false,
    disablePadding: true,
    label: "WAN Enabled",
    style: { textAlign: "left" }
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    label: "Actions",
    style: { textAlign: "left" }
  }
]

@inject("userStore", "siteStore", "companyStore", "uxStore")
@observer
export default class SiteList extends React.Component {
  state = {
    selected: [],
    sites: [],
    page: 0,
    rowsPerPage: 15
  }

  async componentDidMount() {
    track("Site List")
    const { userId } = this.props.userStore.currentUser
    await this.props.siteStore.allUserSites(userId)
  }

  handleChangePage = (event, page) => {}

  handleChangeRowsPerPage = event => {}

  handleNewSite = () => {
    this.props.history.push("/console/company-profile")
  }

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  isSelected = id => this.state.selected.indexOf(id) !== -1

  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value })
  }

  handleCopyInstall = siteId => async closeMenu => {
    const installUrl = await this.props.siteStore.getCoreInstallUrl(siteId)
    closeMenu()
    const success = await copyToClipboard(installUrl)
    if (success) {
      this.props.uxStore.openToast({
        message: `Copied! 
      Valid For 15mins`
      })
    }
  }

  handleDeploySite = siteId => async closeMenu => {
    await this.props.siteStore
      .deploySite(siteId, _.get(this.props, "userStore.currentUser.userId"))
      .then(() => {
        this.props.uxStore.openToast({ message: "Site Deployed !" })
      })
      .catch(err => {
        this.props.uxStore.openToast({
          status: "error",
          message: "Error occurred !"
        })
      })
    closeMenu()
  }

  handleViewLog = siteId => () => {
    this.props.uxStore.openDialog(LOG_VIEWER)
  }

  getCellProps(id) {
    switch (id) {
      case "siteName":
        return {
          component: "th",
          scope: "row",
          padding: "none",
          style: { textAlign: "left", paddingLeft: "30px" }
        }
      case "companyName":
        return {
          style: { textAlign: "left" }
        }
      case "status":
        return {
          style: { textAlign: "left" }
        }
      case "wanSiteEnabled":
        return {
          style: { paddingLeft: "30px" }
        }
      case "actions":
        return {
          style: { textAlign: "left" }
        }
      case "primaryLinks":
      case "standbyLinks":
      case "avg":
        return { numeric: true }
      default:
        return {}
    }
  }

  getSortValue = (n, orderBy) => {
    switch (orderBy) {
      case "siteName":
        return `${_.get(n, "info.streetAddr", n.id)} ${_.get(
          n,
          "info.streetAddr2",
          ""
        )}`
      case "companyName":
        return (
          _.get(n, "info.name") ||
          _.get(
            _.find(_.get(this.props, "companyStore.companies"), {
              id: _.get(n, "info.companyId")
            }),
            "name"
          )
        )
      case "wanSiteEnabled":
        return _.get(n, "configuration.preferences.wanSiteEnabled")
      default:
        return _.get(n, orderBy)
    }
  }

  getCellChildren(row, n) {
    switch (row.id) {
      case "siteName":
        return (
          <Link
            color="#373737"
            css={{ textDecoration: "none" }}
            is={NavLink}
            to={`sites/${n.id}`}>
            {`${_.get(n, "info.streetAddr", n.id)} ${_.get(
              n,
              "info.streetAddr2",
              ""
            )}`}
          </Link>
        )
      case "companyName":
        return (
          _.get(n, "info.name") ||
          _.get(
            _.find(_.get(this.props, "companyStore.companies"), {
              id: _.get(n, "info.companyId")
            }),
            "name"
          )
        )
      case "wanSiteEnabled":
        return _.get(n, "configuration.preferences.wanSiteEnabled")
          ? getActive(true)
          : null
      case "actions":
        return (
          <VerticalMenu
            options={[
              {
                text: "Copy Install Command",
                onClick: this.handleCopyInstall(n.id),
                manual: true
              },
              {
                text: "Deploy Site",
                onClick: this.handleDeploySite(n.id),
                manual: true
              },
              {
                text: "View Logs",
                onClick: this.handleViewLog(n.id)
              }
            ]}
          />
        )
      default:
        return n[row.id]
    }
  }

  render() {
    const { siteStore } = this.props
    const { sites } = siteStore
    const { rowsPerPage, page } = this.state
    return (
      <Flex flexDirection="column" pb={90}>
        <Flex align="flex-start" flexDirection="column">
          <Flex
            flex="0 0 70px"
            css={{
              width: "100%",
              background: "linear-gradient(212deg, #a2a2a9 0%, #2b2b2b 100%)"
            }}
            pt={10}
            pb={10}>
            <Flex flex={1} align="center">
              <HeadlineText children={"Sites"} px={3} />
              <PrimaryButton
                children="Create Site"
                onClick={this.handleNewSite}
              />
            </Flex>
          </Flex>
          <Flex flex="1" width="100%" justify="flex-end">
            <TablePagination
              disableRipple
              component="div"
              count={sites.length}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[]}
              page={page}
              backIconButtonProps={{
                "aria-label": "Previous Page"
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page"
              }}
              labelDisplayedRows={({ from, to, count }) => `${to} of ${count}`}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Flex>
        </Flex>
        <FlatPaper>
          <Table
            disableCheck
            data={sites}
            orderBy="siteName"
            page={page}
            rows={rows}
            sortByValue={this.getSortValue}
            rowsPerPage={rowsPerPage}
            cellRenderer={(row, n) => {
              return {
                cellProps: this.getCellProps(row.id),
                children: this.getCellChildren(row, n)
              }
            }}
          />
        </FlatPaper>
        <LogViewerDialog />
      </Flex>
    )
  }
}
