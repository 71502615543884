import React from "react";
import { Heading, Box, Flex, Text } from "rebass";
import { observer, inject } from "mobx-react";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import { NavLink } from "react-router-dom";

import {
  HeadlineText,
  Title,
  WANButton,
  Checkbox,
  FlatPaper,
  Link
} from "../ui";
import { Table, TablePagination } from "../ui/Table";

const rows = [
  {
    id: "profileName",
    numeric: false,
    disablePadding: true,
    label: "Name",
    style: { textAlign: "left" }
  },
  {
    id: "profileType",
    numeric: true,
    disablePadding: true,
    label: "Profile Type",
    style: { textAlign: "left" }
  },
  {
    id: "configType",
    numeric: true,
    disablePadding: true,
    label: "Config Type",
    style: { textAlign: "left" }
  }
];

let id = 0;
function createData(id, profileName, profileType, configType) {
  id += 1;
  return { id, profileName, profileType, configType };
}

const data = [
  createData("Profile A", "Profile Type A", "WAN"),
  createData("Profile B", "Profile Type B", "Site"),
  createData("Profile C", "Profile Type C", "WAN")
];

@inject("userStore")
@observer
export default class ProfileList extends React.Component {
  state = {
    selected: [],
    profiles: data,
    page: 0,
    rowsPerPage: 15
  };

  handleChangePage = (event, page) => {};

  handleChangeRowsPerPage = event => {};

  handleNewWAN = () => {
    this.props.history.push("/console/wan-config");
  };

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  getCellProps(id) {
    switch (id) {
      case "profileName":
        return {
          component: "th",
          scope: "row",
          padding: "none",
          style: { textAlign: "left" }
        };
      case "profileType":
        return { numeric: true, style: { textAlign: "left" } };
      default:
        return { style: { textAlign: "left" } };
    }
  }

  getCellChildren(row, n) {
    switch (row.id) {
      default:
        return n[row.id];
    }
  }

  getSortValue = (n, orderBy) => {
    return _.get(n, orderBy);
  };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      wanStore
    } = this.props;
    const { profiles } = this.state;
    const { rowsPerPage, page } = this.state;

    return (
      <Flex align="flex-start" flexDirection="column">
        <Flex
          flex="0 0 70px"
          css={{
            width: "100%",
            background: "linear-gradient(212deg, #a2a2a9 0%, #2b2b2b 100%)"
          }}
          pt={10}
          pb={10}
        >
          <Flex flex={1} align="center">
            <HeadlineText children={"Profiles"} px={3} />
            <WANButton children="Create Profile" ml={"25px"} />
          </Flex>
          <Flex flex="1" width="100%" justify="flex-end">
            <TablePagination
              disableRipple
              component="div"
              count={_.size(profiles)}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[]}
              page={page}
              backIconButtonProps={{
                "aria-label": "Previous Page"
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page"
              }}
              labelDisplayedRows={({ from, to, count }) => `${to} of ${count}`}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Flex>
        </Flex>
        <FlatPaper style={{ width: "100%" }}>
          <Table
            data={profiles}
            orderBy="siteName"
            page={page}
            rows={rows}
            sortByValue={this.getSortValue}
            rowsPerPage={rowsPerPage}
            cellRenderer={(row, n) => {
              return {
                cellProps: this.getCellProps(row.id),
                children: this.getCellChildren(row, n)
              };
            }}
          />
        </FlatPaper>
      </Flex>
    );
  }
}
