import React from "react";
import { Provider as RebassThemeProvider } from "rebass";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter } from "react-router-dom";
import { injectGlobal } from "styled-components";
import { hot } from "react-hot-loader";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { Routes } from "./Routes";
import themes from "../themes";

injectGlobal`
  * { box-sizing: border-box; }
  body { 
    margin: 0; 
    display: flex;
    font-family: montserrat;
    font-size: 15px;
    line-height: 1.6;
    flex-direction: column;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button
  {
    -webkit-appearance: none;
    margin: 0;
  }

  input::placeholder {
    color: #FFF !important;
  }

  input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  
  input[type="number"] {
      -moz-appearance: textfield;
      -webkit-appearance: textfield;
  }
`;

@DragDropContext(HTML5Backend)
class App extends React.Component {
  render() {
    return (
      <RebassThemeProvider theme={themes.rebass}>
        <MuiThemeProvider theme={themes.material}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </MuiThemeProvider>
      </RebassThemeProvider>
    );
  }
}

export default App;
