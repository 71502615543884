import React from "react"
import _ from "lodash"
import styled from "styled-components"
import * as Yup from "yup"

import { observer, inject } from "mobx-react"
import { Flex, Text, Box, Image } from "rebass"
import { Formik, Field, FastField, Form } from "formik"
import { withStyles } from "@material-ui/core"
import {
  Title,
  SubTitle,
  FormControlLabel,
  Checkbox,
  TextField,
  Headline,
  Select,
  MenuItem,
  Button,
  PrimaryButton,
  Link,
  GradientFlex,
  SecondarySwitch
} from "../ui"
import {
  interfacePortData,
  netMaskData,
  linkTypeData
} from "../ui/DropdownConstants"
import { TRAFFIC_MGMT_PROFILE, CREATE_WAN } from "./dialogs/constants"
import { validateIPAddress } from "../../utils/validations"
import { validateCIDR } from "../../utils/validations"

const trafficProfileData = ["Recommended Settings"]

const ipConfigData = [{ value: 32, text: "Default/32(Single)" }]

const ipVersionData = ["IPv4", "IPv6"]

const alertProfileData = ["Default"]

const wanSiteData = ["0.0.0.0"]

const NatSwitch = withStyles({
  root: {
    right: -5
  }
})(SecondarySwitch)

const SitePrefCheckbox = withStyles({
  label: {
    fontSize: ".9em"
  },
  labelChecked: {
    color: "orange",
    fontSize: ".9em"
  },
  labelRoot: {
    marginRight: 0
  }
})(Checkbox)

const validateWAN = (wanSiteEnabled, type, message) => value => {
  if (wanSiteEnabled && !value) {
    return message ? message : `${type} is required`
  }
}

const validateSubnet = (wanSiteEnabled, type, message) => value => {
  return (
    validateWAN(wanSiteEnabled, type, message)(value) ||
    (wanSiteEnabled && validateIPAddress(value))
  )
}

const schema = Yup.object().shape({
  subnetScheme: Yup.string().required("CIDR Is Required"),
  cpeIPAddr: Yup.string().required("Lan Address Is Required")
})

@inject("uxStore", "wanStore", "siteStore")
@observer
class SiteConfigForm extends React.PureComponent {
  static defaultProps = {
    alertsEnabled: false,
    alertProfile: null,
    clientEmail: null,
    wanSiteEnabled: false,
    wanSite: null,
    subnet: null,
    subnetScheme: "/24",
    trafficMgmtEnabled: false,
    trafficProfile: null,
    advRoutingEnabled: false,
    cpeIPAddr: "192.168.7.0",
    lanSubnetScheme: null,
    primaryDNS: "8.8.8.8",
    secondaryDNS: "8.8.4.4",
    ipConfig: null,
    ipVersion: null,
    interfacePort: "eth0",
    cidr: "/24",
    onError: () => {},
    onPrefChange: () => {},
    onDirty: () => {}
  }

  constructor(props) {
    super(props)
  }

  getValues() {
    return {
      ..._.get(this.form, "initialValues"),
      ..._.get(this.form, "state.values")
    }
  }

  resetForm() {
    this.form.resetForm(this.getValues())
  }

  handleCreateTrafficProfile = () => {
    this.props.uxStore.openDialog(TRAFFIC_MGMT_PROFILE)
  }

  getErrors(errors, touched) {
    return !_.isEmpty(
      _.filter(_.keys(errors), key => {
        return !!touched[key]
      })
    )
  }

  handleCreateNewWan = wanSiteEnabled => () => {
    if (!wanSiteEnabled) return false
    this.props.uxStore.openDialog(CREATE_WAN)
  }

  handlePrefChange = (name, checked) => {
    this.form.setFieldTouched("subnet", checked)
    this.form.setFieldTouched("subnetScheme", checked)
    this.form.setFieldTouched("wanSite", checked)
    this.props.onPrefChange("wanSiteEnabled", checked)
    this.props.onError(this.getErrors(this.form.errors, this.form.touched))
  }

  render() {
    const {
      alertsEnabled,
      alertProfile,
      clientEmail,
      wanSiteEnabled,
      wanSite,
      subnet,
      subnetScheme,
      trafficMgmtEnabled,
      trafficProfile,
      advRoutingEnabled,
      cpeIPAddr,
      lanSubnetScheme,
      primaryDNS,
      secondaryDNS,
      ipConfig,
      ipVersion,
      interfacePort,
      cidr
    } = this.props

    const { wans } = this.props.wanStore

    return (
      <Formik
        validationSchema={schema}
        ref={_ref => (this.form = _ref)}
        initialValues={{
          alertsEnabled,
          alertProfile,
          clientEmail,
          wanSiteEnabled,
          wanSite,
          subnet,
          subnetScheme,
          interfacePort,
          trafficMgmtEnabled,
          trafficProfile,
          advRoutingEnabled,
          cpeIPAddr,
          lanSubnetScheme,
          primaryDNS,
          secondaryDNS,
          ipConfig,
          ipVersion,
          cidr
        }}
        render={({
          values,
          isSubmitting,
          dirty,
          touched,
          errors,
          handleSubmit,
          handleChange,
          setFieldTouched
        }) => (
          <React.Fragment>
            <Flex justify="space-around" align="baseline" flexWrap="wrap">
              {/* Pushed Out of MVP to Version 1 release */}

              {/* <GradientFlex
                flexDirection="column"
                p={"15px 35px 0 35px"}
                css={{
                  boxShadow: "0px 4px 10px -3px rgba(0, 0, 0, 0.34)",
                  height: "300px",
                  maxWidth: "330px"
                }}
                flex="0 0 330px"
                mt={"30px"}
              >
                <SubTitle children={"Alerts Profile"} mb={10} />
                <FastField
                  name="alertsEnabled"
                  component={SitePrefCheckbox}
                  label="Enable Alerts and Dashboard"
                />
                <Flex flexDirection="column" p={"10px 0"}>
                  <FastField
                    name="alertProfile"
                    render={props => (
                      <Select
                        {...props}
                        error={errors.alertProfile}
                        touched={touched.alertProfile}
                        setFieldTouched={setFieldTouched}
                        labelText="Alert Profile"
                        htmlFor="alertProfile"
                        value={values.alertProfile}
                        frmControlStyle={{ flex: "0 0 50px", minWidth: 135 }}
                      >
                        {_.map(alertProfileData, (data, i) => (
                          <MenuItem key={i} children={data} value={data} />
                        ))}
                      </Select>
                    )}
                  />
                  <Link
                    children="Create New"
                    underline
                    disabled={!values.wanSiteEnabled}
                    fontSize={11}
                    css={{ textAlign: "right" }}
                  />
                </Flex>
                <FastField
                  name="clientEmail"
                  label="Client Email"
                  component={TextField}
                  css={{ maxWidth: "150px" }}
                />
              </GradientFlex> */}

              <GradientFlex
                flexDirection="column"
                p={"15px 35px 0 35px"}
                css={{
                  boxShadow: "0px 4px 10px -3px rgba(0, 0, 0, 0.34)",
                  height: "300px",
                  maxWidth: "330px"
                }}
                flex="0 0 330px"
                mt={"30px"}>
                <SubTitle children={"Wide Area Networking"} mb={10} />
                <FastField
                  name="wanSiteEnabled"
                  label="WAN Site"
                  component={props => (
                    <SitePrefCheckbox
                      {...props}
                      onChange={this.handlePrefChange}
                    />
                  )}
                />
                {/* <Flex flexDirection="column" p={"10px 0"}>
                  <Field
                    name="wanSite"
                    validate={validateWAN(
                      values.wanSiteEnabled,
                      "wanSite",
                      "Select WAN / Create new WAN"
                    )}
                    render={props => (
                      <Select
                        {...props}
                        displayEmpty
                        error={errors.wanSite}
                        touched={touched.wanSite}
                        frmControlStyle={{ minWidth: 135 }}
                        htmlFor="wanSite"
                        labelText="Select WAN"
                        renderValue={value =>
                          value === "None"
                            ? "Select"
                            : _.get(_.find(wans, { id: value }), "name")
                        }
                        setFieldTouched={setFieldTouched}
                        value={values.wanSite}
                        disabled={!values.wanSiteEnabled}
                      >
                        {_.map(wans, (data, i) => (
                          <MenuItem
                            key={data.id}
                            children={data.name}
                            value={data.id}
                          />
                        ))}
                      </Select>
                    )}
                  />
                  <Link
                    children="Create New"
                    underline
                    fontSize={11}
                    disabled={!values.wanSiteEnabled}
                    css={{
                      textAlign: "right",
                      marginTop: "5px"
                    }}
                    onClick={this.handleCreateNewWan(values.wanSiteEnabled)}
                  />
                </Flex> */}
                <Flex justify="space-between">
                  {/* <FastField
                    validate={validateSubnet(
                      values.wanSiteEnabled,
                      "subnet",
                      "private site subnet is required"
                    )}
                    name="subnet"
                    render={props => (
                      <TextField
                        label="Private site subnet"
                        {...props}
                        disabled={!values.wanSiteEnabled}
                      />
                    )}
                    css={{ maxWidth: "150px" }}
                  />
                  <FastField
                    name="subnetScheme"
                    validate={validateWAN(
                      values.wanSiteEnabled,
                      "subnetScheme",
                      "CIDR is required"
                    )}
                    render={props => (
                      <Select
                        {...props}
                        error={errors.subnetScheme}
                        touched={touched.subnetScheme}
                        htmlFor="subnetScheme"
                        labelText="CIDR"
                        value={values.subnetScheme}
                        renderValue={value => `/${value}`}
                        frmControlStyle={{
                          maxWidth: 80,
                          minWidth: 80,
                          marginLeft: "10px"
                        }}
                        setFieldTouched={setFieldTouched}
                        disabled={!values.wanSiteEnabled}
                      >
                        {_.map(netMaskData, (data, i) => (
                          <MenuItem
                            key={i}
                            children={`/${data}`}
                            value={data}
                          />
                        ))}
                      </Select>
                    )}
                  /> */}
                </Flex>
              </GradientFlex>

              <GradientFlex
                flexDirection="column"
                p={"15px 35px 0 35px"}
                css={{
                  boxShadow: "0px 4px 10px -3px rgba(0, 0, 0, 0.34)",
                  height: "300px",
                  maxWidth: "330px"
                }}
                flex="0 0 330px"
                mt={"30px"}>
                <SubTitle children={"Traffic Management"} mb={10} />
                <FastField
                  name="trafficMgmtEnabled"
                  component={SitePrefCheckbox}
                  label="Enable Traffic Management"
                />
                <Flex flexDirection="column" p={"10px 0"}>
                  <FastField
                    name="trafficProfile"
                    render={props => (
                      <Select
                        {...props}
                        displayEmpty
                        error={errors.trafficProfile}
                        touched={touched.trafficProfile}
                        frmControlStyle={{ flex: "0 0 50px", minWidth: 195 }}
                        htmlFor="trafficProfile"
                        labelText="Select Traffic Profile"
                        renderValue={value =>
                          value === "None" ? "Select" : value
                        }
                        setFieldTouched={setFieldTouched}
                        value={values.trafficProfile}>
                        {_.map(trafficProfileData, (data, i) => (
                          <MenuItem key={i} children={data} value={data} />
                        ))}
                      </Select>
                    )}
                  />
                  {/* Pushed Out Of MVP to Version 1 Release  */}
                  {/* <Link
                    children="Create New"
                    underline
                    fontSize={11}
                    underline
                    css={{ textAlign: "right" }}
                    onClick={this.handleCreateTrafficProfile}
                  /> */}
                </Flex>
              </GradientFlex>

              {/* <GradientFlex
                flexDirection="column"
                p={"15px 35px 0 35px"}
                css={{
                  boxShadow: "0px 4px 10px -3px rgba(0, 0, 0, 0.34)",
                  height: "300px",
                  maxWidth: "330px"
                }}
                flex="0 0 330px"
                mt={"30px"}
              >
                <SubTitle children={"Advanced Routing"} mb={10} />
                <FastField
                  name="advRoutingEnabled"
                  label="Enabled Advanced Routing"
                  component={SitePrefCheckbox}
                />
                <Flex p={"10px 0"} width={195} align="flex-end" mt={20}>
                  <Link
                    children="Settings"
                    underline
                    fontSize={12}
                    css={{ textAlign: "left" }}
                  />
                </Flex>
              </GradientFlex>
             */}
            </Flex>

            <Flex
              width="100%"
              justify="space-between"
              m={"30px 0"}
              css={{
                borderTop: "1px solid #CCC",
                borderBottom: "1px solid #CCC"
              }}
              p={"20px 0"}>
              <Flex flexDirection="column" width="100%">
                <SubTitle children="LAN Configuration" pb={20} />
                <Flex justify="flex-start" flexWrap="wrap">
                  {/* <Field
                    disabled={true}
                    flex={"0 0 60px"}
                    name="interfacePort"
                    //  error={errors.interfacePort}
                    //  touched={touched.interfacePort}
                    label="Interface Port"
                    component={TextField}
                    css={{ maxWidth: "170px", marginRight: "5%" }}
                  /> */}
                  <Field
                    validate={value =>
                      value ? validateIPAddress(value) : null
                    }
                    flex={"0 0 60px"}
                    name="cpeIPAddr"
                    // error={errors.cpeIPAddr}
                    // touched={touched.cpeIPAddr}
                    label="LAN Address"
                    component={TextField}
                    css={{ maxWidth: "170px", marginRight: "5%" }}
                  />
                  <FastField
                    validate={value => (value ? validateCIDR(value) : null)}
                    flex={"0 0 60px"}
                    name="subnetScheme"
                    // error={errors.cidr}
                    // touched={touched.cidr}
                    label="CIDR"
                    component={TextField}
                    css={{ maxWidth: "170px", marginRight: "5%" }}
                  />
                  {/* <FastField
                    flex={"0 0 60px"}
                    css={{ maxWidth: "170px", marginRight: "5%" }}
                    name="subnetScheme"
                    validate={validateWAN(
                      values.wanSiteEnabled,
                      "subnetScheme",
                      "CIDR is required"
                    )}
                    render={props => (
                      <Select
                        {...props}
                        error={errors.subnetScheme}
                        touched={touched.subnetScheme}
                        htmlFor="subnetScheme"
                        labelText="CIDR"
                        value={values.subnetScheme}
                        renderValue={value => `/${value}`}
                        frmControlStyle={{
                          maxWidth: 80,
                          minWidth: 80,
                          marginLeft: "10px"
                        }}
                        setFieldTouched={setFieldTouched}
                        // disabled={!values.wanSiteEnabled}
                      >
                        {_.map(netMaskData, (data, i) => (
                          <MenuItem
                            key={i}
                            children={`/${data}`}
                            value={data}
                          />
                        ))}
                      </Select>
                    )}
                  /> */}
                  <FastField
                    disabled={true}
                    flex={"0 0 60px"}
                    name="primaryDNS"
                    // error={errors.primaryDNS}
                    // touched={touched.primaryDNS}
                    label="DNS Server Primary"
                    component={TextField}
                    css={{ maxWidth: "170px", marginRight: "5%" }}
                  />
                  <FastField
                    disabled={true}
                    name="secondaryDNS"
                    // error={errors.secondaryDNS}
                    // touched={touched.secondaryDNS}
                    label="DNS Secondary"
                    component={TextField}
                    css={{ maxWidth: "170px" }}
                  />
                </Flex>
              </Flex>
            </Flex>
            {dirty && this.props.onError(this.getErrors(errors, touched))}
            {this.props.onDirty(dirty)}
          </React.Fragment>
        )}
      />
    )
  }

  componentDidMount() {
    const { siteStore } = this.props
    siteStore.clearUnsavedPorts()
  }
}

export default SiteConfigForm
