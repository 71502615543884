import { observable, action, decorate } from "mobx"
import { API, graphqlOperation } from "aws-amplify"
import _ from "lodash"

import {
  ALL_IGW,
  CREATE_IGW,
  UPDATE_IGW,
  CREATE_UBOND_SERVER,
  GET_CORE_INSTALL_URL_SERVER,
  ALL_UBOND_SERVERS
} from "../queries"

class InternetGatewayStore {
  gateways = []
  ubondServers = []

  /**
   * Retrieves a list of Internet Gateways associated with User
   *
   * @param {String!} ownerId - id of the current user
   *
   * @typedef InternetGateway
   * @type {Object}
   * @property {String} id - id
   * @property {String} name - company name
   * @property {String} ip - ip address
   * @property {String} port - port
   *
   * @return {Promise Array.<InternetGateway>} - list of companies
   */
  allUserInternetGateways = async ownerId => {
    const {
      data: { allUserInternetGateways: gateways }
    } = await API.graphql(graphqlOperation(ALL_IGW, { ownerId }))
    this.gateways = gateways
    return Promise.resolve()
  }

  /**
   * Creates an Internet Gateway
   *
   * @param {input} - ig info
   * @param {String!} input.ownerId - id of the current user
   * @param {String!} input.name - ig name
   * @param {String!} input.ip - ip address
   * @param {String!} input.port - port
   * @return {Promise <Object>} - result data
   */
  createInternetGateway = async input => {
    const {
      data: {
        createInternetGateway: { id }
      }
    } = await API.graphql(graphqlOperation(CREATE_IGW, { input }))

    this.gateways = [{ ...input, id }, ...this.gateways]
    return Promise.resolve(id)
  }

  /**
   * Updates an Internet Gateway
   */
  updateInternetGateway = async input => {
    await API.graphql(graphqlOperation(UPDATE_IGW, { input }))
    const index = _.findIndex(this.gateways, ({ id }) => id === input.id)
    this.gateways = Object.assign([...this.gateways.slice()], {
      [index]: input
    })

    return Promise.resolve(input.id)
  }

  /**
   * Creates a Ubond Server
   *
   * @param {String!} ownerId - id of the current user
   * @return {Promise <id>} - id of the created server
   */
  createUbondServer = async ownerId => {
    const {
      data: {
        createUbondServer: { id }
      }
    } = await API.graphql(
      graphqlOperation(CREATE_UBOND_SERVER, { input: { ownerId } })
    )
    return Promise.resolve(id)
  }

  /**
   * Retrieves a list of Ubond Servers associated with User
   *
   * @param {String!} ownerId - id of the current user
   *
   * @typedef UbondServer
   * @type {Object}
   * @property {String} id - id
   *
   * @return {Promise Array.<UbondServer>} - list of companies
   */
  allUserUbondServers = async ownerId => {
    const {
      data: { allUserUbondServers: ubondServers }
    } = await API.graphql(graphqlOperation(ALL_UBOND_SERVERS, { ownerId }))
    this.ubondServers = ubondServers
    return Promise.resolve(ubondServers)
  }

  /**
   * Get install url for Server core
   * @param {String!} id - server id
   * @returns {Promise} Promise containing the url
   */
  getCoreInstallUrlServer = async serverId => {
    const {
      data: {
        getCoreInstallUrlServer: { url }
      }
    } = await API.graphql(
      graphqlOperation(GET_CORE_INSTALL_URL_SERVER, { serverId })
    )
    return Promise.resolve(
      `/usr/bin/curl ${url.replace(/\&/g, "\\&")} | /bin/bash`
    )
  }
}

decorate(InternetGatewayStore, {
  allUserInternetGateways: action,
  createInternetGateway: action,
  updateInternetGateway: action,
  allUserUbondServers: action,
  gateways: observable,
  ubondServers: observable
})

export default new InternetGatewayStore()
