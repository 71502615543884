import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { inject } from "mobx-react"
import { withRouter } from "react-router-dom"
import { Auth } from "aws-amplify"

import Authenticator from "./Auth"
import Console from "./Console"
import SignOut from "./Auth/Signout"

@withRouter
@inject("userStore")
class PrivateRoute extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false
  }

  unlisten = this.props.history.listen(async () => {
    try {
      await Auth.currentAuthenticatedUser()
    } catch (error) {
      if (this.state.isAuthenticated) {
        this.setState({ isAuthenticated: false })
      }
    }
  })

  async componentDidMount() {
    const { userId, email } = await this.authenticate()
    this.setState({ loaded: true, isAuthenticated: true })
  }

  componentWillUnmount() {
    this.unlisten()
  }

  authenticate = async () => {
    const { userStore, history } = this.props
    try {
      const {
        username: userId,
        attributes: { email }
      } = await Auth.currentAuthenticatedUser()
      userStore.setUser({ userId, email })
      return Promise.resolve({ userId, email })
    } catch (error) {
      console.log(error)
      userStore.forgetUser()
      history.push("/auth")
    }
  }

  render() {
    const { component: Component, ...rest } = this.props
    const { loaded, isAuthenticated } = this.state
    if (!loaded) return null
    return (
      <Route
        {...rest}
        render={props => {
          return isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/auth"
              }}
            />
          )
        }}
      />
    )
  }
}

export const Routes = () => (
  <Switch>
    <Route exact path="/" render={() => <Redirect to="/console/sites" />} />
    <Route exact path="/out" component={SignOut} />
    <Route exact path="/auth" component={Authenticator} />
    <PrivateRoute path="/console" component={Console} />
  </Switch>
)
