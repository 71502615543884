import React from "react"
import { Link } from "rebass"
import { NavLink } from "react-router-dom"
import _ from "lodash"
import classNames from "classnames"
import styled from "styled-components"
import PropTypes from "prop-types"

import { withStyles } from "@material-ui/core/styles"
import BaseTable from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import BaseTableCell from "@material-ui/core/TableCell"
import BaseTablePagination from "@material-ui/core/TablePagination"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import ButtonBase from "@material-ui/core/ButtonBase"
import { Checkbox as BaseCheckbox } from "./index"
import IconButton from "@material-ui/core/IconButton"
import Chevron from "@material-ui/icons/ChevronLeft"
import { lighten } from "@material-ui/core/styles/colorManipulator"

function desc(a, b) {
  const lowerA = _.toLower(a)
  const lowerB = _.toLower(b)
  if (lowerB < lowerA) {
    return -1
  }
  if (lowerB > lowerA) {
    return 1
  }
  return 0
}

function getSorting(order, orderBy, sortByValue) {
  return order === "desc"
    ? (a, b) => desc(sortByValue(a, orderBy), sortByValue(b, orderBy))
    : (a, b) => -desc(sortByValue(a, orderBy), sortByValue(b, orderBy))
}

const SortLabelStyles = theme => ({
  /* Styles applied to the root element. */
  root: {
    cursor: "pointer",
    display: "inline-flex",
    justifyContent: "flex-start",
    flexDirection: "inherit",
    alignItems: "center"
  },
  /* Styles applied to the root element if `active={true}`. */
  active: {
    color: "#FFFFFF",
    "& $icon": {
      opacity: 1
    }
  },
  /* Styles applied to the icon component. */
  icon: {
    height: 25,
    marginRight: 4,
    marginLeft: 4,
    opacity: 0,
    transition: theme.transitions.create(["opacity", "transform"], {
      duration: theme.transitions.duration.shorter
    }),
    userSelect: "none",
    width: 25
  },
  /* Styles applied to the icon component if `direction="desc"`. */
  iconDirectionDesc: {
    transform: "rotate(270deg)"
  },
  /* Styles applied to the icon component if `direction="asc"`. */
  iconDirectionAsc: {
    transform: "rotate(90deg)"
  }
})

const MTablePagination = withStyles({
  caption: {
    color: "#FFFFFF",
    fontSize: 14
  }
})(BaseTablePagination)

export const TablePagination = styled(MTablePagination)`
  button {
    svg {
      color: #ffffff;
    }
    &:hover {
      background-color: inherit;
    }
  }
  button[disabled] {
    svg {
      color: #ffffff52;
    }
  }
  button:last-child {
    margin-left: -20px;
  }
  div[class^="MuiTablePagination-actions"] {
    margin-left: 0;
  }
`

/**
 * A button based label for placing inside `TableCell` for column sorting.
 */
function TableSortLabel(props) {
  const { active, classes, className, children, direction, ...other } = props

  return (
    <ButtonBase
      className={classNames(
        classes.root,
        { [classes.active]: active },
        className
      )}
      component="span"
      disableRipple
      {...other}>
      {children}
      <Chevron
        className={classNames(
          classes.icon,
          classes[`iconDirection${_.capitalize(direction)}`]
        )}
      />
    </ButtonBase>
  )
}

TableSortLabel.propTypes = {
  /**
   * If `true`, the label will have the active styling (should be true for the sorted column).
   */
  active: PropTypes.bool,
  /**
   * Label contents, the arrow will be appended automatically.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css-api) below for more details.
   */
  classes: PropTypes.object.isRequired,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The current sort direction.
   */
  direction: PropTypes.oneOf(["asc", "desc"])
}

TableSortLabel.defaultProps = {
  active: false,
  direction: "desc"
}

TableSortLabel = withStyles(SortLabelStyles, { name: "MuiTableSortLabel" })(
  TableSortLabel
)

const tableCellStyles = {
  root: {
    flexDirection: "row",
    backgroundColor: "#EFEFEF",
    paddingRight: 10,
    paddingLeft: 10,
    textAlign: "center",
    whiteSpace: "nowrap",
    color: "##4E4D5C"
  }
}

const TableCell = withStyles(tableCellStyles)(BaseTableCell)

const TableHeadCell = withStyles({
  root: {
    backgroundColor: "#37373A",
    color: "#CCCCCC",
    fontWeight: "bold"
  }
})(TableCell)

const TableCheckbox = withStyles({
  colorPrimary: {
    color: "#CCCCCC"
  },
  checked: {
    color: "#373737 !important"
  }
})(BaseCheckbox)

const TableHeadCheckbox = withStyles({
  colorPrimary: {
    color: "#FFFFFF"
  },
  checked: {
    color: "#FFFFFF !important"
  }
})(BaseCheckbox)

const HeadTableRow = withStyles({
  head: {
    height: 40
  }
})(TableRow)

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      rows,
      disableCheck
    } = this.props

    return (
      <TableHead>
        <HeadTableRow>
          {!disableCheck && (
            <TableHeadCell padding="checkbox">
              {/* <TableHeadCheckbox
                disableRipple
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
              /> */}
            </TableHeadCell>
          )}
          {rows.map(row => {
            return (
              <TableHeadCell
                key={row.id}
                numeric={row.numeric}
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
                style={row.style || {}}>
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={this.createSortHandler(row.id)}>
                  {row.label}
                </TableSortLabel>
              </TableHeadCell>
            )
          }, this)}
        </HeadTableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

const styles = theme => ({
  root: {
    width: "100%"
  },
  table: {
    minWidth: 1020
  },
  tableWrapper: {
    overflowX: "auto"
  }
})

class EnhancedTable extends React.Component {
  state = {
    order: "asc",
    orderBy: this.props.orderBy,
    selected: []
  }

  handleRequestSort = (event, property) => {
    const orderBy = property
    let order = "desc"

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc"
    }

    this.setState({ order, orderBy })
  }

  handleSelectAllClick = (event, checked) => {
    let selected = []
    if (checked) {
      selected = this.props.data.map(n => n.id)
    }
    this.setState({ selected })
    this.props.onSelect(selected)
    return
  }

  handleClick = id => () => {
    const { selected } = this.state
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    this.setState({ selected: newSelected })
    this.props.onSelect(newSelected)
  }

  isSelected = id => this.state.selected.indexOf(id) !== -1

  render() {
    const {
      classes,
      rows,
      rowsPerPage,
      page,
      data,
      cellRenderer,
      disableCheck,
      sortByValue
    } = this.props
    const { order, orderBy, selected } = this.state
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <BaseTable className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              rows={rows}
              disableCheck={disableCheck}
            />
            <TableBody>
              {data
                .slice()
                .sort(getSorting(order, orderBy, sortByValue))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  return (
                    <TableRow hover tabIndex={-1} key={n.id}>
                      {!disableCheck && (
                        <TableCell>
                          {/* <TableCheckbox
                            checked={this.isSelected(n.id)}
                            onChange={this.handleClick(n.id)}
                          /> */}
                        </TableCell>
                      )}
                      {_.map(rows, row => {
                        const { cellProps, children } = cellRenderer(row, n)
                        return (
                          <TableCell {...cellProps} key={row.id}>
                            {children}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
            </TableBody>
          </BaseTable>
        </div>
      </Paper>
    )
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired
}

export const Table = withStyles(styles)(EnhancedTable)
