import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";

@withRouter
export default class SignOut extends Component {
  render() {
    return null;
  }
  async componentDidMount() {
    try {
      const data = await Auth.signOut();
      this.props.history.push("/auth");
    } catch (ex) {
      console.info("SignOut: Error logging out");
    }
  }
}
