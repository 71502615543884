import React from "react"
import { Box, Flex, Container, Image } from "rebass"
import {
  Title,
  SubTitle,
  FormControlLabel,
  Checkbox,
  TextField,
  Headline,
  Select,
  MenuItem,
  Button,
  PrimaryButton,
  Link,
  GradientFlex,
  SecondarySwitch,
  Plus,
  Heading
} from "../ui"
export default class Dashboard extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div>
        <GradientFlex p={40} bg="greys.3" m={"3% 3%"}>
          <Heading children={"Welcome"} />
        </GradientFlex>
        <Flex flexDirection="row" bg="greys.3" m={"0 3%"}>
          <Flex width="85%" flexDirection="column">
            <Flex flexDirection="row" p={10} bg="greys.3" m={"1% 3%"}>
              <Heading children={"A couple of quick notes"} />
            </Flex>
            <Flex flexDirection="column" p={10} bg="greys.3" m={"0 3%"}>
              <Flex flexDirection="row">
                <SubTitle children={"1."} />
                <SubTitle
                  ml={3}
                  children={`This demo system supports only hub-and-spoke architecture. Each site creates an overlay across multiple WAN links to a Core device ( we call it a Gateway ). Between these two nodes, is where our product takes care of :`}
                />
              </Flex>
              <Flex pt={2} mt={1} ml={4} flexDirection="row">
                <SubTitle>a.</SubTitle>
                <SubTitle
                  ml={2}
                  children={`Bi-Directional Traffic prioritization ( Classic QoS )`}
                />
              </Flex>
              <Flex pt={2} mt={1} ml={4} flexDirection="row">
                <SubTitle>b.</SubTitle>
                <SubTitle
                  ml={2}
                  children={`Link
            Aggregation ( stacking of bandwidth )`}
                />
              </Flex>
              <Flex pt={2} mt={1} ml={4} flexDirection="row">
                <SubTitle>c.</SubTitle>
                <SubTitle
                  ml={2}
                  children={`Session-Persistent & Same-IP Failover ( sub-second failover of
                traffic from an active WAN link to a standby WAN link )`}
                />
              </Flex>
            </Flex>
            <Flex flexDirection="column" p={8} bg="greys.3" m={"0 3%"}>
              <Flex flexDirection="row">
                <SubTitle children={"2."} />
                <SubTitle ml={3}>
                  We've posted test procedures{" "}
                  <a
                    href="http://www.stereonetworks.com/we-want-you"
                    target="_blank"
                    style={{
                      color: "#D9E139",
                      textDecoration: "none"
                    }}>
                    here
                  </a>{" "}
                  to help you evaluate the product.
                </SubTitle>
              </Flex>
            </Flex>
            <Flex flexDirection="column" p={8} bg="greys.3" m={"0 3%"}>
              <Flex flexDirection="row">
                <SubTitle children={"3."} />
                <SubTitle
                  ml={3}
                  children={`The system you have has some current limitations. They include:`}
                />
              </Flex>
              <Flex ml={4} mt={1} pt={2} flexDirection="row">
                <SubTitle children={"a."} />
                <SubTitle
                  ml={2}
                  children={` Each account can have only one Gateway. In production, there will be no limit.`}
                />
              </Flex>
              <Flex ml={4} mt={1} pt={2} flexDirection="row">
                <SubTitle>b.</SubTitle>
                <SubTitle
                  ml={2}
                  children={`Your Gateway can support up to 8 WAN links across all of your sites. In production, there will be no limit.`}
                />
              </Flex>
              <Flex ml={4} mt={1} pt={2} flexDirection="row">
                <SubTitle>c.</SubTitle>
                <SubTitle
                  ml={2}
                  children={`Your Gateway can have only one IP Address - meaning that you can have a WAN with multiple sites, but all share a single public /32, or you can test a single-site deployment on that /32. In production, there will be no limit.`}
                />
              </Flex>
            </Flex>
            <Flex flexDirection="column" p={8} bg="greys.3" mb={4} m={"0 3%"}>
              <Flex flexDirection="row">
                <SubTitle>4.</SubTitle>
                <SubTitle ml={3}>
                  More information about our product can be accessed by clicking
                  on the "Help Centre" text in the left column, or by visiting:{" "}
                  <a
                    style={{
                      color: "#D9E139",
                      textDecoration: "none"
                    }}
                    href="https://ethica.zendesk.com/hc/en-us">
                    https://ethica.zendesk.com/hc/en-us
                  </a>
                </SubTitle>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            flexDirection="column"
            m={5}
            width="25%"
            style={{ overflow: "hidden" }}
          />
        </Flex>
        <GradientFlex textAlign="center" p={30} bg="greys.3" m={"3% 3%"}>
          <SubTitle>
            If you have any questions or are stumbling, we want to hear from you
            - please email{" "}
            <a
              style={{
                color: "#D9E139",
                textDecoration: "none"
              }}
              href="mailto:support@ethica.partners"
              target="_blank">
              {" "}
              support@ethica.partners
            </a>{" "}
            and we'll get right on it!
          </SubTitle>
        </GradientFlex>
      </div>
    )
  }
}
