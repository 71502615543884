import React from "react";
import { Flex } from "rebass";
import { withRouter } from "react-router-dom"
import { CREATE_DIALOG } from "./constants";
import { Title, PrimaryButton, WANButton } from "../../ui";
import ssDialog from "./decorator";

@withRouter
@ssDialog({key: CREATE_DIALOG})
export default class CreateDialog extends React.Component {
	handleNewSite = () => {
		this.props.onClose();
		this.props.history.push("/console/company-profile")
	}

	handleNewWAN = () => {
		this.props.onClose();
		this.props.history.push("/console/wan-config")
	}

	render() {
		return (
			<Flex align="center" flexDirection="column" flex="1" justify="center">
				<Title color="#fff" children="What are we creating today?" />
	    	<Flex width={250} mt={15} justify="space-around">
	    		<PrimaryButton onClick={this.handleNewSite}>
	    			New Site
	    		</PrimaryButton>
	    		<WANButton onClick={this.handleNewWAN}>
	    			New WAN
	    		</WANButton>
	    	</Flex>
	    </Flex>
	 	);
 	}
}