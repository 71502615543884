import React from "react"
import _ from "lodash"
import { Flex, Text, Box, Image } from "rebass"
import styled from "styled-components"
import * as Yup from "yup"
import { observer, inject } from "mobx-react"
import { Formik, Field, FastField, Form } from "formik"
import { withStyles } from "@material-ui/core"
import {
  Title,
  SubTitle,
  FormControlLabel,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  Tree,
  PrimaryButton,
  Button,
  Link,
  GradientFlex,
  PrimarySwitch,
  SecondarySwitch,
  SmallIOSSwitch,
  Input,
  RadioGroup,
  Radio,
  ErrorFormHelperText
} from "../ui"

const bitType = ["Mbps", "Kbps"]

const ipConfigData = [{ value: 32, text: "Default/32(Single)" }]

const ipVersionData = ["IPv4", "IPv6"]

export const BitMenuItem = withStyles({
  root: {
    fontSize: 14
  }
})(MenuItem)

export const BitSelect = withStyles({
  input: {
    fontSize: "14px"
  }
})(Select)

const ThroughputCheckbox = withStyles({
  label: {
    fontSize: ".9em"
  },
  labelChecked: {
    color: "orange",
    fontSize: ".9em"
  }
})(Checkbox)

const ConfigBlock = styled(Flex)`
  padding-right: 60px;
  @media (max-width: 1470px) {
    justify-content: space-between;
    padding-right: 0;
  }
`

const validateThroughput = (throughputCapEnabled, type) => value => {
  if (throughputCapEnabled && !value) {
    return `${type} is required`
  }
}

const schema = Yup.object().shape({
  primaryGateway: Yup.string().required("Primary Gateway is required"),
  ipConfig: Yup.string().required("IP Configuration  is required")
})

@inject("internetGatewayStore", "userStore", "uxStore", "siteStore")
class SiteConfigTest extends React.PureComponent {
  static defaultProps = {
    downloadBitType: bitType[0],
    uploadBitType: bitType[0],
    onDirty: () => {}
  }

  calculateUpload = (type, isEnabled) => {
    const { upload } = this.props
    return _.sumBy(upload, o => {
      return !o
        ? 0
        : isEnabled
        ? o[type]
          ? _.parseInt(o["value"]) || 0
          : 0
        : !o[type]
        ? _.parseInt(o["value"]) || 0
        : 0
    })
  }

  debouncedOnError = _.debounce(this.props.onError, 250)

  calculateDownload = (type, isEnabled) => {
    const { download } = this.props
    return _.sumBy(download, o => {
      return !o
        ? 0
        : isEnabled
        ? o[type]
          ? _.parseInt(o["value"]) || 0
          : 0
        : !o[type]
        ? _.parseInt(o["value"]) || 0
        : 0
    })
  }

  getValues() {
    let initialValues = _.get(this.form, "initialValues")
    let values = _.get(this.form, "state.values")
    return {
      ...initialValues,
      ...values,
      ...{
        downloadThroughput: values.throughputCapEnabled
          ? values.downloadThroughput
          : undefined,
        uploadThroughput: values.throughputCapEnabled
          ? values.uploadThroughput
          : undefined
      },
      ...{
        configuredThroughPut: {
          primary: {
            upload: this.calculateUpload("standbyEnabled"),
            download: this.calculateDownload("standbyEnabled")
          },
          secondary: {
            upload: this.calculateUpload("standbyEnabled", true),
            download: this.calculateDownload("standbyEnabled", true)
          }
        }
      }
    }
  }

  resetForm = () => {
    this.form.resetForm(this.getValues())
  }

  runValidations = async () => {
    if (!this.props.wanSiteEnabled) {
      this.form.setFieldTouched("primaryGateway", true)
      this.form.setFieldTouched("ipConfig", true)
    }
    const errors = await this.form.runValidations()
    if (errors.primaryGateway || errors.ipConfig) {
      this.props.uxStore.openToast({
        status: "error",
        message: `: : Tunnel : : 
        Missing Fields `
      })
    }
    return !_.isEmpty(_.keys(errors))
  }

  getErrors(errors, touched) {
    return !_.isEmpty(
      _.filter(_.keys(errors), key => {
        return !!touched[key]
      })
    )
  }

  handleThroughputChange = (errors, touched) => () => {
    this.form.setFieldTouched("downloadThroughput", true)
    this.form.setFieldTouched("uploadThroughput", true)
    this.props.onError(this.getErrors(errors, touched))
  }

  render() {
    const {
      primaryGateway,
      secondaryGateway,
      throughputCapEnabled,
      download,
      downloadBitType,
      downloadThroughput,
      uploadThroughput,
      upload,
      uploadBitType,
      ipVersion,
      ipConfig,
      wanSiteEnabled
    } = this.props
    const { gateways } = this.props.internetGatewayStore
    let ispSubnets = _.get(this.props.userStore, "config.ispSubnets", [])

    // Filter Out Used ispSubnets
    let usedIps = []

    this.props.siteStore.sites.map(eachSite => {
      return usedIps.push(eachSite.configuration.tunnel.ipConfig)
    })
    ispSubnets = ispSubnets.filter(function(each) {
      return !usedIps.includes(each)
    })

    return (
      <Formik
        validationSchema={schema}
        ref={_ref => (this.form = _ref)}
        initialValues={{
          primaryGateway,
          secondaryGateway,
          throughputCapEnabled,
          downloadThroughput,
          uploadThroughput,
          download,
          downloadBitType,
          upload,
          uploadBitType,
          ipVersion,
          ipConfig
        }}
        render={({ values, dirty, touched, setFieldTouched, errors }) => (
          <Flex
            width="100%"
            justify="space-between"
            align="baseline"
            flexWrap="wrap">
            <ConfigBlock
              flex={1}
              justify="flex-start"
              css={{ minWidth: "400px" }}
              mt={20}>
              <Flex
                flexDirection="column"
                flex={1}
                mr={"20px"}
                css={{ maxWidth: "250px" }}>
                <Title fontSize="1.4em">Gateways</Title>
                <Flex mt={20} flexWrap="wrap" align="flex-start">
                  <Field
                    name="primaryGateway"
                    render={props => (
                      <Select
                        {...props}
                        error={errors.primaryGateway}
                        touched={touched.primaryGateway}
                        setFieldTouched={setFieldTouched}
                        labelText="Primary Gateway"
                        htmlFor="primaryGateway"
                        value={values.primaryGateway}
                        frmControlStyle={{
                          flex: "0 0 50px",
                          minWidth: 150,
                          marginRight: 10,
                          marginBottom: 20
                        }}
                        // disabled={wanSiteEnabled}
                      >
                        {_.map(gateways, (data, i) => (
                          <MenuItem
                            key={i}
                            children={data.name}
                            value={data.id}
                          />
                        ))}
                      </Select>
                    )}
                  />
                  {/* <Flex flexDirection="column">
                    <Field
                      name="secondaryGateway"
                      render={props => (
                        <Select
                          {...props}
                          error={errors.secondaryGateway}
                          touched={touched.secondaryGateway}
                          setFieldTouched={setFieldTouched}
                          labelText="Secondary Gateway"
                          htmlFor="secondaryGateway"
                          value={values.secondaryGateway}
                          frmControlStyle={{ flex: "0 0 50px", minWidth: 150 }}
                          disabled={wanSiteEnabled}
                        >
                          {_.map(gateways, (data, i) => (
                            <MenuItem
                              key={i}
                              children={data.name}
                              value={data.id}
                            />
                          ))}
                        </Select>
                      )}
                    />
                    <Text
                      children="Requires BGP setup"
                      color={wanSiteEnabled ? "#ffffff78" : "#ffffff"}
                      fontSize={11}
                    />
                  </Flex> */}
                </Flex>
                {/* {wanSiteEnabled && (
                  <Text fontSize={11} mt={"20px"}>
                    Note: To enable gateways, uncheck <b>WAN Site</b> above
                  </Text>
                )} */}
              </Flex>

              <Flex
                flexDirection="column"
                flex={1}
                css={{ maxWidth: "250px" }}
                align="flex-start">
                <Title
                  fontSize="1.4em"
                  children="Network Configuration"
                  pb={20}
                />
                <Flex justify="flex-start" flexWrap="wrap">
                  <FastField
                    name="ipConfig"
                    render={props => (
                      <Select
                        {...props}
                        error={errors.ipConfig}
                        touched={touched.ipConfig}
                        setFieldTouched={setFieldTouched}
                        labelText="IP Configuration"
                        htmlFor="ipConfig"
                        value={values.ipConfig}
                        frmControlStyle={{
                          flex: "0 0 70px",
                          minWidth: 150,
                          maxWidth: 150,
                          marginRight: 10,
                          marginBottom: 20
                        }}>
                        {this.props.disabled ? (
                          <MenuItem
                            children={values.ipConfig}
                            value={values.ipConfig}
                          />
                        ) : (
                          _.map(ispSubnets, (data, i) => (
                            <MenuItem key={i} children={data} value={data} />
                          ))
                        )}
                      </Select>
                    )}
                  />
                  {/* <FastField
                    name="ipVersion"
                    render={props => (
                      <Select
                        {...props}
                        error={errors.ipVersion}
                        touched={touched.ipVersion}
                        setFieldTouched={setFieldTouched}
                        labelText="IP Version"
                        htmlFor="ipVersion"
                        value={values.ipVersion}
                        frmControlStyle={{ minWidth: 90, maxWidth: 90 }}
                      >
                        {_.map(ipVersionData, (data, i) => (
                          <MenuItem key={i} children={data} value={data} />
                        ))}
                      </Select>
                    )}
                  /> */}
                </Flex>
              </Flex>
            </ConfigBlock>

            <Flex
              flex={1}
              justify="space-between"
              css={{ minWidth: "700px" }}
              mt={20}
              align="baseline">
              <Flex
                flexDirection="column"
                flex={"0 0 27%"}
                css={{ minWidth: "300px" }}>
                <Title fontSize="1.4em">Configured Throughput</Title>
                <Flex flexDirection="column" mt={15}>
                  <Text>Primary Link(s)</Text>
                  <Flex justify="space-between" flexWrap="wrap">
                    <Flex flex={"0 0 155px"}>
                      <Text children="Download" fontSize=".9em" />
                      <Text
                        children={`${this.calculateDownload(
                          "standbyEnabled"
                        )} Mbps`}
                        fontSize=".9em"
                        fontWeight="bold"
                        pl={10}
                      />
                    </Flex>
                    <Flex>
                      <Text children="Upload" fontSize=".9em" />
                      <Text
                        children={`${this.calculateUpload(
                          "standbyEnabled"
                        )} Mbps`}
                        fontSize=".9em"
                        fontWeight="bold"
                        pl={10}
                      />
                    </Flex>
                  </Flex>
                </Flex>

                <Flex flexDirection="column" mt={15}>
                  <Text>Standby Link(s)</Text>
                  <Flex justify="space-between" flexWrap="wrap">
                    <Flex flex={"0 0 155px"}>
                      <Text children="Download" fontSize=".9em" />
                      <Text
                        children={`${this.calculateDownload(
                          "standbyEnabled",
                          true
                        )} Mbps`}
                        fontSize=".9em"
                        fontWeight="bold"
                        pl={10}
                      />
                    </Flex>
                    <Flex>
                      <Text children="Upload" fontSize=".9em" />
                      <Text
                        children={`${this.calculateUpload(
                          "standbyEnabled",
                          true
                        )} Mbps`}
                        fontSize=".9em"
                        fontWeight="bold"
                        pl={10}
                      />
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>

              <Flex
                flexDirection="column"
                bg="greyBg"
                flex="0 0 400px"
                p={16.5}
                css={{
                  boxShadow: "0px 4px 10px -3px rgba(0, 0, 0, 0.34)",
                  boxSizing: "content-box !important",
                  maxWidth: "290px"
                }}
                p={"30px 35px"}>
                <Title fontSize="1.4em">Maximum Throughput</Title>
                <Box>
                  <FastField
                    label="Set Throughput Cap"
                    name="throughputCapEnabled"
                    component={props => (
                      <ThroughputCheckbox
                        {...props}
                        onChange={this.handleThroughputChange(errors, touched)}
                      />
                    )}
                  />
                </Box>
                <Flex justify="space-between" mt={10}>
                  <Flex
                    flexDirection="column"
                    flex={"0 0 95px"}
                    align="flex-start">
                    <Text fontSize={12} fontWeight="bold">
                      Download
                    </Text>
                    <Flex align="flex-end" flex="0 0 30px">
                      <Field
                        name="downloadThroughput"
                        error={errors.downloadThroughput}
                        validate={validateThroughput(
                          values.throughputCapEnabled,
                          "download"
                        )}
                        render={({ field, form }) => (
                          <Input
                            type="number"
                            style={{
                              width: "50px",
                              fontSize: "14px",
                              fontWeight: 800,
                              marginRight: "10px"
                            }}
                            {...field}
                            {...form}
                            disabled={!values.throughputCapEnabled}
                          />
                        )}
                      />
                      <Field
                        name="downloadBitType"
                        render={props => (
                          <BitSelect
                            {...props}
                            htmlFor="downloadBitType"
                            value={values.downloadBitType}
                            frmControlStyle={{ maxWidth: 72, minWidth: 72 }}
                            disabled={!values.throughputCapEnabled}>
                            {_.map(bitType, (data, i) => (
                              <BitMenuItem
                                key={i}
                                children={data}
                                value={data}
                              />
                            ))}
                          </BitSelect>
                        )}
                      />
                    </Flex>
                    {values.throughputCapEnabled &&
                      !!errors.downloadThroughput &&
                      touched.downloadThroughput && (
                        <ErrorFormHelperText
                          children={errors.downloadThroughput}
                        />
                      )}
                  </Flex>
                  <Flex
                    flexDirection="column"
                    flex={"0 0 60px"}
                    align="flex-start"
                    ml={10}>
                    <Text fontSize={12} fontWeight="bold">
                      Upload
                    </Text>
                    <Flex align="flex-end" flex="0 0 30px">
                      <Field
                        name="uploadThroughput"
                        validate={validateThroughput(
                          values.throughputCapEnabled,
                          "upload"
                        )}
                        render={({ field, form }) => (
                          <Input
                            type="number"
                            style={{
                              width: "50px",
                              fontSize: "14px",
                              fontWeight: 800,
                              marginRight: "10px"
                            }}
                            {...field}
                            {...form}
                            disabled={!values.throughputCapEnabled}
                          />
                        )}
                      />
                      <Field
                        name="uploadBitType"
                        render={props => (
                          <BitSelect
                            {...props}
                            htmlFor="uploadBitType"
                            value={values.uploadBitType}
                            frmControlStyle={{
                              flex: "0 0 70px",
                              maxWidth: 72,
                              minWidth: 72
                            }}
                            disabled={!values.throughputCapEnabled}>
                            {_.map(bitType, (data, i) => (
                              <BitMenuItem
                                key={i}
                                children={data}
                                value={data}
                              />
                            ))}
                          </BitSelect>
                        )}
                      />
                    </Flex>
                    {values.throughputCapEnabled &&
                      !!errors.uploadThroughput &&
                      touched.uploadThroughput && (
                        <ErrorFormHelperText
                          children={errors.uploadThroughput}
                        />
                      )}
                  </Flex>
                </Flex>
                {(dirty || touched) &&
                  this.props.onError(this.getErrors(errors, touched))}
                {this.props.onDirty(dirty)}
              </Flex>
            </Flex>
          </Flex>
        )}
      />
    )
  }
}

export default SiteConfigTest
