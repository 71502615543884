import React from "react";
import _ from "lodash";
import { Box, Flex, Text, Image, Border, Circle, Heading } from "rebass";
import { withRouter } from "react-router-dom";
import { Formik, Field, FastField, Form, FieldArray } from "formik";
import { observer, inject } from "mobx-react";
import * as Yup from "yup";
import { CREATE_WAN } from "./constants";
import {
  Title,
  PrimaryButton,
  WANButton,
  DialogTitle,
  DialogSubTitle,
  TextField,
  Checkbox,
  Plus,
  Select,
  MenuItem,
  Link
} from "../../ui";
import ssDialog from "./decorator";
import { withStyles, Dialog as BaseDialog } from "@material-ui/core";
import styled from "styled-components";

const schema = Yup.object().shape({
  name: Yup.string().required("WAN Name is required"),
  primaryGateway: Yup.string().required("Primary Gateway is required")
});

export const StyledDialog = styled(BaseDialog)`
  [role="dialog"] {
    top: -5%;
  }

  [role="document"] {
    background: #333;
    max-width: 420px;
    height: 440px;
    width: 100%;
  }
`;

@withRouter
@ssDialog({ key: CREATE_WAN, component: StyledDialog })
@inject("internetGatewayStore", "wanStore", "userStore", "uxStore")
export default class CreateWANDialog extends React.Component {
  static defaultProps = {
    trafficPriorities: [[{ desc: "", type: "" }]],
    tunnelBypassTraffic: [{ desc: "", type: "" }]
  };

  deleteItem = (arrayHelpers, index) => () => {
    arrayHelpers.remove(index);
  };

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { trafficPriorities, tunnelBypassTraffic } = this.props;
    const { gateways } = this.props.internetGatewayStore;

    return (
      <Box p={"0 30px"}>
        <DialogTitle
          children="Create WAN"
          p={"30px 0 20px 0"}
          color="white"
          css={{ borderBottom: "1px solid #DDD" }}
        />
        <Formik
          validationSchema={schema}
          onSubmit={async (
            { name, primaryGateway, secondaryGateway },
            { setSubmitting }
          ) => {
            await this.props.wanStore.createWan({
              ownerId: _.get(this.props, "userStore.currentUser.userId"),
              name,
              status: "DRAFT",
              wanGateway: { primaryGateway, secondaryGateway }
            });
            this.props.uxStore.openToast({ message: "Created WAN !" });
            setSubmitting(false);
            this.handleClose();
          }}
          initialValues={{ trafficPriorities, tunnelBypassTraffic }}
          render={({
            values,
            setFieldTouched,
            errors,
            dirty,
            touched,
            isSubmitting,
            handleSubmit
          }) => (
            <Form autoComplete="off">
              <Flex flexDirection="column" pt={30}>
                <FastField name="name" label="WAN Name" component={TextField} />
                <Flex
                  flexDirection="column"
                  flex={"0 0 190px"}
                  css={{ borderBottom: "1px solid #DDD" }}
                >
                  <DialogSubTitle children="Gateways" color="white" pt={25} />
                  <Flex pt={20} justify="space-between">
                    <FastField
                      name="primaryGateway"
                      render={props => (
                        <Select
                          {...props}
                          labelText="Primary Gateway"
                          htmlFor="gateway"
                          name="primaryGateway"
                          error={errors.primaryGateway}
                          touched={touched.primaryGateway}
                          setFieldTouched={setFieldTouched}
                          value={values.primaryGateway}
                          frmControlStyle={{
                            flex: "0 0 150px",
                            maxWidth: 150,
                            minWidth: 150
                          }}
                        >
                          {_.map(gateways, (data, i) => (
                            <MenuItem
                              key={i}
                              children={data.name}
                              value={data.id}
                            />
                          ))}
                        </Select>
                      )}
                    />
                    {/* <Flex flexDirection="column">
                      <FastField
                        name="secondaryGateway"
                        render={props => (
                          <Select
                            {...props}
                            labelText="Secondary Gateway"
                            htmlFor="secondaryGateway"
                            name="secondaryGateway"
                            frmControlStyle={{
                              maxWidth: 160,
                              minWidth: 160
                            }}
                          >
                            {_.map(gateways, (data, i) => (
                              <MenuItem
                                key={i}
                                children={data.name}
                                value={data.id}
                              />
                            ))}
                          </Select>
                        )}
                      />
                      <Text
                        color="white"
                        children="Requires BGP setup"
                        fontSize={"9px"}
                        mt={"4px"}
                      />
                    </Flex> */}
                  </Flex>
                </Flex>
              </Flex>
              <Flex p={"15px 0"} justify="flex-end" align="center">
                <Link
                  children="Close"
                  underline="true"
                  fontSize={13}
                  pr={20}
                  onClick={this.handleClose}
                />
                <PrimaryButton
                  children="Save"
                  disabled={
                    isSubmitting || !_.isEmpty(_.keys(errors)) || !dirty
                  }
                  onClick={handleSubmit}
                  p={"15px 50px"}
                />
              </Flex>
            </Form>
          )}
        />
      </Box>
    );
  }
}
