import React from "react"
import _ from "lodash"
import { observer, inject } from "mobx-react"
import { Flex, Text, Box } from "rebass"
import { Formik, Field, Form } from "formik"
import { withStyles } from "@material-ui/core"
import { Title, GradientFlex } from "../ui"
import {
  PrimaryButton,
  TextField,
  MenuItem,
  CreateMenuItem,
  InputLabel,
  FormControl,
  SelectFormControl,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  CreateSelect,
  ErrorFormHelperText
} from "../ui"
import SiteContactForm from "./SiteContactForm"
import { track } from "../../analytics"

class CompanyNameSelect extends React.Component {
  state = {
    value: ""
  }

  handleChange = event => {
    this.setState({ value: event.target.value })
    this.props.onChange(event)
  }

  handleBlur = event => {
    if (!this.state.value) {
      this.props.setFieldTouched(_.get(this.props, "field.name"), true)
    }
  }

  render() {
    const { value } = this.state
    return (
      <CreateSelect
        {...this.props}
        value={value}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        renderValue={value => value}
        inputProps={{
          name: "companyName",
          id: "companyName",
          title: "Select Company"
        }}>
        {_.map(companyData, (data, i) => (
          <CreateMenuItem key={i} value={data["name"]}>
            <Flex flexDirection="column" fontSize={13}>
              <Text fontWeight="semiBold" children={data["name"]} />
              <Text children={data["desc"]} color="greys.3" fontSize={11} />
            </Flex>
          </CreateMenuItem>
        ))}
      </CreateSelect>
    )
  }
}

@inject("companyStore", "siteStore", "userStore")
export default class CreateSite extends React.Component {
  state = {
    disableSubmit: true
  }

  componentDidMount() {
    track("Create Site")
  }

  handleSiteFormError = hasError => {
    if (this.state.disableSubmit !== hasError) {
      this.setState({ disableSubmit: hasError })
    }
  }

  getCompanyId() {
    return _.get(this.props, "location.state.companyId")
  }

  handleSiteFormSubmit = values => {
    this.props.history.push({
      pathname: "/console/site-config",
      state: { info: { ...values, ...{ companyId: this.getCompanyId() } } }
    })
  }

  render() {
    const company = _.find(_.get(this.props, "companyStore.companies"), {
      id: this.getCompanyId()
    })
    const { disableSubmit } = this.state
  
    let companyName = _.get(company, "name")
    return (
      <Flex
        m="0 auto"
        width="100%"
        css={{ maxWidth: "1000px" }}
        flexDirection="column">
        <Flex flex="0 0 150px" align="flex-end">
          {/* <Title children="New Site Information" color="greys.3" pb={20} /> */}
        </Flex>
        <Flex flex="0 0 480px">
          <Formik
            onSubmit={(values, { setSubmitting }) => {
              this.form.submit()
              setSubmitting(false)
            }}
            render={({ handleSubmit, isSubmitting }) => (
              <Form
                onSubmit={handleSubmit}
                style={{ display: "flex", width: "100%" }}>
                <Flex css={{ width: "100%" }}>
                  <Flex flex="1" p={30} bg="greys.3" flexDirection="column">
                     <Title>{companyName}</Title> <Text>New Site Information</Text>
                    <Flex flexDirection="column" mt={15}>
                      {/* <Text>Company</Text> */}
                     
                    </Flex>
                    <Box mt={10}>
                      <SiteContactForm
                        ref={_ref => (this.form = _ref)}
                        onError={this.handleSiteFormError}
                        onSubmit={this.handleSiteFormSubmit}
                        companyName={companyName}
                      />
                    </Box>
                  </Flex>
                  <GradientFlex
                    flex="0 0 250px"
                    flexDirection="column"
                    justify="flex-end"
                    p={"40px 30px"}>
                    <PrimaryButton
                      type="submit"
                      children="Save and Continue"
                      disabled={disableSubmit || isSubmitting}
                    />
                  </GradientFlex>
                </Flex>
              </Form>
            )}
          />
        </Flex>
      </Flex>
    )
  }
}
