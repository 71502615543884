import React from "react"
import _ from "lodash"
import { Flex, Text, Box, Image } from "rebass"
import { observer, inject } from "mobx-react"
import { toJS } from "mobx"
import { Formik, Field, FastField, Form, FieldArray } from "formik"
import * as Yup from "yup"

import {
  Title,
  SubTitle,
  FormControlLabel,
  Checkbox,
  TextField,
  Headline,
  Select,
  MenuItem,
  Button,
  PrimaryButton,
  Link,
  GradientFlex,
  SecondarySwitch,
  Plus
} from "../ui"
import { industryTypeData } from "../ui/DropdownConstants"

const schema = Yup.object().shape({
  name: Yup.string().required("Company Name is required"),
  industry: Yup.string().required("Industry Type is required")
})

@inject("companyStore")
class CompanyForm extends React.Component {
  static defaultProps = {
    companyInfoLinks: [{ name: "", url: "" }],
    industry: "",
    onError: () => {},
    onSubmit: () => {},
    onDirty: () => {},
    disabled: false
  }

  submit() {
    this.form.submitForm()
  }

  getValues() {
    return {
      ..._.get(this.form, "initialValues"),
      ..._.get(this.form, "state.values")
    }
  }

  resetForm() {
    this.form.resetForm(this.getValues())
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const { name, industry, companyInfoLinks, notes, companyId } = this.props
    const company =
      _.find(_.get(this.props, "companyStore.companies"), {
        id: companyId
      }) || ""
    return (
      <Formik
        ref={_ref => (this.form = _ref)}
        validationSchema={schema}
        initialValues={{
          name: name || company.name,
          industry: industry || company.industry,
          companyInfoLinks: company.companyInfoLinks
            ? [...toJS(company.companyInfoLinks)]
            : [
                { name: companyInfoLinks[0].name, url: companyInfoLinks[0].url }
              ],
          notes: notes || company.notes
        }}
        onSubmit={this.props.onSubmit}
        render={({
          values,
          isSubmitting,
          dirty,
          touched,
          errors,
          handleSubmit,
          handleChange,
          setFieldTouched
        }) => (
          <Flex flexDirection="column" width="100%">
            <Flex justify={this.props.disabled && "space-between"}>
              <FastField
                disabled={this.props.disabled}
                name="name"
                label="Company Name"
                component={TextField}
                value={values.name}
                css={{ maxWidth: "180px", marginRight: "30px", width: "100%" }}
              />
              <FastField
                name="industry"
                render={props => (
                  <Select
                    disabled={this.props.disabled}
                    {...props}
                    error={errors.industry}
                    touched={touched.industry}
                    setFieldTouched={setFieldTouched}
                    labelText="Industry Type"
                    htmlFor="industry"
                    value={values.industry || ""}>
                    {_.map(industryTypeData, (data, i) => (
                      <MenuItem key={i} children={data} value={data} />
                    ))}
                  </Select>
                )}
              />
            </Flex>

            <Flex flexDirection="column">
              <FieldArray
                name="companyInfoLinks"
                render={arrayHelpers =>
                  this.props.disabled ? (
                    _.map(values.companyInfoLinks, (link, index) => (
                      <Flex flexDirection="column">
                        <Flex key={`link-${index}`} width="100%" pt={20}>
                          <Box
                            style={{
                              borderBottom: "1px dotted"
                            }}
                            flex={1}
                            mr={30}>
                            <Text fontSize={"0.775rem"}> Quick Link</Text>
                            <a
                              href={link.url}
                              target="_blank"
                              style={{
                                color: "#D9E139",
                                textDecoration: "none"
                              }}>
                              <Text mt={3} mb={1} fontSize={"0.775rem"}>
                                {" "}
                                {link.url}
                              </Text>
                            </a>
                          </Box>
                          <Box
                            style={{
                              borderBottom: "1px dotted"
                            }}
                            mr={"40%"}
                            ml={30}
                            flex="0 0 33%">
                            <Text fontSize={"0.775rem"}> Link Description</Text>
                            <Text mt={3} mb={1} fontSize={"0.775rem"}>
                              {" "}
                              {link.name}
                            </Text>
                          </Box>
                        </Flex>
                      </Flex>
                    ))
                  ) : (
                    <Plus
                      onAdd={() => {
                        arrayHelpers.push({ name: "", url: "" })
                      }}
                      onRemove={index => () => {
                        arrayHelpers.remove(index)
                      }}>
                      {_.map(values.companyInfoLinks, (link, index) => (
                        <Flex key={`link-${index}`} width="100%" pt={20}>
                          <Box flex="1" mr={30}>
                            <FastField
                              name={`companyInfoLinks.${index}.url`}
                              label="https://www.example.com"
                              component={TextField}
                              value={link.url}
                            />
                          </Box>
                          <Box flex="0 0 35%">
                            <FastField
                              name={`companyInfoLinks.${index}.name`}
                              label="Link Description (Opt)"
                              component={TextField}
                              value={link.name}
                            />
                          </Box>
                        </Flex>
                      ))}
                    </Plus>
                  )
                }
              />
            </Flex>
            <Flex mt={30} flexDirection="column">
              <Text fontSize={13} color="#DBDBDB">
                Notes (Opt)
              </Text>
              <FastField
                name="notes"
                value={values.notes}
                render={props => (
                  <TextField
                    disabled={this.props.disabled}
                    multiline
                    rows="5"
                    {...props}
                  />
                )}
              />
            </Flex>
            {dirty && this.props.onError(!_.isEmpty(_.keys(errors)))}
            {this.props.onDirty(dirty)}
          </Flex>
        )}
      />
    )
  }
}

export default CompanyForm
