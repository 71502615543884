import React from "react";
import _ from "lodash";
import { observer, inject } from 'mobx-react';
import { Dialog, FormDialog } from "../../ui";

const ssDialog = (defaults) => {
  return (WrappedComponent) => {
    const { key, component, type, options = {}} = defaults;
    const CustomDialog = component ? component : (type === "form" ? FormDialog : Dialog);

    @inject("uxStore")
    @observer
    class ssDialogComponent extends React.Component {
      handleClose = () => {
        this.props.uxStore.closeDialog();
      }

      render() {
        const { options, ...others } = this.props
        return (
          <CustomDialog
            onClose={this.handleClose}
            open={_.isEqual(_.get(this.props, "uxStore.dialog"), key)}
          >
            <WrappedComponent onClose={this.handleClose} {...others} {...options}/>
          </CustomDialog>
        );
      }
    };

    ssDialogComponent.defaultProps = {
      options: options
    }

    return ssDialogComponent;
  };
};

export default ssDialog;
