export const APPSYNC_ENDPOINT =
  process.env.REACT_APP_ENV === "production"
    ? "https://nfav3nqzsfce5fymfgtzlkarei.appsync-api.us-east-1.amazonaws.com/graphql"
    : "https://l5muuq3bfba6bf4tupfpfx4g4a.appsync-api.us-east-1.amazonaws.com/graphql"

export const USER_POOL_ID =
  process.env.REACT_APP_ENV === "production"
    ? "us-east-1_Vb6QxB6kI"
    : "us-east-1_kppZPCuKC"

export const IDENTITY_POOL_ID =
  process.env.REACT_APP_ENV === "production"
    ? "us-east-1:cd00187f-8a31-41f2-b966-f729ce4512c4"
    : "us-east-1:c44caae8-54cd-4323-abc6-b39ffb74a8ad"

export const POOL_WEB_CLI_ID =
  process.env.REACT_APP_ENV === "production"
    ? "516n7h5qqtdvddipe09u4abk2i"
    : "76udm4bgask1dqlf8la389sta9"

export const AMPLIFY_CONFIG = {
  aws_cognito_identity_pool_id: IDENTITY_POOL_ID,
  aws_cognito_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: USER_POOL_ID,
  aws_user_pools_web_client_id: POOL_WEB_CLI_ID,
  aws_appsync_graphqlEndpoint: APPSYNC_ENDPOINT,
  aws_appsync_region: "us-east-1",
  graphql_endpoint_iam_region: "us-east-1",
  aws_appsync_authenticationType: "AWS_IAM"
}
