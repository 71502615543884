import { observable, action, decorate } from "mobx"
import { API, graphqlOperation } from "aws-amplify"
import userStore from "./UserStore"
import _ from "lodash"

import { ALL_COMPANIES, CREATE_COMPANY, UPDATE_COMPANY } from "../queries"

class CompanyStore {
  companies = []

  /**
   * Retrieves a list of Companies associated with User
   *
   * @param {String!} ownerId - id of the current user
   *
   * @typedef Company
   * @type {Object}
   * @property {String} id - id
   * @property {String} name - company name
   * @property {String} industry - company industry
   * @property {String} notes - notes
   * @property {Array} companyInfoLinks - an array of objects: {name: "name", url: "url"}
   *
   * @return {Promise Array.<Company>} - list of companies
   */
  fetchCompanies = async ownerId => {
    const {
      data: { allCompanies: companies }
    } = await API.graphql(graphqlOperation(ALL_COMPANIES, { ownerId }))

    this.companies = _.map(companies, c => ({
      ...c,
      companyInfoLinks: JSON.parse(c.companyInfoLinks)
    }))
    return Promise.resolve()
  }

  /**
   * Creates a Company
   *
   * @param {input} - company info
   * @param {String!} input.ownerId - id of the current user
   * @param {String!} input.name - company name
   * @param {Array} input.companyInfoLinks - an array of objects -- {name: "name", url: "url"}
   *
   * @return {Promise <Object>} - result data
   */
  createCompany = async input => {
    // const companyInfoLinks = _.map(input.companyInfoLinks, l => {
    //   return _.mapValues(l, v => (v === "" ? null : v))
    // })

    const {
      data: {
        createCompany: { id }
      }
    } = await API.graphql(
      graphqlOperation(CREATE_COMPANY, {
        input: {
          ...input,
          companyInfoLinks: JSON.stringify(input.companyInfoLinks)
        }
      })
    )

    this.companies = [{ ...input, id }, ...this.companies]
    return Promise.resolve(id)
  }

  /**
   * Updates a Company
   * @param {input} - company info
   * @param {String!} input.id - id of the company to update
   * @param {String!} input.ownerId - id of the current user
   * @param {String} input.name - company name
   * @param {String} input.industry - company industry
   * @param {String} input.notes - notes on the company
   * @returns {Promise} Promise containing the company id
   */
  updateCompany = async input => {
    // const companyInfoLinks = _.map(input.companyInfoLinks, l => {
    //   return _.mapValues(l, v => (v === "" ? null : v))
    // })
    await API.graphql(
      graphqlOperation(UPDATE_COMPANY, {
        input: {
          ...input,
          companyInfoLinks: JSON.stringify(input.companyInfoLinks)
        }
      })
    )

    const index = _.findIndex(this.companies, ({ id }) => id === input.id)
    this.companies = Object.assign([...this.companies.slice()], {
      [index]: input
    })

    return Promise.resolve(input.id)
  }
}

decorate(CompanyStore, {
  fetchCompanies: action,
  createCompany: action,
  updateCompany: action,
  companies: observable
})

export default new CompanyStore()
